.CreateAccountFlow {
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    min-height: 1000px;

    &.-step-3 {
        min-height: 0;
        //remove overflow-x hidden to allow the form to be displayed
        overflow-x: visible;
    }

    // &.-step-2 {
    //     @media screen and (max-width: 750px) {
    //         padding-bottom: 0 !important;  
    //     }
    // }

    .decoration {
        position: absolute;
        right: 0;
        z-index: 0;
    }

    .steps-container {
        position: relative;
        height: 670px;
        display: flex;
        justify-content: center;
        width: 90%;
        margin: auto;
        max-width: 1280px;
    }

    .animation-container {
        width: 100%;

        .Form {
            max-width: none;
        }
    }

    .step1,
    .step2,
    .step3,
    .step4 {
        position: absolute;
    }

    .step2,
    .step3,
    .step4 {
        transform: translate(200%);
    }

    .step4 {
        height: 100%;
    }

    @keyframes currentSlideToLeft {
        0% {
            transform: translateX(0);
            opacity: 1;
        }
        100% {
            transform: translateX(-100%);
            opacity: 0;
        }
    }

    @keyframes currentSlideToRight {
        0% {
            transform: translateX(0);
            opacity: 1;
        }
        100% {
            transform: translateX(200%);
            opacity: 0;
        }
    }

    @keyframes step1SlideInto {
        0% {
            transform: translateX(-100%);
            opacity: 0;
        }
        100% {
            transform: translateX(0);
            opacity: 1;
        }
    }

    @keyframes step2SlideIntoFromLeft {
        0% {
            transform: translateX(-100%);
            opacity: 0;
        }
        100% {
            transform: translateX(0);
            opacity: 1;
        }
    }

    @keyframes step2SlideIntoFromRight {
        0% {
            transform: translateX(200%);
            opacity: 0;
        }
        100% {
            transform: translateX(0);
        }
    }

    @keyframes step3SlideInto {
        0% {
            transform: translateX(200%);
        }
        100% {
            transform: translateX(0%);
        }
    }

    @media screen and (max-width: 1460px) {
        .decoration {
            display: none;
        }
    }

    @media screen and (max-width: 950px) {
        min-height: 0;
        padding-top: 50px;
        padding-bottom: 0 !important;

        &.-step-3 {
            padding-bottom: 0 !important;
            padding-top: 0 !important;
        }    
    }
}
