@import '../../../../Style/theme.scss';

.HowItWorksStep {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    .step {
        color: $surface-grey-opposite;
        text-align: center;
        padding-bottom: 8px;
        padding-top: 16px;
    }

    .step-text {
        color: $element-greys-secondary;
        text-align: center;
        text-transform: capitalize;

        @media screen and (max-width: 1000px) {
            padding-bottom: 14px;
        }
    }

    img {
        margin: 0 auto -26px;
        width: 350px;
        height: 437px;

        @media screen and (max-width: 1000px) {
            margin: 0 auto -15px;
        }

        @media screen and (max-width: 370px) {
            width: 90%;
        }
    }
}

@media screen and (max-width: 450px) {
    .HowItWorksStep {
        img {
            height: auto;
        }
    }
}

@media (max-width: 100px) {
    .HowItWorksStep {
        img {
            width: 328px;
            height: 474px;
        }
    }
}
