$breakpoint: 1130px;

.CreateAccountHeader {
    height: 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    box-shadow: 0px 25px 40px -10px rgba(28, 39, 49, 0.08);
    padding: 0 110px;
    background-color: white;
    z-index: 10;

    a {
        text-decoration: none;
    }

    .home {
        position: absolute;
        left: 110px;
        cursor: pointer;
        font-weight: 600;
    }

    .logo-container {
        margin: auto;
        height: 45px;
    }

    @media screen and (max-width: $breakpoint) {
        padding: 0 20px;

        .logo-container {
            margin: 0;
            margin-left: auto;
        }

        .home {
            left: 20px;
        }
    }
}
