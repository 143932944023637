.FAQPage {
    margin: 80px 0;

    .hero {
        padding: 80px 16px;
        text-align: center;
    }

    .wrapper-faq {
        width: 900px;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        flex-direction: column;
        gap: 32px;

        @media (max-width: 850px) {
            width: 600px;
            gap: 18px;
        }

        @media (max-width: 670px) {
            width: calc(100% - 32px);
        }
    }
}