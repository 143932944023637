@import './../../Style/theme.scss';

.Modal {
    .react-responsive-modal-modal {
        background: none !important;
        background-color: $white !important;
    }

    .modal-content {
        padding: 80px 40px;
    }
}