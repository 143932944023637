@import './../../../../Style/theme.scss';
@import './../../../../Style/typographie.scss';

.AboutUsSectionHero {
    background-color: $white;
    padding: 80px 17px;

    .row {
        max-width: 812px;
        width: 100%;
        margin: 80px auto 0;

        .title,
        .subtitle,
        .phrase-one-line {
            text-align: center;

            .gradient-text {
                padding-bottom: 4px;
            }
        }

        .paragraph {
            text-align: center;
            max-width: 622px;
            margin: 32px auto 0;
        }
    }
    
    @media screen and (max-width: 900px) {
        & {
            .row {
                .title,
                .subtitle,
                .phrase-one-line {
                    line-height: 39px !important;
                }
            }
        }
    }
    
    @media screen and (max-width: 630px) {
        & {
            .row {
                max-width: 356px;

                .WrapperSectionTitle {
                    text-align: left;
                }
    
                .paragraph {
                    margin-top: 16px;
                    text-align: left;
                }
            }
        }
    }
}

