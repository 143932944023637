.Step3Mobile {
    position: relative;

    .top-section {
        .navigation-container {
            .prev-btn {
                min-width: 0;
            }
        }

        .Button {
            padding: 0 !important;
        }
        h3 {
            padding-top: 10px;
        }
    }

    .progress-container {
        position: absolute;
        top: -50px;
        width: 100%;
    }

    .checkmark {
        display: flex;
        justify-content: center;
    }

    .fade {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        width: 100%;
        height: 275px;
        bottom: 0;
        background: linear-gradient(
            180deg,
            rgba(255, 255, 255, 0) 0%,
            rgba(255, 255, 255, 0.92) 66.44%,
            #fff 100%
        );

        .scroll-down {
            margin-bottom: 20px;
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        &.fade-down {
            animation: fadeDown 0.5s forwards;
        }

        @keyframes fadeDown {
            0% {
                transform: translateY(0);
                opacity: 1;
            }
            100% {
                transform: translateY(100%);
                opacity: 0;
            }
        }
    }

    .bottom-section {
        position: absolute;
        bottom: -205px;
        box-shadow: 0px -2px 34px 0px rgba(0, 0, 0, 0.25);
        opacity: 0;
        pointer-events: none;
        background-color: white;
        width: calc(100vw + 32px);
        left: -32px;

        @media screen and (max-height: 500px) {
            height: 100%;
            bottom: -50px;
        }

        &.complete {
            opacity: 1;
            pointer-events: all;
        }

        .inner {
            padding: 20px;
            display: flex;
            flex-direction: column;
            align-items: center;
            row-gap: 20px;
        }
    }
}
