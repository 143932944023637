@import './../../../../Style/theme.scss';

.AboutUsSectionRevolution {
    width: 100%;
    background-color: $white;

    .FuturOfFashionSection {
        padding-top: 80px !important;
    }
}
