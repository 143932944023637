@import '../../../../Style/typographie.scss';
@import '../../../../Style/breakpoint.scss';

.Hero {
    position: relative;
    max-height: 100vh;
    overflow: hidden;
    padding: 0 0 !important;

    .wrapper-arrow-right {
        padding-left: 15px;
        position: absolute;

        &.-has-link {
            padding-left: 0 !important;
            margin-left: 10px !important;
        }
    }

    .learn-more {
        margin-left: 5px;
    }



    .wrapper-video {
        video {
            margin-left: auto;
            margin-right: auto;            
            
            overflow-y: hidden;

            @media (max-width: 768px) {
                width: auto;
                height: 100vh !important;
                position: relative;
                top: 0;
                left: 50%;
                transform: translate(-50%, 0);
            }
        }
    
        &::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.5);
            overflow-y: hidden;    
        }

        @media (max-width: 768px) {
            width: auto;
            height: 840px !important;
            position: relative;
            top: 0;
            left: 50%;
            transform: translate(-50%, 0);
        }
    }

    .annoucement-toast {
        position: absolute;
        top: 100px;
        left: 0;
        right: 0;
        z-index: 60;

        .box {
            background-color: #2B2B2B80;
            width: 51%;
            margin-left: auto;
            margin-right: auto;
            border-radius: 20px;
            padding: 26px 34px;
            gap: 10px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            backdrop-filter: blur(25px);

            .left {
                .whatnews {
                    color: white;
                }
    
                .content {
                    display: flex;
                    flex-direction: row;
                    gap: 1ch;
                    align-items: center;
    
                    a {
                        color: white;
                        text-decoration: underline;
                    }

                    @media (max-width: 600px) {
                        flex-direction: column;
                        gap: 2px;
                    }

                    .wrapper-arrow-right {
                        margin-left: 0;
                        margin-right: 20px;

                        &.-has-link {
                            margin-left: 48px;
                        }
                    }
                }
            }

            .right {
                display: flex;

                @media (max-width: 1000px) {
                    margin-bottom: auto;
                    margin-top: 2px;
                }        
            }

            @media (max-width: 1960px) {
                width: 60%;
            }

            @media (max-width: 1670px) {
                width: 68%;
            }

            @media (max-width: 1000px) {
                width: calc(100% - 100px);
            }
        }
    }

    .inner {
        position: absolute;
        left: 40%;
        bottom: 120px;
        transform: translate(-50%, -50%);
        z-index: 1;
        width: max-content;

        @media (max-width: 1650px) {
            max-width: 1100px;
        }

        @media (max-width: 1300px) {
            max-width: 1000px;
        }

        @media (max-width: 1150px) {
            max-width: 900px;
        }

        @media (max-width: 1110px) {
            max-width: 700px;
        }

        @media (max-width: 750px) {
            max-width: 600px;
        }

        @media (max-width: 650px) {
            max-width: 500px;
        }

        @media (max-width: 550px) {
            max-width: 400px;
        }

        .WrapperSectionTitle {
            margin-bottom: 16px;
        }

        .extra-info {
            margin-left: 3px;
        }

        .Button {
            margin-top: 32px;
            min-width: 0 !important;
            width: fit-content;

            @media (max-width: 580px) {
                margin-top: 40px;
                width: 264px;
                padding: 12px 50px !important;
            }

            @media (max-width: map-get($breakpoints, 'l')) {
                min-width: 100% !important;
            }
        }

        @media (max-width: 1600px) and (min-width: 1000px) {
            position: absolute;
            left: 45%;
            bottom: 10%;
            transform: translate(-50%, -50%);
            z-index: 1;
        }

        @media (max-width: 1000px) and (min-width: 580px) {
            position: absolute;
            left: 50%;
            bottom: 10%;
            transform: translate(-50%, -50%);
            z-index: 1;

            display: flex;
            flex-direction: column;
            text-align: center;
        }

        @media (max-width: 580px) {
            position: absolute;
            bottom: 100px;
            left: 16px;
            right: 0;

            transform: translate(0, 0);
            width: calc(100% - 32px);

            display: flex;
            flex-direction: column;
            text-align: left;
        }
    }
}
