@import '../../../../Style/theme.scss';
@import '../../../../Style/breakpoint.scss';

$faded-gray: #bbbbbb;
.OurGoalsContainer {
    background-color: $surface-grey-light;
    .inner-container {
        margin: auto;
        display: flex;
        position: relative;
        max-width: 1360px;
        @media screen and (max-width: 1350px) {
            margin: 0 16px;
        }

        @media (max-width: map-get($breakpoints, 'tablet')) {
            margin: 0;
        }

        .OurGoals {
            padding: 120px 0;
            position: relative;

            @media (max-width: map-get($breakpoints, 'tablet')) {
                flex-direction: column;
                justify-content: center;
                margin-left: 16px;
            }

            .title {
                @media (max-width: map-get($breakpoints, 'tablet')) {
                    max-width: 400px;
                }
                @media (max-width: map-get($breakpoints, 'm')) {
                    max-width: 300px;
                }
            }

            .timeline {
                position: relative;
                width: 100%;
                overflow-x: scroll;
                padding-top: 20px;
                overflow: hidden;
                cursor: grab;
            }

            .timeline-track {
                display: flex;
                align-items: flex-end;
                width: max-content;
                min-width: 100%;
                position: relative;
                padding-right: 20px; // Add right padding
                
                &::before {
                    content: '';
                    position: absolute;
                    left: 20px;
                    bottom: 22px;
                    right: 20px;
                    height: 5px;
                    background: linear-gradient(90deg, #2EB67D 0.34%, #2FB481 37.71%, #34A797 80.63%, #399DA9 100%);
                }
            }
            

            .timeline-item {
                position: relative;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                margin-right: 130px;
                padding-bottom: 40px;

                &.-isLast-true {
                    .timeline-content {
                        max-width: 250px !important;
                    }
                }
            
                .top {
                    height: 205px;
                }
            }

            .timeline-icon {
                position: absolute;
                width: 45px;
                height: 45px;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                z-index: 1;
                background-color: #2ecc71;
                bottom: 2px;

                svg {
                    width: 32px;
                    height: 32px;
                }
            }

            .timeline-content {
                text-align: left;
                margin-bottom: 20px;
                max-width: 300px;
            }

            .timeline-status {
                font-size: 12px;
                text-transform: uppercase;
                color: #3498db;
            }
        }

        .keep-scrolling {
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            bottom: 20px;
            right: 5px;
            margin-top: 32px;
            color: $faded-gray;

            @media (max-width: map-get($breakpoints, 'tablet')) {
                right: 30px;
                bottom: 30px;
            }
        }

        .arrow-container {
            width: 40px;
            height: 40px;
            position: relative;

            .arrow {
                position: absolute;
                top: 50%;
                right: 10px;
                transform: translateY(-50%);
                width: 20px;
                height: 2px;
                background-color: $faded-gray;
            }

            .arrow::after {
                content: '';
                position: absolute;
                top: -5px;
                right: 0;
                width: 10px;
                height: 10px;
                border-top: 2px solid $faded-gray;
                border-right: 2px solid $faded-gray;
                transform: rotate(45deg);
            }

            @keyframes slide {
                0% {
                    transform: translateX(0);
                }
                50% {
                    transform: translateX(20px);
                }
                100% {
                    transform: translateX(0);
                }
            }

            .animated-arrow {
                animation: slide 3s ease-in-out infinite;
            }
        }
    }
}
