@import '../../../../Style/theme.scss';

.PetSection {
    padding: 80px 16px;
    background-color: $surface-grey-light;
    width: 100%;

    .WrapperSectionTitle {
        text-align: center;
        padding: 0 16px;
        text-transform: capitalize;
    }

    .wrapper-slider {
        padding-top: 40px;
        width: 870px;
        margin-left: auto;
        margin-right: auto;
        position: relative;

        .overlay-blur {
            position: absolute;
            width: 100px;
            height: 250px;
            z-index: 2;
            touch-action: none;
            pointer-events: none;

            &.-left {
                top: 0;
                left: -10px;
                background: linear-gradient(270deg, rgba(246, 246, 246, 0) 0%, #f9f9f9 100%);
            }

            &.-right {
                top: 0;
                right: -10px;
                background: linear-gradient(90deg, rgba(246, 246, 246, 0) 0%, #f9f9f9 100%);
            }

            @media (max-width: 764px) {
                width: 80px;
            }
        }

        @media (max-width: 840px) {
            max-width: 100%;
        }
    }
}
