@import './../../../../../Style/theme.scss';
  
.CardLeader {
    width: 253px;
    height: 375px;
    position: relative;
    perspective: 1000px;
    background-color: transparent;
    cursor: pointer;

    .card-container {
        width: 100%;
        height: 100%;
        position: absolute;
        transition: all 1.1s;
        transform-style: preserve-3d;

        &.-selected {
            transform: rotateY(180deg);
        }

        .side {
            height: 100%;
            width: 100%;
            position: absolute;
            backface-visibility: hidden;

            .card-leader-image {
                width: 253px;
            }
            
            &.-front {
                z-index: 1;
            }

            &.-back {
                transform: rotateY(180deg)
            }
        }
    } 

    .wrapper-leader-info {
        position: absolute;
        overflow: hidden;
        bottom: 0;
        width: 100%;
        background: linear-gradient(180deg, rgba(22, 22, 22, 0) 0%, rgba(22, 22, 22, 0.637804) 42%, rgba(14, 14, 14, 0.859493) 71%, #0A0A0A 100%);
        border-radius: 0 0 30px 30px;
        padding-top: 20px;

        &.-center-content {
            display: flex;

            .infos {
                justify-content: center;
                align-items: center;
            }
        }

        .infos {
            padding: 16px;

            .vanish {
                opacity: 0;
            }

            .job-title {
                @media (max-width: 1000px) {
                    font-size: 14px !important;
                }
            }

            .text-description {
                font-size: 12.5px !important;
            }

            .click-to-see {
                color: #eaeaea5b;
            }

            &.-lasted {
                margin-bottom: 0;
                width: 150px;
                margin-left: auto;
                margin-right: auto;
            }
        }

        &.-selected {
            height: calc(100% - 20px);
            background: #000000A6;
            border-radius: 30px;
            backdrop-filter: blur(8px);

            .infos {
                padding-top: 25px;
                display: flex;
                flex-direction: column;
                gap: 16px;
            }
        }
    }

    .leader-extra-link {
        width: fit-content;
    }

    .wrapper-close-icon {
        position: absolute;
        top: 20px;
        right: 20px;
        border: 2px solid $white;
        border-radius: 150px;
        width: 20px;
        height: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 120;
    }
}
