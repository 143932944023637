@mixin partialEllipseRotationAnimation($animationName, $startDeg, $endDeg, $duration, $delay) {
    @keyframes #{$animationName} {
        0% {
            transform: rotate($startDeg);
        }
        50% {
            transform: rotate($endDeg);
        }
        100% {
            transform: rotate($startDeg);
        }
    }
    animation: #{$animationName} $duration $delay infinite;
}

.AnimatedEllipses {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 66%;
    overflow: hidden;
    z-index: 0;

    .ellipsesContainer {
        position: absolute;
        width: 100%;
        height: 100%;

        .ellipse {
            position: absolute;
            width: 100%;
            height: 100%;
        }

        .ellipse1 {
            @include partialEllipseRotationAnimation(ellipse1, 0, 10deg, 10s, 2s);
        }

        .ellipse2 {
            @include partialEllipseRotationAnimation(ellipse2, 90deg, 80deg, 10s, 0s);
        }

        .ellipses {
            position: absolute;
            width: 500px;
            height: 500px;
        }

        .ellipses-topRight {
            top: -150px;
            right: -240px;
        }

        .ellipses-bottomLeft {
            transform: scale(-1, -1);
            bottom: -150px;
            left: -240px;
        }
    }
}
