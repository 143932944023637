@import '../../../../Style/theme.scss';
@import '../../../../Style/typographie.scss';

.ExperienceHowItWorks {
    background-color: $surface-grey-light;
    margin-top: 0;

    ul,
    li {
        list-style: none;
        padding: 0;
        margin: 0;
    }

    &,
    & > * {
        box-sizing: border-box;
    }

    .content {
        width: 100%;
        max-width: 961px;
        margin: 0 auto;
    }

    .disclaimer {
        text-align: center;
        margin-top: 6px;
        margin-bottom: 32px;

        @media screen and (max-width: 768px) {
            margin-top: 0;
            margin-bottom: 12px;
        }
    }

    .title {
        text-align: center;
        margin-bottom: 8px;

        @media screen and (max-width: 768px) {
            margin-bottom: 8px;
        }
    }

    .subtitle {
        text-align: center;

        bold {
            font-weight: 600;
            font-size: 18px;
            color: #2b2b2b;
        }

        @media screen and (max-width: 768px) {
            padding-bottom: 8px;
        }
    }

    .steps {
        display: flex;
        justify-content: center;
        gap: 32px;

        @media screen and (max-width: 1180px) {
            gap: 24px;
        }

        @media screen and (min-width: 1000px) and (max-width: 1180px) {
            .step {
                img {
                    width: 95%;
                    height: auto;
                }
            }
        }
    }
}

