.lib-modal-container {
    padding: 0;
    border-radius: 10px;
    max-width: none;

    .WelcomeModal {
        display: flex;

        .left {
            border-radius: 10px 0 0 10px;
            display: flex;
            flex-direction: column;
            background-size: cover;
            background-repeat: no-repeat;
            .align {
                display: flex;
                flex-direction: column;
                align-items: center;
                margin: auto;
                max-width: 458px;
                text-align: center;
                margin-top: 50px;
                p {
                    padding-top: 20px;
                }
            }
            .img {
                max-width: 580px;
                width: 100%;
                margin-top: 40px;
            }
        }

        .right {
            border-radius: 0 10px 10px 0;
            background-color: white;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            row-gap: 40px;
            padding: 0 50px;
            position: relative;

            .align {
                text-align: center;
                p {
                    padding-top: 10px;
                }
            }
            .logo {
                width: 95px;
                height: 95px;
            }
            .Button {
                width: 80%;
                box-shadow: 0px 6px 12px 0px rgba(28, 39, 49, 0.15);
            }

            canvas {
                //this is the confetti
                right: 0;
            }
        }
    }

    .Carousel {
        max-width: 90vw;
    }

    @media screen and (max-width: 1020px) {
        .WelcomeModal {
            .right {
                .Button {
                    max-width: 250px;
                }
            }
        }
    }

    @media screen and (max-width: 950px) {
        .WelcomeModal {
            .Carousel {
                border-radius: 10px;

                .slick-list {
                    border-radius: 10px;
                    .slick-track {
                        background: white;
                    }
                }
                .left {
                    .align {
                        margin: auto;
                        margin-top: 20px;
                    }
                }

                .right {
                    padding: 10px;
                    padding-top: 25%;
                    .logo {
                        max-width: 52px;
                        max-height: 52px;
                    }
                }
            }

            .dots-container {
                display: flex;
                justify-content: center;
                align-items: center;
                margin-top: 20px;
                column-gap: 10px;
                .dot {
                    width: 15px;
                    height: 15px;
                    border-radius: 25px;
                    background: transparent;
                    border: 2px solid white;
                }

                .active {
                    background: white;
                    border: none;
                }
            }
        }
    }

    @media screen and (max-width: 500px) {
        .WelcomeModal {
            .Carousel {
                .left {
                    .align {
                        margin: 20px;
                    }
                }
                .right {
                    row-gap: 20px;
                }
            }
        }
    }
}

.react-responsive-modal-modal {
    &.welcome-modal {
        background: transparent;
        box-shadow: none;

        display: flex;
        justify-content: center;
        align-items: center;
    }
}
