@import './../../Style/theme.scss';

.Form {
    background-color: $white;
    border-radius: 10px;
    width: 100%;

    .form-content {
        padding: 40px;
        display: flex;
        flex-direction: column;
        gap: 20px;
    }

    .form-header-line {
        height: 11px;
        width: 100%;
        background-color: $surface-brand-rest-primary;
        border-radius: 10px 10px 0 0;
    }

    .form-title {
        text-align: center;
        margin-top: 29px;
    }
}
