@import '../../../../Style/theme.scss';
@import '../../../../Style/typographie.scss';

.LifeAtSpreeAIPageSectionBenefits {
    padding: 80px 16px;
    width: 100%;
    max-width: 1200px;

    ul,
    li {
        margin: 0;
        padding: 0;
        list-style: none;
    }

    &,
    & > * {
        box-sizing: border-box;
    }

    .content {
        max-width: 1500px;
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 24px;
        margin: 0 auto;

        .title {
            text-align: center;

            @media (max-width: 800px) {
                text-align: left;
            }
        }
    
        .sub-paragraph {
            max-width: 850px;
            text-align: center;
            padding-bottom: calc(64px - 26px);
            margin-left: auto;
            margin-right: auto;

            @media (max-width: 800px) {
                text-align: left;
            }

            @media (max-width: 600px) {
                padding-bottom: calc(40px - 26px);
            }
        }
    }

    .mobile-carousel-container {
        display: flex;
        justify-content: left;
        .Carousel {
            max-width: 532px;

            @media screen and (max-width: 530px) {
                max-width: 400px;
            }

            @media screen and (max-width: 400px) {
                max-width: 300px;
            }
        }
    }


    .benefits {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 40px;
        flex-wrap: wrap;

        .benefit {
            display: flex;
            gap: 24px;
            max-width: 540px;

            .benefit-title {
                font-weight: 600 !important;
            }

            .benefit-wrapper-icon {
                min-width: 80px;
                height: 80px;
                border-radius: 20px;
                
                display: flex;
                justify-content: center;
                align-items: center;

                background-color: $white !important;
            }

            .benefit-info {
                display: flex;
                flex-direction: column;
                gap: 8px;

                .benefit-description {
                    color: $element-greys-secondary-alt;
                    @media (max-width: 600px) {
                        font-size: 14px !important;
                        
                    }
                }
            }
        }
    }
    
    @media screen and (max-width: 1450px) {
        .benefits {
            grid-template-columns: repeat(2, 1fr);
        }
        max-width: 900px;
    }
    
    @media screen and (max-width: 950px) {
        padding: 80px 16px;

        .benefits {
            grid-template-columns: repeat(1, 1fr);
            padding-top: 16px;

            .benefit-title {
                font-weight: 600 !important;
            }
        }

        .title {
            text-align: left;
        }
    }
}

