@import "./../../../../../Style/breakpoint.scss";
@import "./../../../../../Style/theme.scss";

.RequirementCards {
    background-color: $white;
    border-radius: 15px 15px 30px 30px;
    border-top: 12px solid $white;
    max-width: 472px !important;

    display: flex;
    flex-direction: column;

    height: 768px;

    .inner-top {
        display: flex;
        flex-direction: column;
        gap: 24px;
        margin-bottom: 24px;
        padding: 36px 24px 0;

        .text-main-header {
            font-size: 32px !important;
            font-weight: 600 !important;

            @media screen and (max-width: 600px) {
                font-size: 24px !important;
            }
        }

        .text-desc {
            color: #3F3F3F;
            padding-bottom: 16px;
            border-bottom: 1px solid #00000033;
        }
    }

    .inner-body {
        padding: 0 24px;

        .item {
            padding: 24px 0;
            .text-body-medium {
                color: #3F3F3F;
            }

            border-bottom: 1px solid #00000033;
        }
    }

    .Button {
        border: 1px solid #00000033;
        margin-left: auto;
        margin-right: auto;
        margin-top: auto;
        gap: 10px;
        margin-bottom: 48px;
        width: 90%;

        .button-icon {
            margin-left: 0 !important;
        }

        &:hover {
            .button-icon {

                svg {
                    path {
                        fill: white;
                    }
                }
            }
            &.-green {
                background-color: #2EB67D !important;
            }
            &.-purple {
                background-color: #9F2589 !important;
            }
        }

        &.-selected {
            .button-icon {

                svg {
                    path {
                        fill: white;
                    }
                }
            }

            &.-green {
                background-color: #2EB67D !important;
                .label {
                    color: $white !important;
                }

            }
            &.-purple {
                background-color: #9F2589 !important;
                .label {
                    color: $white !important;
                }

            }
        }

        @media screen and (max-width: 1000px) {
            width: 90%;
            .button-icon {
                display: none;
            }
            &.-green {
                background-color: #2EB67D !important;
                .label {
                    color: $white !important;
                }
            }
            &.-purple {
                background-color: #9F2589 !important;
                .label {
                    color: $white !important;
                }
            }

        }
    }
}