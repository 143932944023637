@import '../../../../Style/theme.scss';
@import '../../../../Style/typographie.scss';
@import '../../../../Style/breakpoint.scss';

.Partner {
    padding: 120px;
    width: 100%;
    position: relative;

    .background {
        position: absolute;
        height: 100%;
        z-index: -1;
        top: 0;
        left: 0;
        -o-filter: blur(8px);
        filter: blur(8px);

        @media screen and (max-width: 1000px) {
           width: 100%;
           height: 50%;
        }
    }

    .background2 {
        position: absolute;
        height: 100%;
        z-index: -2;
        top: 0;
        right: 0;
        -o-filter: blur(8px);
        filter: blur(8px);
        
        @media screen and (max-width: 1000px) {
            width: 100%;
            height: 50%;
            top: 50%;
            bottom: 0;
            left: 0;
         }
     }

     .sub-overlay {
        background: #2B2B2BCC;
        opacity: 0.6;
        position: absolute;
        height: 100%;
        width: 100%;
        z-index: 0;
        top: 0; 
        left: 0;
    }

     .overlay {
        background: linear-gradient(to top, rgba(0, 0, 0, 0.82) 18%, rgba(43, 43, 43, 0.2));
        position: absolute;
        height: 100%;
        width: 100%;
        z-index: 1;
        top: 0; 
        left: 0;
    }
    
    &,
    & > * {
        box-sizing: border-box;
    }

    &__content {
        position: relative;
        z-index: 10;
        width: 100%;
        max-width: 1280px;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .title {
        text-align: center;
        margin: 0 auto 36px;
        max-width: 838px;
    }

    .subtitles {
        display: flex;
        flex-direction: column;
        gap: 20px;
        padding-bottom: 34px;
    }

    .subtitle {
        text-align: center;
        margin: 0 auto;
        max-width: 838px;

        span {
            color: #2EB67D;
            font-weight: bold;
        }
    }

    .companies,
    .compagny {
        list-style: none;
    }

    .companies {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 120px;
    }

    .compagny {
        img {
            width: 263px;
        }
    }

    @media screen and (max-width: 700px) {
        .companies {
            gap: 20px;
        }
    }

    @media screen and (max-width: 600px) {
        .companies {
            justify-content: space-around;

            .compagny {
                img {
                    width: 200px;
                }
            }
        }
    }

    @media screen and (max-width: 450px) {
        .companies {
            gap: 20px;
            .compagny {
                img {
                    width: 150px;
                }
            }
        }
    }

    @media screen and (max-width: map-get($breakpoints, 'tablet')) {
        padding: 80px 80px;
    }
    
    @media screen and (max-width: map-get($breakpoints, 'l')) {
        & {
            padding: 80px 16px;
    
            .title {
                margin-bottom: 40px;
                text-align: left;
            }
    
            .subtitle {
                margin: 40px 0;
                padding: 0;
                text-align: left;
            }
    
            .compagny {
                img {
                    width: 233px;
                    padding: 25px;
                }
            }
        }
    }
    
    @media screen and (max-width: map-get($breakpoints, 'm')) {
        & {
            .companies {
                display: flex;
                flex-direction: row;
                gap: 10px;
                padding-top: 40px;
            }

            .compagny {
                img {
                    padding: 0;
                }
            }
        }
    }
}

