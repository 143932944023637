.MediaStrapi {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0;

    img {
        width: 100%;
        border-radius: 20px;
    }
}   