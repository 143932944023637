@import './../../../Style/theme.scss';

$BREAKPOINT: 800px;

.TermsPolicyPage {
    .terms-hero {
        height: 400px;
        background-color: $surface-grey-opposite;
        color: $white !important;
        text-align: center;
        display: flex;

        * {
            color: $white !important;
        }
    }

    .deprecated-text-display-large {
        margin-left: auto;
        margin-right: auto;
        margin-top: auto;
        margin-bottom: auto;
    }

    .terms-content {
        margin-top: 80px;
        width: 65%;
        margin-left: auto;
        margin-right: auto;
        text-align: justify;

        @media screen and (max-width: $BREAKPOINT) {
            width: 80%;
        }
    }

    .terms-section {
        margin-top: 40px;
        margin-bottom: 80px;
        .deprecated-text-display-small {
            margin-bottom: 40px;
        }
    }
}
