@import "./../../../../Style/theme.scss";

.VogueSection {
    position: relative;
    width: 100%;
    
    video,
    .overlay-text,
    .overlay-text-reverse {
        position: absolute;
        left: 50%;
        transform: translate(-50%, 0%);
        width: 100%;
        transition: opacity 2s ease-in-out;
    }

    video {
        top: 0;
        z-index: 0;
    }

    .video {
        width: 130%;
    }
    
    .overlay-text {
        top: 0;
        z-index: 1;
        opacity: 1;
        width: 130%;

        &.-visible {
            opacity: 0;
        }

        @media (max-width: 768px) {
            height: 100%;
            width: none !important;
        }
    }

    .overlay-text-reverse {
        top: 0;
        z-index: 1;
        opacity: 0;

        &.-visible {
            opacity: 1;
        }

        width: 130%;

        @media (max-width: 768px) {
            height: 100%;
            width: none !important;
        }
    }

    .text-overlay-fix {
        top: 27%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 100;
        width: 50%;
        justify-content: center;
        opacity: 0;
        position: absolute;

        display: flex;
        align-items: center;
        gap: 7px;

        transition: opacity 2s ease-in-out;

        .description-2 {
            span {
                font-weight: bold;
            }
        }

        .logo-container {
            padding-top: 6px;
        }

        &.-visible {
            opacity: 1;
        }

        &.-bottom {
            width: 768px;
            top: 63%;
            left: 50%;

            display: flex;
            flex-direction: column;
            gap: 5px;

            @media (max-width: 768px) {
                width: calc(100% - 32px) !important;
                top: 72%;
            }

            @media (max-width: 450px) {
                width: calc(100% - 32px) !important;
                top: 78%;
            }

        }

        @media (max-width: 768px) {
            width: calc(100% - 32px);
            top: 35%;
        }

    }

    @media (max-width: 768px) {
        img {
            object-fit: cover;
        }
    }
}