@import 'src/Style/theme.scss';

.Step4Email {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .whitecard-container {
        padding: 80px;
        .WhiteCard {
            box-shadow: 0px 4px 20px 0px #0000000D;
            max-width: 510px;
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
            gap: 18px;
            border: 1px solid #0000001A;
            border-radius: 20px;

            .wrapper-icon {
                border: 3px solid #2EB67D;
                border-radius: 100px;
                padding: 12px;

                display: flex;
                align-items: center;
                justify-content: center;
            }

            .text {
                max-width: 344px;
            }

            .back-to-home-page {
                .label {
                    text-decoration: underline;
                    color: $element-brand-rest-primary !important;
                }
            }

            .spacer {
                width: 100%;
                height: 1px;
                background-color: #00000040;
            }

            @media (max-width: 768px) {
                padding: 40px !important;
            }
        }
        @media (max-width: 768px) {
            padding: 80px 16px !important;
        }
    }
}
