.PartnerRessourcesRequirementsMobile {
    width: 100%;
    background-color: rgba(246, 246, 246, 1);
    padding: 0 0 55px 0;

    .half-circle {
        width: 75%;
        height: 300px;
        background-color: rgba(46, 182, 125, 1);
        border-radius: 100%;
        transform: translate(15%, -50%);

        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 60px;

        z-index: 1;
        position: relative;

        margin-bottom: -100px;

        .WrapperSectionTitle {
            display: flex;
            flex-direction: column;
            gap: 10px;
            text-align: center;

            position: absolute;
            bottom: 50px;

            max-width: 200px;
        }

        @media screen and (max-width: 436px) {
            width: 95%;
            height: 300px;
            transform: translate(2%, -50%);
        }
    }

    .Carousel {
        max-width: 844px;
        padding: 0 16px;
        margin-left: auto;
        margin-right: auto;

        @media screen and (max-width: 900px) {
            max-width: 432px;
        }

        .element {
            min-height: 0;
            width: 100%;
            display: flex;
            justify-content: center;
            margin-left: auto;
            margin-right: auto;
            flex-direction: column;
            gap: 60px;
            cursor: pointer;
    
            .image {
                width: calc(100% - 32px);
                margin-left: auto;

                img {
                    width: calc(100% - 32px);
                }


                .WrapperVideo {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    border-radius: 10px;

                    video {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        border-radius: 30px;
                    }
                }
            }

            .desc {
                text-align: center;
            }
        }

    }

}

.PartnerRessourcesRequirements {
    height: 725px;
    width: 100%;
    background-color: rgba(246, 246, 246, 1);

    display: flex;
    .left {
        z-index: 10;
        height: 100%;
        display: flex;
        .base {
            width: 10px;
            height: 100%;
        }

        .half-circle {
            width: 500px;
            height: 100%;
            background-color: rgba(46, 182, 125, 1);
            border-radius: 0 100% 100% 0;

            display: flex;
            align-items: center;

            .WrapperSectionTitle {
                display: flex;
                flex-direction: column;
                gap: 10px;
                margin-left: 50px;
            }

            @media screen and (max-width: 1334px) {
                width: 450px;
            }

            @media screen and (max-width: 1000px) {
                width: 350px;
            }
        }
    }

    .right {
        height: 100%;
        width: 100%;
        display: flex;
        position: relative;
        flex-direction: column;

        .elements {
            display: flex;
            gap: 75px;
            margin-top: auto;
            margin-bottom: 30px;
            transition: all 1s ease-in-out;

            .element {
                cursor: pointer;
                width: 325px;
                display: flex;
                flex-direction: column;
                gap: 32px;
                position: relative;

                .image {
                    width: 325px;
                    height: 300px;
                    box-shadow: 0px 25px 40px -10px rgba(28, 39, 49, 0.08);
                    border-radius: 10px;

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        border-radius: 10px;
                    }

                    .WrapperVideo {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        border-radius: 10px;

                        video {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                            border-radius: 20px;
                        }
                    }
                }

                .overlay {
                    position: absolute;
                    top: 0;
                    right: 0;
                    width: 100%;
                    height: 100%;
                    background-color: rgba(246, 246, 246, 0.5);
                    z-index: 5;
                }
            }
        }

        .wrapper-control {
            display: flex;
            gap: 40px;
            margin-bottom: 60px;
            width: inherit;

            .control {
                width: 40px;
                height: 40px;
                border-radius: 50px;

                display: flex;
                justify-content: center;
                align-items: center;

                cursor: pointer;

                &.-disabled-true {
                    background-color: rgba(63, 63, 63, 0.25) !important;
                }
            }
        }
    }
}

.lib-modal-container {
    padding: 0 !important;
    width: 100%;
    height: 100%;
    margin: 0;
    border-radius: 10px;
    max-width: none;
    border-radius: 0;
    padding: 0px !important;
    overflow-y: hidden;
}

.requirement-modal {
    .wrapper-element {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;

        .element {
            width: 515px;
            position: relative;
            display: flex;
            flex-direction: column;
            gap: 32px;
            .image {
                width: 515px;
                height: auto;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }

                .WrapperVideo {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    border-radius: 10px;

                    video {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        border-radius: 20px;
                    }
                }

            }

            .desc {
                color: white;
                text-align: left;
            }


            .close-icon-wp {
                position: absolute;
                top: 0px;
                left: 0px;
                cursor: pointer;
                z-index: 100000;
                padding: 20px;
                .close-icon {
                }
            }

            @media screen and (max-width: 600px) {
                width: 90%;
                margin-left: auto;
                .image {
                    width: 90%;
                }

                .desc {
                    width: 90%;
                }
                
            }
        }

    }
}

span {
    &.bold {
        font-weight: bold;
    }

    &.color-green {
        color: rgba(46, 182, 125, 1);
    }
    &.color-purple {
        color: rgba(159, 37, 137, 1);
    }
}