@import 'src/Style/theme';

.CreateAccountFooter {
    background-color: $surface-grey-opposite;
    width: 100%;
    margin-top: auto;

    .inner {
        height: 70px;
        margin: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 20px;
        text-align: center;
    }
}
