@import 'src/Style/index';
@import './Style/theme.scss';

body {
    margin: 0;

    h1,
    h2,
    h3,
    h4 {
        margin: 0;
        font-weight: normal;
    }
}

bold {
    font-weight: bold;
}

.Toastify__progress-bar--success {
    background: $surface-brand-rest-primary;
}

.Toastify__toast-icon {
    svg {
        fill: $surface-brand-rest-primary;
    }
}

a {
    text-decoration: none;
}

* {
    font-family: 'montserrat', sans-serif;
}

.card-account-modal {
    width: 840px;

    .card-account-modal-content {
        div {
            text-align: center;
        }

        .deprecated-text-title-medium-bold {
            cursor: pointer;
            text-align: center;
            margin-top: 33px;
        }

        .modal-description {
            color: $element-greys-tertiary;
        }

        .spacer {
            height: 1px;
            width: 100%;
            background-color: #e5e5e6;

            margin: 47px 0;
        }

        .row-btn {
            display: flex;
            justify-content: center;
            gap: 20px;
        }

        .button-primary {
            background-color: $surface-error-rest-primary;

            &:hover {
                background-color: $surface-error-overlay-primary;
            }
        }

        .button-white {
            &:hover {
                background-color: $white;
                border: 1px solid $black;
            }
        }
    }

    @media screen and (max-width: 950px) {
        width: 90%;
        height: 90%;

        .row-btn {
            flex-direction: column;
        }
    }

    @media screen and (max-width: 450px) {
        justify-content: center;
        align-items: center;

        .modal-content {
            padding: 30px 30px !important;
        }

        .deprecated-text-display-small {
            font-size: 34px;
        }

        .modal-description {
            font-size: 14px;

            &.mt-40 {
                margin-top: 20px !important;
            }
        }
    }
}
