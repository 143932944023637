@import './../../../../Style/theme.scss';
@import './../../../../Style/typographie.scss';

.IntellectualProperty {
    position: relative;
    padding: 120px 16px;

    .background {
        position: absolute;
        height: 100%;
        z-index: -1;
        top: 0;
        left: 0;
        -o-filter: blur(15px);
        filter: blur(15px);

        //add blur
    }

    .overlay {
        background: linear-gradient(0deg, #2B2B2B, #2B2B2B),
        linear-gradient(95.63deg, #399DA9 30%, #34A797 45.42%, #2FB481 55.35%, #2EB67D 68.95%),
        linear-gradient(0deg, rgba(43, 43, 43, 0.8), rgba(43, 43, 43, 0.8));
//add blur

        opacity: 0.8;

        position: absolute;
        height: 100%;
        width: 100%;
        z-index: 0;
        top: 0; 
        left: 0;
    }

    &,
    & > * {
        box-sizing: border-box;
    }

    &__ellipses {
        position: absolute;

        &--topRight {
            top: 0;
            right: 0;
        }

        &--bottomLeft {
            bottom: 0;
            left: 0;
            transform: rotate(180deg);
        }
    }

    .content {
        position: relative;
        z-index: 1;
        max-width: 1152px;
        width: 100%;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .title {
        margin-bottom: 40px;
    }

    .subtitles {
        display: flex;
        flex-direction: column;
        padding-bottom: 64px;
        gap: 20px;
    }

    .subtitle {
        max-width: 636px;
        color: $white-e8 !important;
        text-align: center;
    }

    .title {
        color: $element-greys-white;
        text-align: center;
    }

    .patents {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 40px;

        .patent {
            padding: 40px 40px 40px;
            display: flex;
            height: 190px;
            width: 250px;
            aspect-ratio: 255 / 225;
            box-sizing: border-box;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            border-radius: 30px;
            background: radial-gradient(100% 217.08% at 0% 32.92%, rgba(57, 157, 169, 0.75) 0%, rgba(47, 180, 129, 0.75) 65.09%, rgba(46, 182, 125, 0.75) 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
            linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1));
            box-shadow: 0px 4px 30px 0px #2EB67D66;

            .amount {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 126px;
                text-align: center;
                margin-bottom: 24px;
                text-align: center;
                font-size: 57px !important;
            }

            @media screen and (max-width: 660px) {
                height: 190px;
                width: 250px;
            }
        }
    }


}

@media screen and (max-width: 1000px) {
    .IntellectualProperty {
        padding: 80px 80px;
    }
}


@media screen and (max-width: 660px) {
    .IntellectualProperty {
        padding: 80px 16px;

        .content {
            align-items: normal;
        }

        .title {
            margin-bottom: 24px;
            text-align: left;
        }

        .subtitle {
            text-align: left;
        }

        .patents {
            flex-direction: column;
        }
    }
}
