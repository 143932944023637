@import '../../../../Style/theme.scss';
@import '../../../../Style/typographie.scss';

.HowItWorks {
    background-color: $surface-grey-light;
    .WrapperSection {
        padding-bottom: 64px !important;

        @media screen and (max-width: 1000px) {
            padding-left: 0 !important;
            padding-right: 0 !important;
            padding-bottom: 105px !important;
        }
    }

    ul,
    li {
        list-style: none;
        padding: 0;
        margin: 0;
    }

    &,
    & > * {
        box-sizing: border-box;
    }

    &__content {
        width: 100%;
        margin: 0 auto;
    }

    .title {
        text-align: center;
        margin-bottom: 8px;
    }

    .subtitle {
        text-align: center;
        padding: 0 16px;

        bold {
            font-weight: 600;
        }
    }

    .steps {
        display: flex;
        justify-content: center;
        gap: 32px;
    }

    @media screen and (max-width: 1000px) {
        & {
            .title {
                text-align: center;
                margin-bottom: 16px;
            }

            .Carousel {
                img {
                    height: 474px;
                    width: auto;
                }
            }
        }
    }

    @media screen and (max-width: 400px) {
        & {
            .Carousel {
                width: 100%;

                img {
                    height: auto;
                    width: calc(100% - 32px);
                }
            }
        }
    }
}
