@import './../../Style/theme.scss';

.stepBaseStyle {
    .deprecated-text-display-large {
        margin-top: 20px;
    }

    .inputs-container {
        display: flex;
        flex-direction: column;
        width: 100%;
        row-gap: 60px;
        margin-top: 120px;

        .row {
            display: flex;
            column-gap: 40px;
        }
    }

    .bottom-section {
        margin-top: 120px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .navigation-container {
            display: flex;
            align-items: center;
            .prev-btn {
                margin-left: auto;
                margin-right: 20px;
                &:hover {
                    color: $surface-brand-rest-primary;
                }
            }
        }
    }

    .Button {
        min-width: 0 !important;
    }

    @media screen and (max-width: 950px) {
        h3 {
            font-size: 16px;
        }
        h1 {
            font-size: 32px;
            line-height: 40px;
        }
        .inputs-container {
            row-gap: 40px;
            margin-top: 50px;
            .row {
                flex-direction: column;
                row-gap: 40px;
            }
        }

        .bottom-section {
            margin-top: 60px;
        }
    }

    @media screen and (max-width: 585px) {
        .bottom-section {
            margin-top: 60px;
            flex-direction: column;
            align-items: flex-start;
            row-gap: 40px;

            .navigation-container {
                flex-direction: column-reverse;
                row-gap: 20px;
                margin: auto;

                .prev-btn {
                    margin: 0;
                }
            }
        }
    }
}
