@import './../../../../Style/theme.scss';

.FileInput {
    display: flex;
    flex-direction: column;
    width: 100%;

    label {
        padding-bottom: 4px;
        font-size: 12px !important;
    }

    textarea {
        border: 1px solid $surface-grey-disabled;
        border-radius: 4px;
        padding: 13px 8px;

        height: auto;
        min-height: 145px;

        &:focus-visible {
            outline: 1px solid $surface-brand-rest-secondary;
        }
    }

}