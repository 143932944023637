.InstructionsSent {
    background: linear-gradient(black 50%, white 50%);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 85px 0;

    .WhiteCard {
        height: 700px;
        box-shadow: 0px 25px 40px -10px rgba(28, 39, 49, 0.08);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        row-gap: 30px;
        max-width: 480px;
        text-align: center;
        padding: 0 80px;

        .Button {
            width: 250px;
        }
    }

    @media screen and (max-width: 750px) {
        padding: 85px 20px;
        .WhiteCard {
            width: 100%;
            padding: 20px;
            height: 400px;

            svg {
                width: 55px;
                height: 55px;
            }
        }
    }
}
