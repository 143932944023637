@import '../../../../../Style/typographie.scss';
@import '../../../../../Style/theme.scss';

.Article {
    &,
    & > * {
        box-sizing: border-box;
    }

    margin-top: 80px;
    padding-bottom: 127px;
    // padding: 80px 16px;

    .content-body {
        padding: 0 16px;
        max-width: 836px;
        width: 100%;
        margin: 0 auto;

        .BreadCrumb {
            margin-bottom: 32px;
            margin-top: 80px;
        }

        .container-embed {
            padding: 15px 0;
            iframe {
                width: 100%;
                height: 500px;
                border-radius: 20px;
            }
        }
    }

    .content-header {
        position: relative;

    }

    .wrapper-video {
        .img {
            margin-left: auto;
            margin-right: auto;            
            
            overflow-y: hidden;

            height: 100%;
            width: 100%;
            object-fit: cover;

            @media (max-width: 768px) {
                width: auto;
                position: relative;
                top: 0;
                left: 50%;
                transform: translate(-50%, 0);
            }
        }
    
        &::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.4);
            background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) -10.77%, rgba(0, 0, 0, 0.11) 36.35%, rgba(0, 0, 0, 0.20) 53.04%, rgba(0, 0, 0, 0.43) 67.72%, rgba(0, 0, 0, 0.65) 85.79%, #000 107.06%), linear-gradient(0deg, rgba(0, 0, 0, 0.50) 0%, rgba(0, 0, 0, 0.50) 100%);
            overflow-y: hidden;    
        }

        @media (max-width: 768px) {
            width: auto;
            height: 840px !important;
            position: relative;
            top: 0;
            left: 50%;
            transform: translate(-50%, 0);
        }
    }

    .wrapper-content-info {
        position: absolute;
        bottom: 180px;
        left: 0;
        z-index: 2;
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: end;
        align-items: center;
    
        .content-info {
            max-width: 1200px;

            display: flex;
            flex-direction: column;
            gap: 32px;

            .category {
                border: 1px solid $white;
                padding: 0 19px;
                width: max-content;
                border-radius: 5px;
                text-transform: uppercase;
                border-radius: 5px;
            }

            .wrapper-time {
                display: flex;
                gap: 8px;
                align-items: center;
            }
        }

        @media (max-width: 1400px) {
            bottom: 60px;
        }

        @media (max-width: 1250px) {
            bottom: 40px;
        }

        @media (max-width: 1200px) {
            bottom: 20px;
            .content-info {
                margin-left: 16px;
            }
        }

    }

    .overlay {
        position: absolute;
        left: 40%;
        bottom: 0;
        transform: translate(-50%, -50%);
        z-index: 1;
        width: max-content;

        @media (max-width: 1650px) {
            max-width: 1100px;
        }

        @media (max-width: 1300px) {
            max-width: 1000px;
        }

        @media (max-width: 1150px) {
            max-width: 900px;
        }

        @media (max-width: 1110px) {
            max-width: 700px;
        }

        @media (max-width: 750px) {
            max-width: 600px;
        }

        @media (max-width: 650px) {
            max-width: 500px;
        }

        @media (max-width: 550px) {
            max-width: 400px;
        }

        .WrapperSectionTitle {
            margin-bottom: 16px;
        }

        .extra-info {
            margin-left: 3px;
        }

        @media (max-width: 1600px) and (min-width: 1000px) {
            position: absolute;
            left: 45%;
            bottom: 10%;
            transform: translate(-50%, -50%);
            z-index: 1;
        }

        @media (max-width: 1000px) and (min-width: 580px) {
            position: absolute;
            left: 50%;
            bottom: 10%;
            transform: translate(-50%, -50%);
            z-index: 1;

            display: flex;
            flex-direction: column;
            text-align: center;
        }

        @media (max-width: 580px) {
            position: absolute;
            bottom: 100px;
            left: 16px;
            right: 0;

            transform: translate(0, 0);
            width: calc(100% - 32px);

            display: flex;
            flex-direction: column;
            text-align: left;
        }
    }


    .WrapperSectionTitle {
        margin-bottom: 31px;
    }

    .date,
    .author {
        @include body-large-mixin;
        color: $element-greys-secondary;
    }

    .date {
        margin-bottom: 20px;
    }

    .author {
        margin-bottom: 31px;
    }

    .wrapper-icon-share {
        display: flex;
        align-items: center;
        gap: 24px;
        margin-bottom: 31px;

        > * {
            cursor: pointer;
        }
    }
}
