.RichText {
    display: flex;
    flex-direction: column;
    gap: 20px;

    p {
        line-height: 160%;
    }

    ul {
        list-style-position: outside !important;

        li {
            padding-left: 10px;
            margin-left: 18px;
        }
    }
}   