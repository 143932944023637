@import '../../../../../Style/theme.scss';

.AdvantageCard {
    max-width: 360px;

    .advantage-card {
        box-shadow: 0 5px 40px -10px rgba(0, 0, 0, 0.05);
    }

    .WhiteCard {
        display: flex;
        flex-direction: column;
        row-gap: 21px;
        height: 210px;

        .texts {
            display: flex;
            flex-direction: column;
            row-gap: 21px;
        }

        @media screen and (max-width: 779px) {
            height: auto;
            flex-direction: row;
            column-gap: 21px;

            .texts {
                row-gap: 10px;
            }
        }
    }

    @media screen and (max-width: 480px) {
        max-width: none;
    }
}
