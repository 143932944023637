@import './../../Style/theme.scss';

.lib-modal-container {
    padding: 0 !important;
    width: 100%;
    height: 100%;
    margin: 0;
    max-width: none;
    border-radius: 0;
    overflow-y: hidden;
}

.CookiesModal {
    width: 505px;
    min-height: 275px;
    background-color: $white;
    position: fixed;
    border-radius: 30px;

    bottom: 16px;
    right: 100px;
    z-index: 10000;
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.15);

    &.-lng-FR {
        width: 540px;
    }

    &.-lng-IT {
        width: 540px;
    }

    &.-lng-ES {
        width: 560px;
    }

    .content {
        display: flex;
        flex-direction: column;
        padding: 32px;
        gap: 24px;

        .title {
            display: flex;
            align-items: center;
            gap: 7px;
        }

        .desc {
            span {
                text-decoration: underline;
                color: $element-brand-rest-primary;
                font-weight: bold;
                cursor: pointer;
            }
        }

        .actions {
            display: flex;
            gap: 16px;

            .Button {
                width: 100%;

                &.reject-all-cta:hover {
                    background-color: $white !important;
                    border-color: #2EB67D !important;
                    .label {
                        color: #2EB67D !important;
                    }
                }

                &.accept-all-cta:hover {
                    background-color: #1C754F;
                }
            }
        }
    }

    @media (max-width: 768px) {
        width: calc(100% - 32px) !important;
        right: 0;
        bottom: 16px;
        
        left: 50%;
        transform: translateX(-50%);

        .actions {
            flex-direction: column;
            gap: 16px;
        }

    }
}