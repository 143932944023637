@import '../../../../Style/theme.scss';

.ExperienceFlowStep {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 840px;

    img {
        max-width: 637px;

        @media screen and (max-width: 1490px) and (min-width: 1200px) {
            max-width: 540px;
        }
    }
    p {
        text-align: center;
    }

    .text-body-primary-font-bold {
        max-width: 80%;
        font-weight: 600;
        padding-bottom: 8px;
    }

    .text-body {
        max-width: 80%;
        color: $element-greys-secondary !important;
    }
}
