@import './../../../../Style/theme.scss';

.LifeAtSpreeAISectionRemote {
    background-color: white;
    padding: 80px 0;
    width: 100%;

    .wrapper {
        display: grid;
        grid-template-columns: 1.1fr 1fr;
        max-width: 1500px;
        margin-left: auto;
        margin-right: auto;

        .left {
            margin-right: auto;
            display: flex;
            flex-direction: column;
            justify-content: center;

            .title {
                margin-bottom: 52px;
                
                @media (max-width: 600px) {
                    line-height: normal !important;
                    margin-bottom: 30px;
                }
            }

            .deprecated-text-title-large {
                color: $element-greys-secondary;
                text-align: justify;

                @media (max-width: 600px) {
                    font-size: 16px !important;
                    line-height: normal !important;
                }
            }
        }

        .right {
            display: flex;
            justify-content: right;
        }

        @media (max-width: 1600px) {
            .right {
                img {
                    width: 450px;
                }
            }
        }

        @media (max-width: 1350px) {
            .right {
                img {
                    width: 400px;
                }
            }
        }

        @media (max-width: 1000px) {
            display: flex;
            flex-direction: column;

            .right {
                display: flex;
                justify-content: center;

                width: 100%;
                margin-top: 30px;
            }
        }

        @media (max-width: 600px) {
            .right {
                img {
                    width: 100%;
                }
            }
        }

        @media (max-width: 1600px) {
            max-width: 1200px;
        }

        @media (max-width: 1350px) {
            max-width: 915px;
        }

        @media (max-width: 1000px) {
            max-width: none;
            padding: 0 16px;
        }
    }
    @media (max-width: 1000px) {
        max-width: none;
    }
}
