@import '../../../../Style/theme.scss';
@import '../../../../Style/typographie.scss';

.Benefits {
    padding: 80px 17px;

    max-width: 979px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 32px;
    width: 100%;

    .WrapperSectionTitle {
        text-align: center;

        @media screen and (max-width: 650px) {
            text-align: left;
        }
    }    

    .benefits {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 32px;

        .benefit {
            display: flex;
            align-items: center;
            gap: 24px;
            
            .benefit-wrapper-icon {
                min-width: 80px;
                min-height: 80px;
                border-radius: 20px;
                display: flex;
                justify-content: center;
                align-items: center;
            }
    
            .benefit-image {
                width: 120px;
                height: 120px;
            }
    
            .benefit-description {
                color: $element-greys-secondary-alt;
            }
        }    
    }

    .cta {
        padding: 12px 20px;
    }

    ul,
    li {
        list-style-type: none;
        margin: 0;
        padding: 0;
    }

    &,
    & > * {
        box-sizing: border-box;
    }
    
    @media screen and (max-width: 950px) {
        & {
            padding: 80px 16px;
    
            .benefits {
                grid-template-columns: 1fr;
                padding: 0;
            }
        }
    }
}
