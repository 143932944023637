@import '../../../../Style/theme.scss';

.CategoryFilter {
    &,
    & > * {
        box-sizing: border-box;
    }
    position: absolute;
    top: calc(-35% - 85px);
    left: 50%;
    transform: translate(-50%);
    width: 100%;
    max-width: 100vw;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;

    .wrapper {
        display: inline-flex;
        padding: 16px 32px;
        gap: 24px;
        border-radius: 100px;
        width: auto;
        white-space: nowrap;
        background-color: $white;
    }

    @media screen and (min-width: 665px) {
        display: flex;
        justify-content: center;
    }

    @media screen and (max-width: 665px) {
        width: auto;
        margin: 0 16px;

        .wrapper {
            margin-right: 32px;
        }
    }

    @media screen and (max-width: 1000px) {
        top: -65px;
    }

    &::-webkit-scrollbar {
        display: none;
    }
    -ms-overflow-style: none;
    scrollbar-width: none;
}