.PartnerRessourcesPage {
    .cta-bottom {
        padding: 80px 16px;
        display: flex;
        flex-direction: column;
        gap: 32px;
        align-items: center;
        
        .label {
            text-align: center;
        }

        .Button {
            width: 264px;

            .label {
                color: white !important;
            }
        }

        @media screen and (max-width: 600px) {
            gap: 16px;
        }
    }
}