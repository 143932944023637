@import 'src/Style/theme.scss';
@import 'src/Style/breakpoint.scss';
@import 'src/Style/typographie.scss';

.CompanyLeadership {
    padding: 120px 16px;
    width: 100%;

    background-color: white;

    &,
    & > * {
        box-sizing: border-box;
    }

    .content {
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: 1488px;
        width: 100%;
        margin: 0 auto;

        @media screen and (max-width: 1000px) {
            display: block;
        }
    }

    .title {
        margin-bottom: 36px;
        text-align: center;

        span {
            display: inline-block;
            background: linear-gradient(90deg, #399da9, #34a797, #2fb481, #2eb67d);
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            font-family: 'poppins', sans-serif;
        }

        div {
            font-family: 'poppins', sans-serif;
        }

        @media screen and (max-width: 1200px) {
            font-weight: 600;
        }

        @media screen and (max-width: map-get($breakpoints, 'tablet')) {
            font-weight: 500;

            * {
                font-weight: 500;
            }
        }
    }

    .leaders-container {
        display: flex;
        flex-wrap: wrap;
        gap: 32px;
        justify-content: center;

        @media screen and (max-width: 1636px) {
            gap: 20px;
        }

        @media screen and (max-width: 1415px) {
            gap: 0;

            .CardLeader {
                scale: 0.9;
            }
        }

        @media screen and (max-width: 1300px) {
            gap: 12px;

            .CardLeader {
                scale: 1;
            }
        }

    }

    .ctas {
        margin: 64px 0 0;
        min-width: 0 !important;
        width: 270px !important;

        padding: 12px 64px;

        @media screen and (max-width: 1000px) {
            margin-top: calc(64px + 50px);
            
        }
    }

    .Carousel {
        max-width: 1060px;
        margin: 0 auto;

        @media screen and (max-width: 1000px) {
            .slick-slider {
                .slick-dots {
                    bottom: -52px;
                }
            }
            
            .CardLeader {
                margin-left: auto;
                margin-right: auto;
            }
        }
    }
    

    @media screen and (max-width: 815px) {
        padding: 80px 16px;
    }
}
