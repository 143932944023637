.AboutUsCoresValueSectionPage {
    padding: 80px 0;
    background-color: #f2f2f3;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 32px;

    .bottom {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        gap: 30px;
        justify-content: center;
        padding-left: 16px;
        padding-right: 16px;

        .one-row {
            display: flex;
            gap: 30px;
            flex-wrap: wrap;
            justify-content: center;

            .card-value-selected {
                transform: rotate(-3deg);
            }
        }

        @media (max-width: 550px) {
            display: flex;
            flex-direction: column;
            align-items: center;
        }
    }

    .container-button-learn-more {
        margin-left: auto;
        margin-right: auto;

        button {
            padding: 12px 64px;
        }
    }

    .top {
        margin-top: auto;
        margin-bottom: auto;
        margin-left: auto;
        margin-right: auto;
        text-align: center;

        @media (max-width: 600px) {
            margin-left: 16px;
            margin-right: 16px;
        }

        @media (max-width: 400px) {
            width: 350px;
        }

        @media (max-width: 360px) {
            width: 300px;
        }

        .WrapperSectionTitle {
            text-align: center;

            @media (max-width: 600px) {
                text-align: left;
            }
        }

        .text-body {
            text-align: center;
            max-width: 525px;
            margin-top: 16px;
            
            @media screen and (min-width: 900px) {
                margin-left: auto;
                margin-right: auto;
            }

            @media screen and (max-width: 900px) {
                bold {
                    font-weight: normal;
                }
                max-width: none;
                width: 100%;
            }

            @media screen and (max-width: 600px) {
                text-align: left;
                margin-top: 24px;
            }
        }
    }

    @media (max-width: 888px) {
        gap: 40px;
    }
}
