@import './../../Style/theme.scss';

.DashboardPage {
    .top {
        height: 360px;
        margin-top: 80px;
        position: relative;

        &.-manage_account {
            background: radial-gradient(86.39% 99.12% at 13.61% 3.61%, #121212 0%, #333333 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
            linear-gradient(0deg, #1B1D1E, #1B1D1E);
        }
        background: rgba(0, 0, 0, 0.5);
    
        .wrapper-video {
            position: absolute;
            top: 0;
            left: 0;
            z-index: -10;
            height: 100%;
            width: 100%;
            background: rgba(0, 0, 0, 0.5);

            .WrapperVideo {
                height: 100%;
                width: 100%;
                video {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
    
                    -o-filter: blur(15px);
                    filter: blur(15px);
                }
    
            }
        }

        .wrapper-user-info-top {
            display: flex;
            justify-content: space-between;
            margin-left: auto;
            margin-right: auto;
            height: 100%;

            max-width: 1100px;

            .left {
                display: flex;
                flex-direction: column;
                gap: 10px;
                justify-content: center;
                padding-bottom: 100px;

                .deprecated-text-first-name {
                    overflow: hidden;
                }
            }

            .right {
                display: flex;

                gap: 16px;
                align-items: center;
                justify-content: center;
                padding-bottom: 100px;

                 * {
                    cursor: pointer;
                 }
            }

            &.-manage_account {
                display: flex;
                flex-direction: column;
                max-width: 1200px;

                .wrapper-user-info-banner {
                    display: flex;
                    justify-content: space-between;
                    margin-top: auto;
                    margin-bottom: auto;

                    .left {
                        display: flex;
                        flex-direction: column;
                        gap: 10px;
                        justify-content: center;
                        padding-bottom: 10px;
                    }
        
                    .right {
                        display: flex;
        
                        gap: 16px;
                        align-items: center;
                        justify-content: center;
                        padding-bottom: 10px;
        
                         * {
                            cursor: pointer;
                         }
                    }
        
                }
                .wrapper-bottom-info {
                    margin-left: 450px;
                    margin-bottom: 39px;

                    .wrapper-back {
                        display: flex;
                        align-items: center;
                        gap: 10px;
                        margin-bottom: 10px;
                        cursor: pointer;
                    }

                    @media (max-width: 999px) {
                        display: none;
                    }
                }
            }
            
            @media (max-width: 1130px) {
                max-width: 900px;
                
            }
            @media (max-width: 870px) {
                max-width: 800px;
                padding: 0 24px;
                flex-direction: column;
                justify-content: center;

                .left {
                    padding-bottom: 30px;
                    .text-first-name {
                        text-align: center;
                        font-size: 32px;
                    }
                }

                .right {
                    justify-content: center;

                    .DropdownUser {
                        padding: 10px 10px 10px 10px;
                    }
                }
            }
            @media (max-width: 550px) {
                padding: 0 24px;
                flex-direction: column;
                justify-content: center;

                .left {
                    padding-bottom: 30px;
                    .text-first-name {
                        text-align: center;
                        font-size: 32px;
                    }
                }

                .right {
                    justify-content: center;

                    .DropdownUser {
                        padding: 10px 10px 10px 10px;
                    }
                }
            }
        }
    }

    .bottom {
        max-width: 1100px;
        margin-left: auto;
        margin-right: auto;
        margin-top: -125px;
        padding-bottom: 60px;

        .wrapper-bottom-info {

            .wrapper-back {
                display: flex;
                align-items: center;
                gap: 10px;
                margin-bottom: 10px;
                cursor: pointer;
            }

            @media (min-width: 999px) {
                display: none;
            }
        }

        .cards {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            gap: 54px;

            .content-manage-account-card {
                display: flex;
                flex-direction: column;

                * {
                    text-align: center;
                }

                .deprecated-text-body-large-bold {
                    margin-bottom: 8px;
                }
                .deprecated-text-body-large {
                    margin-bottom: 8px;
                }
                .deprecated-text-title-medium {
                    margin-bottom: 32px;
                }
                .deprecated-text-headline-medium {
                    margin-bottom: 32px;
                }
                .user-name {
                    color: #2EB67D;
                }
                .user-mail {
                    color: #606060;
                    &.-manage_account {
                        margin-bottom: 0 !important;
                    }
                }

                .delete-account {
                    color: $surface-error-rest-primary;
                    cursor: pointer;
                }

                .Button {
                    width: 100% !important;
                    margin-left: auto;
                    margin-top: auto;

                    border-color: #00000033;
                    .deprecated-text-title-medium-bold {
                        font-weight: normal !important;
                    }
                }

                .separator {
                    margin-top: 32px;
                    margin-bottom: 32px;
                    height: 1px;
                    width: 100%;
                    background-color: #00000033;
                }

                .wrapper-jira-ticket-icon {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: 16px;

                    margin-top: auto;
                }
            }

            .card-account {
                &.-manage_account {
                    min-height: auto !important;
                }
            }

            &.-manage_account {
                justify-content: flex-start;

                @media (max-width: 999px) {
                    justify-content: center;
                }
            }
        }

        @media (min-width: 1350px) {
            max-width: 1100px;

            .cards {
                justify-content: space-between;

                .CardDashboard {
                    width: 280px;

                    .inner {
                        padding: 25px;
                    }

                    .deprecated-text-headline-small {
                        font-size: 22px;
                    }
                    .deprecated-text-title-medium {
                        font-size: 14px;
                    }

                    .wrapper-icon {
                        padding: 15px;
                    }
                }
            }
        }

        @media (max-width: 1350px) and (min-width: 1000px){
            max-width: 1100px;

            .cards {
                justify-content: space-between;

                .CardDashboard {
                    width: 230px;

                    .inner {
                        padding: 25px;
                    }

                    .deprecated-text-headline-small {
                        font-size: 22px;
                    }
                    .deprecated-text-title-medium {
                        font-size: 14px;
                    }

                    .wrapper-icon {
                        padding: 15px;
                    }
                }
            }
        }

        @media (max-width: 1130px) {
            max-width: 900px;
            
            .cards {
                gap: 5px !important
            }

            .CardDashboard {
                min-width: 250px;
            }
        }
        @media (max-width: 950px) {
            max-width: 800px;
            
            .cards {
                gap: 54px !important
            }

            .CardDashboard {
                min-width: 250px;
            }
        }
        @media (max-width: 870px) {
            max-width: none;
            display: flex;
            flex-direction: column;
            padding: 60px 24px;
        }


    .account-form {
        .Form {
            display: inline;
            .form-content {
                width: 70%;
                padding: 0;
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                margin-left: 450px;
                margin-top: -175px;
                margin-right: 120px;

                @media screen and (max-width: 890px) {
                    display: flex;
                    flex-direction: column;
        
                    .manage-account-content-left {
                        display: flex;
                        justify-content: center;
                        padding-bottom: 100px;
                    }    
                }
                
                .input-change-password-container {
                    display: flex;
                    flex-direction: column;

                    .input-change-password-bottom {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        margin-top: 20px;
                        padding-bottom: 15px;
                        .right {
                            color: $surface-brand-rest-primary;
                        }
                    }
                }

                .update-container {
                    margin-left: auto;
                    grid-column: 2;
                    margin-top: 25px;
                }

                @media (max-width: 1600px) {
                    width: 65%;
                }
                @media (max-width: 1350px) {
                    display: flex;
                    width: 60%;
                }

                @media (max-width: 1160px) {
                    display: flex;
                    width: 50%;
                }

                @media (max-width: 1080px) {
                    width: 48%;
                }
                @media (max-width: 999px) {
                    width: 100%;
                    padding: 0;
                    display: grid;
                    grid-template-columns: repeat(2, 1fr);
                    margin-left: 0;
                    margin-top: 0;
                    margin-right: 0;
                }

                @media (max-width: 755px) {
                    width: 100%;
                    padding: 0;
                    display: flex;
                    margin-left: 0;
                    margin-top: 0;
                    margin-right: 0;
                }

            }
        }
    }

    .change-password-form {
        .Form {
            display: inline;
            .form-content {
                width: 70%;
                padding: 0;
                display: flex;
                margin-left: 450px;
                margin-top: -175px;
                margin-right: 120px;

                .separator {
                    height: 1px;
                    background-color: $surface-grey-disabled;
        
                    margin-top: 10px;
                    margin-bottom: 10px;
                }

                @media screen and (max-width: 890px) {        
                    .manage-account-content-left {
                        display: flex;
                        justify-content: center;
                        padding-bottom: 100px;
                    }    
                }
                
                @media (max-width: 1600px) {
                    width: 65%;
                }
                @media (max-width: 1350px) {
                    display: flex;
                    width: 60%;
                }

                @media (max-width: 1160px) {
                    display: flex;
                    width: 50%;
                }

                @media (max-width: 1080px) {
                    width: 48%;
                }
                @media (max-width: 999px) {
                    width: 100%;
                    padding: 0;
                    margin-left: 0;
                    margin-top: 0;
                    margin-right: 0;
                }

                @media (max-width: 755px) {
                    width: 100%;
                    padding: 0;
                    display: flex;
                    margin-left: 0;
                    margin-top: 0;
                    margin-right: 0;
                }

            }
        }
    }

    @media (max-width: 999px) {
        margin-top: -70px;
    }    

    @media (max-width: 800px) {
        margin-top: -140px;
    }    

    @media (max-width: 500px) {
        margin-top: -100px;
    }    

}
}