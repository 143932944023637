@import '../../../../Style/typographie.scss';
@import '../../../../Style/theme.scss';

.SharingAndFeedback {
    width: 100%;
    padding: 80px;

    &,
    & > * {
        box-sizing: border-box;
    }

    width: 100%;
    max-width: 1040px;
    display: flex;
    gap: 42px;
    margin: 0 auto;

    .card {
        width: 50%;
        display: flex;
        flex-direction: column;

        .image-container {
            width: 100%;
            border-radius: 30px;
        }

        .image {
            width: 100%;
            margin-bottom: 24px;
        }
    
        .title {
            margin-bottom: 10px;
        }
    }

    @media screen and (max-width: 750px) {
        padding: 40px 16px 80px;
    
        .card {
            width: 100%;
        }
    
        flex-direction: column;
        width: 100%;
        max-width: calc(363px + 32px);
        align-items: center;
        gap: 24px;
    }
}

