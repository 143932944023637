@import './../../Style/theme.scss';

.lib-modal-container {
    padding: 0 !important;
    width: 100%;
    height: 100%;
    margin: 0;
    border-radius: 10px;
    max-width: none;
    border-radius: 0;
    padding: 0px !important;
    overflow-y: hidden;
}

.OnBoardingModal {
    display: flex;
    flex-direction: column;
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    padding-top: 25px;

    .video-container {
        position: relative;
        width: 95%;
        aspect-ratio: 16 / 9;
        max-width: 1200px;
        margin: 0 auto;
        max-height: 80vh;

        iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border-radius: 20px;
            border: 2px solid white;
            box-shadow: 0 0 20px rgba(255, 255, 255, 0.5);
        }
    }

    Button {
        margin-top: 22px;
        min-width: 0 !important;
        padding-left: 76px !important;
        max-height: 48px !important;

        @media (max-width: 768px) {
            background-color: $white !important;

            .label {
                color: $surface-grey-opposite !important;
            }

            .button-icon {
                svg {
                    path {
                        fill: $surface-grey-opposite;
                    }
                }
            }
        }
    }

    .close-icon {
        z-index: 10000;
        width: 95.2%;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        svg {
            cursor: pointer;
        }
    }
    
    .wrapper-close-icon {
        position: absolute;
        display: flex;
        top: -60px;
        width: 100%;
        height: 50px;
    }
}

.react-responsive-modal-modal {
    &.onboarding-modal {
        background: rgba(0, 0, 0, 0.7); // darkish and transparent
        box-shadow: none;
        position: relative;
    }
}

.react-responsive-modal-modal {
    &.cookies-detail-modal {
        background: rgba(0, 0, 0, 0.7); // darkish and transparent
        box-shadow: none;
        position: relative;
    }
}