@import './../../../Style/theme.scss';

.PasswordSuccess {
    background: linear-gradient(black 50%, white 50%);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 85px 0;

    .WhiteCard {
        box-shadow: 0px 25px 40px -10px rgba(28, 39, 49, 0.08);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        row-gap: 30px;
        max-width: 750px;
        text-align: center;
        padding: 40px 80px;

        .line {
            background-color: $surface-grey-disabled;
            margin: 30px 0;
            height: 1px;
            width: 100%;
        }
        .Button {
            width: 250px;
        }
    }

    @media screen and (max-width: 950px) {
        padding: 85px 20px;
        .WhiteCard {
            width: 100%;
            padding: 40px 20px;
            height: 400px;

            h1 {
                font-size: 24px;
            }

            .line {
                display: none;
            }

            svg {
                min-width: 55px;
                min-height: 55px;
            }
        }
    }
}
