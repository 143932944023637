.AnnoucementSectionMobile {
    padding: 0 16px;
    margin-bottom: 80px;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    position: relative;
    height: 600px; /* Adjust this value based on your needs */
    overflow: hidden;

    .motion-div {
        position: absolute;
        width: calc(100% - 32px);
        height: 100%;
        display: flex;
        flex-direction: column;
        border-radius: 30px;
        overflow: hidden;
    }

    .top {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        width: 100%;
        border-radius: 30px 30px 0px 0px;
        height: 415px;
        overflow: hidden;

        .img-current {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 30px 30px 0px 0px;
            position: absolute;
            z-index: -1;
            opacity: 0;
            transition: opacity 1s ease-in-out;
        
            &.-active {
                z-index: 1;
                opacity: 1;
            }
        
            &.-last {
                z-index: 0;
                opacity: 1;
            }
        
            &.-hidden {
                z-index: -1;
                opacity: 0;
            }
        
            @keyframes fadeIn {
              0% {
                opacity: 0;
              }
              100% {
                opacity: 1;
              }
            }
        
            @keyframes fadeOut {
              0% {
                opacity: 1;
              }
              100% {
                opacity: 0;
              }
            }
    
            .wrapper-video-annoucement {
                height: 100%;

                .play-btn {
                    position: absolute;
                    top: 40px;
                    left: 40px;
                    z-index: 99;
                    cursor: pointer;
                    border: 3px solid white;
                    border-radius: 40px;
                    width: 50px;
                    height: 50px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }

            video {
                width: 100%;
                height: 100%;
                object-fit: cover;
                border-radius: 30px 30px 0px 0px;
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                border-radius: 30px 30px 0px 0px;
            }
        }

        .WrapperSectionTitle {
            position: absolute;
            bottom: 26px;
            left: 16px;
            z-index: 2;
        }

        .overlay {
            position: absolute;
            bottom: 0;
            left: 0;
            z-index: 1;
            width: 100%;
            height: 100%;
            background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.87) 100%);
        }
    }

    .learn-more {
        text-decoration: underline;
        color: white !important;
        text-align: left;
    }

    .bottom {
        padding: 24px;
        background: linear-gradient(268deg, #000 -78.49%, rgba(0, 0, 0, 0.99) 23.85%, rgba(0, 0, 0, 0.96) 44.74%, #000000f0 51.57%, rgba(0, 0, 0, 0.89) 66.12%, rgba(0, 0, 0, 0.80) 85.14%, rgba(0, 0, 0, 0.67) 94.98%, rgba(0, 0, 0, 0.50) 124.23%);
        background-color: rgb(0, 0, 0);

        border-radius: 0px 0px 30px 30px;
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        z-index: 10;
        margin-top: -1px;

        .dots {
            display: flex;
            gap: 8px;
            align-items: center;
            margin-top: auto;

            .dot {
                width: 16px;
                height: 10px;
                background: rgba(255, 255, 255, 0.25);
                border-radius: 14px;
                transition: all 0.7s ease;

                &.-active {
                    background: rgba(255, 255, 255, 0.50);
                    width: 30px;
                }
            }
        }

        .redirectionUrl {
            text-decoration: underline;
            margin-top: 16px;
        }
    }

    .cta-container {
        position: absolute;
        background-color: rgba(0, 0, 0, 0.50);
        border-radius: 5px;
        backdrop-filter: blur(15px);
        width: 32px;
        height: 32px;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 88;
        top: 50%;
        transform: translateY(-50%);
        
        &.-left {
            left: 16px;
            background-color: rgba(0, 0, 0, 0.25);
        }

        &.-right {
            right: 16px;
        }
    }

    @media screen and (max-width: 850px) {
        max-width: calc(361px + 32px);
    }  

    @media screen and (max-width: 500px) {
        max-width: calc(100% - 32px);
    }  
}