@import './../../../../Style/breakpoint.scss';

.AnnouncementSection {
  padding: 80px 64px 40px;

  .cta-container {
    position: absolute;
    width: 75px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
    right: 180px;
    background-color: rgba(51, 51, 51, 1);
    border-radius: 5px;
    cursor: pointer;

    &.-top {
      background-color: rgba(51, 51, 51, 1);
      top: 16px;
    }
    &.-bottom {
      bottom: 16px;
    }
  }

  .asset-left {
    position: absolute;
    z-index: -1;

    &.-active {
      z-index: 0;
      animation: fadeIn 1s ease-in-out;
    }

    &.-last {
      z-index: 0;
      animation: fadeOut 1s ease-in-out;
    }

    &.-need-to-go {
      z-index: -1 !important;
    }

    @keyframes fadeIn {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }

    @keyframes fadeOut {
      0% {
        opacity: 1;
      }
      100% {
        opacity: 0;
      }
    }
  }

  .wrapper-video-annoucement-left {
    position: relative;

    video {
        height: 527px;
        width: calc(100% + 120px);
        object-fit: cover;

        border-top-left-radius: 30px;
        border-bottom-left-radius: 30px;    
    }

    .play-btn {          
      position: absolute;
      top: 40px;
      left: 40px;
      z-index: 1;
      cursor: pointer;

      border: 3px solid white;
      border-radius: 40px;

      width: 50px;
      height: 50px;

      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .box {
    height: 527px;
    max-width: calc(1280px - 128px);
    margin-left: auto;
    margin-right: auto;
    display: flex;
    position: relative;
    background-color: #000000;
    border-radius: 30px;

    .left {
      border-top-left-radius: 30px;
      border-bottom-left-radius: 30px;
      width: 884px !important;
      position: relative;

      .img-current {
        img {
          height: 527px;
          width: calc(100% + 120px);

          border-top-left-radius: 30px;
          border-bottom-left-radius: 30px;

          object-fit: cover;
        }

        .wrapper-video-annoucement {
          position: relative;
          height: 117px;
          width: 117px;

          video {
              height: 527px;
              width: calc(100% + 120px);
              object-fit: cover;

              border-top-left-radius: 30px;
              border-bottom-left-radius: 30px;    
          }

          .play-btn {          
            position: absolute;
            top: 40px;
            left: 40px;
            z-index: 1;
            cursor: pointer;

            border: 3px solid white;
            border-radius: 40px;

            width: 50px;
            height: 50px;

            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
      }

      .WrapperSectionTitle {
        position: absolute;
        bottom: 40px;
        left: 40px;
        z-index: 10;
      }
    }

    .overlay {
      border-radius: 0px 0px 30px 30px;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.87) 100%);        position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 40%;
    }

    .right {
      width: 660px;
      max-height: 527px;
      overflow: hidden;
      border-top-right-radius: 30px;
      border-bottom-right-radius: 30px;
      position: relative;
      margin-left: -10px;
      background: linear-gradient(270deg, #000 0%, rgba(0, 0, 0, 0.99) 72.22%, rgba(0, 0, 0, 0.96) 74.35%, rgba(0, 0, 0, 0.94) 76.2%, rgba(0, 0, 0, 0.89) 77.59%, rgba(0, 0, 0, 0.80) 80.71%, rgba(0, 0, 0, 0.67) 85.57%, rgba(0, 0, 0, 0.50) 100%);

      .overlay {
        background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.87) 100%);
        position: absolute;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 10%;

        &.-top {
          top: 0;
          bottom: auto;
        }

        &.-bottom {
          top: auto;
          bottom: 0;
        }
      }
      

      &::-webkit-scrollbar {
        display: none;
      }

      .item {
        background: linear-gradient(270deg, #000 0%, rgba(0, 0, 0, 0.99) 72.22%, rgba(0, 0, 0, 0.96) 74.35%, rgba(0, 0, 0, 0.94) 76.2%, rgba(0, 0, 0, 0.89) 77.59%, rgba(0, 0, 0, 0.80) 80.71%, rgba(0, 0, 0, 0.67) 85.57%, rgba(0, 0, 0, 0.50) 100%);            padding: 16px 24px;
        margin-right: -10px;
        display: flex;
        align-items: center;
        cursor: pointer;
        transition: all .7s ease;

        .inner {
          padding-left: 24px;
          max-width: 330px;

          .title {     
            max-height: 80px;
            max-width: 90%;  
            display: -webkit-box;   
            -webkit-line-clamp: 4;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            
            .announcement-title {
              transition: all 2s ease;
            }
          }
        }

        .img {
          width: 117px;
          height: 117px;
          object-fit: cover;

          border-radius: 8px;
        }

        video {
          width: 117px;
          height: 117px;
          border-radius: 8px;
        }

        .wrapper-video-annoucement {
          position: relative;
          width: 117px;
          height: 117px;

          video {
              width: 117px;
              height: 117px;
              object-fit: cover;
          }
        }

        .learn-more {
          text-decoration: underline;
          color: white;
        }

        .wrapper-media {
          border: 2px solid transparent;
          border-radius: 10px;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 117px;
          height: 117px;
  
        }

        &.-active {
          background-color: #3C3C3C80 !important;
          background-image: none !important;
          opacity: 1 !important;

          .wrapper-media {
            border: 2px solid white;
            border-radius: 10px;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }

        &:not(.-active) {
          opacity: 0.5;
        }

      }
    }

    @media screen and (max-width: 850px) {
        display: none;
    }  
  }
}
