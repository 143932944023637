@import './../../../../Style/theme.scss';

.BasicInput {
    display: flex;
    flex-direction: column;

    label {
        padding-bottom: 4px;
        font-size: 12px !important;
    }

    input {
        border: 1px solid $surface-grey-disabled;
        border-radius: 4px;
        padding: 13px 8px;

        &:focus-visible {
            outline: 1px solid $surface-brand-rest-secondary;
        }
    }
}
