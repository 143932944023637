@import './../../Style/theme.scss';

.Forbidden401 {
    height: calc(100vh - $header-height);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    h1 {
        font-size: 12.5em;
    }
    background-color: $surface-grey-opposite;

    .card {
        max-width: 723px;
        background-color: $white;
        display: flex;
        flex-direction: column;
        align-items: center;
        row-gap: 40px;
        padding: 40px 80px;
        text-align: center;
    }

    @media screen and (max-width: 1000px) {
        .card {
            padding: 40px;
            margin: 0 20px;
        }
    }

    @media screen and (max-width: 810px) {
        height: auto;
        padding-bottom: 40px;
        h1 {
            font-size: 6em;
        }
    }
}
