.InfiniteSliderHorizontal {
    width: 100%;
    overflow: hidden;
    user-select: none;

    img {
        height: 170px;
    }

    .wrapper {
        display: flex;
        cursor: grab;
        overflow-x: scroll;
        scrollbar-width: none;
        -ms-overflow-style: none;

        &::-webkit-scrollbar {
            display: none;
        }

        &:active {
            cursor: grabbing;
        }
    }

    .content {
        display: flex;
        justify-content: space-around;
        min-width: max-content;

        .PetCard {
            padding-right: 64px;
        }
    }
}
