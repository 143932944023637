@import 'src/Style/theme.scss';

.ProgressBar {
    width: 100%;
    height: 10px;
    background-color: $surface-grey-disabled;
    position: relative;
    border-radius: 5px;
    overflow: hidden;

    .filled {
        height: 100%;
        background: $surface-brand-rest-primary;
    }

    .unfilled {
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        border-radius: 5px;
    }
}
