@import './../../Style/theme.scss';

.Login {
    background: linear-gradient(black 50%, white 50%);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 85px 0;
    margin-top: 80px;

    .box {
        height: 700px;
        border-radius: 20px;
        background: white;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        box-shadow: 0px 25px 40px -10px rgba(28, 39, 49, 0.08);
        padding: 0 80px;

        @media screen and (max-width: 1490px) and (min-width: 1200px) {
            height: 550px;
        }

    }

    .WhiteCard {
        box-shadow: 0px 25px 40px -10px rgba(28, 39, 49, 0.08);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 600px;
        height: 600px;

        .back {
            display: flex;
            align-items: center;
            align-self: flex-start;
            margin-bottom: auto;
        }

        .forgot-content {
            max-width: 480px;
            width: 100%;
            margin-bottom: 180px;
            text-align: center;

            .form-container {
                margin-bottom: 40px;
                .form-content {
                    padding: 0;
                    label {
                        text-align: left;
                    }
                }
                button {
                    max-width: 250px;
                    margin-left: auto;
                    margin-right: auto;
                }
                .account-password-incorrect {
                    color: $surface-error-rest-primary;
                    text-align: center;
                }
        
            }
            .have-account {
                text-decoration: none;
                color: $surface-brand-rest-primary;
                word-break: normal;
            }
        }
    }

    .login-box {
        border-radius: 20px 0 0 20px;

        .account-password-incorrect {
            color: $surface-error-rest-primary;
            text-align: center;
        }

        .title {
            margin-top: 175px;
            font-size: 24px !important;
            font-weight: 600 !important;
            color: $black !important;

            @media screen and (max-width: 1490px) and (min-width: 1200px) {
                margin-top: 85px;
            }

        }
        .form-container {
            width: 480px;
            .form-content {
                padding: 0;
            }
        }

        .forgot-pass {
            text-decoration: none;
            color: $surface-brand-rest-primary;
            text-align: center;
            margin-bottom: auto;
            margin-top: 20px;
        }

        .no-account {
            text-decoration: none;
            color: $element-greys-tertiary;
            text-align: center;
            margin-bottom: 40px;
        }
    }

    .welcome-box {
        border-radius: 0 20px 20px 0;
        max-width: 480px;
        background: $surface-brand-rest-primary;
        align-items: flex-start;
        background-size: cover;
        background-repeat: no-repeat;

        .logo {
            max-width: 51px;
        }

        .title {
            color: $white !important;
            padding: 40px 0;
        }

        .info-container {
            display: flex;
            flex-direction: column;
            row-gap: 20px;
            svg {
                flex: 0 0 auto;
            }

            .info {
                .text-body-small {
                    font-size: 12px !important;
                }
            }
        }
        .info {
            display: flex;
            column-gap: 10px;
            * {
                color: $white;
            }
        }
    }

    @media screen and (max-width: 1330px) {
        padding: 85px 20px;
        .box {
            flex: 1 1 50%;
            padding: 0 20px;
        }

        .login-box {
            .form-container {
                width: 100%;
            }
        }

        .welcome-box {
            max-width: none;
            padding: 0 30px;
        }
    }

    @media screen and (max-width: 850px) {
        flex-direction: column;
        padding: 30px 40px;
        row-gap: 25px;

        .login-box,
        .welcome-box,
        .WhiteCard {
            border-radius: 10px;
            width: 100%;
        }

        .WhiteCard {
            height: 436px;
            .forgot-content {
                margin-bottom: 60px;
            }
        }

        .login-box {
            .title {
                margin-top: 40px;
            }
            .no-account {
                margin-top: 40px;
                word-break: normal;
            }
        }

        .welcome-box {
            padding: 40px 20px;
            .logo,
            .title {
                display: none;
            }
        }
    }
}
