@import '../../../../../Style/theme.scss';

.MemberOfTeam {
    display: flex;
    flex-direction: column;
    width: 221px;
    height: 319px;

    perspective: 1000px;
    background-color: transparent;
    cursor: pointer;

    .card-container {
        width: 100%;
        height: 100%;
        position: absolute;
        transition: all 1.1s;
        transform-style: preserve-3d;

        &.-selected {
            transform: rotateY(180deg);
        }

        .side {
            height: 100%;
            width: 100%;
            position: absolute;
            backface-visibility: hidden;

            img {
                width: 100%;
                border-radius: 30px;
            }
            
            &.-front {
                z-index: 1;
            }

            &.-back {
                transform: rotateY(180deg)
            }
        }
    }

    .info {
        margin-top: 221px;
        padding-top: 16px;
    }
}
