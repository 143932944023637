@import 'src/Style/theme.scss';

.Step3 {
    .flex-container {
        display: flex;
        column-gap: 90px;
        align-items: center;

        .left-container {
            .WhiteCard {
                box-shadow: 0px 25px 60px -10px rgba(28, 39, 49, 0.12);
                max-height: 500px;
                max-width: 845px;
                overflow-y: scroll;
                overflow-x: hidden;
                position: relative;

                .checkmark {
                    display: flex;
                    justify-content: center;
                }

                .fade {
                    position: absolute;
                    display: flex;
                    justify-content: center;
                    align-items: flex-end;
                    width: 100%;
                    right: -5px;
                    height: 275px;
                    bottom: 0;
                    background: linear-gradient(
                        180deg,
                        rgba(255, 255, 255, 0) 0%,
                        rgba(255, 255, 255, 0.92) 66.44%,
                        #fff 100%
                    );

                    .scroll-down {
                        margin-bottom: 20px;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                    }

                    &.fade-down {
                        animation: fadeDown 0.5s forwards;
                    }

                    @keyframes fadeDown {
                        0% {
                            transform: translateY(0);
                            opacity: 1;
                        }
                        100% {
                            transform: translateY(100%);
                            opacity: 0;
                        }
                    }
                }

                .LayoutPolicy {
                    .terms-content {
                        width: 100%;
                        margin-top: 0;
                    }
                }
            }
        }

        .right-container {
            min-width: 440px;

            .progress-container {
                .ProgressBar {
                    margin-top: 60px;
                    margin-bottom: 10px;
                }
            }

            .complete-container {
                opacity: 0;
                pointer-events: none;
                position: relative;
                &.complete {
                    opacity: 1;
                    pointer-events: all;
                }

                .line {
                    width: 100%;
                    height: 1px;
                    background: $surface-grey-disabled;
                    margin: 40px 0;
                }

                .Button {
                    width: 100%;
                    margin-top: 20px;
                    margin-bottom: 40px;
                }
            }

            .bottom-section {
                display: flex;
                justify-content: space-between;
                align-items: center;
            }
        }
    }
}
