.HowItWorksVideoSection {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    padding-bottom: 80px;

    .video-wrapper {
        border-radius: 50px;
        overflow: hidden;
        display: flex;
        margin-bottom: 16px;

        @media (max-width: 390px) {
            height: 452px;
            width: 329px;
            object-fit: fill;
        }

        video {
            width: 1367px;
            border-radius: 50px;
            margin-left: auto;
            margin-right: auto;

            @media (max-width: 1550px) {
                width: 80%;
            }

            @media (max-width: 700px) {
                height: 616px;
                width: 329px;
                object-fit: fill;
            }

            @media (max-width: 390px) {
                object-fit: cover;
            }

            @media (max-width: 350px) {
                width: calc(100% - 32px);
                height: auto;
            }
        }
    }

    .disclaimer {
        padding: 0 16px;
        text-align: center;
    }

    .Button {
        margin-top: 32px;
        @media (max-width: 700px) {
            margin-top: 24px;
        }
    }
}

