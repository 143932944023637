@import './../../../../../Style/theme.scss';

.CardValue {
    display: flex;
    flex-direction: column;
    gap: 20px;

    border-radius: 30px;
    background-color: $white;
    box-shadow: 0 25px 60px -10px #1c27311f;

    padding: 30px;
    width: 300px;
    max-height: 500px;

    transition: all 0.2s ease-in-out;

    @media (max-width: 550px) {
        width: 100%;
    }

    .description {
        color: #777777;
    }

    &.card-value-selected {
        background-color: #2db67d;

        * {
            color: $white;
        }
    }
}
