.d-flex {
    display: flex;
}

.d-flex-col {
    display: flex;
    flex-direction: column;
}

.d-flex-align {
    display: flex;
    align-items: center;
}

.d-flex-justify {
    display: flex;
    justify-content: center;
}

.d-flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.capitalize {
    text-transform: capitalize;
}

.uppercase {
    text-transform: uppercase;
}

.lowercase {
    text-transform: lowercase;
}

.d-none {
    display: none;
}

.sortable-object {
    z-index: 2000;
}

.t-align-center {
    text-align: center;
}

.cursor-pointer {
    &:hover {
        cursor: pointer;
    }
}

//paddings
.pl-40 {
    padding-left: 40px;
}

.pt-40 {
    padding-top: 40px;
}

//margins
.ml-auto {
    margin-left: auto;
}

.ml-40 {
    margin-left: 40px;
}

.mr-auto {
    margin-right: auto;
}

.mt-5 {
    margin-top: 5px;
}

.mb-5 {
    margin-bottom: 5px;
}

.mt-10 {
    margin-top: 10px;
}

.mb-10 {
    margin-bottom: 10px;
}

.mt-15 {
    margin-top: 15px;
}

.mb-15 {
    margin-bottom: 15px;
}

.mt-20 {
    margin-top: 20px;
}

.mb-20 {
    margin-bottom: 20px;
}

.mt-30 {
    margin-top: 30px;
}

.mb-30 {
    margin-bottom: 30px;
}

.mt-40 {
    margin-top: 40px;
}

.mb-40 {
    margin-bottom: 40px;
}

.ml-5 {
    margin-left: 5px;
}

.mr-5 {
    margin-right: 5px;
}

.mt-50 {
    margin-top: 50px;
}

.mb-50 {
    margin-bottom: 50px;
}

.mt-60 {
    margin-top: 60px;
}

.mb-60 {
    margin-bottom: 60px;
}

.gradient-text {
    background: linear-gradient(
        to right,
        $linear-gradient1,
        $linear-gradient2,
        $linear-gradient3,
        $linear-gradient4
    ) !important;
    -webkit-background-clip: text !important;
    background-clip: compatibility !important;
    color: transparent !important;
}

// rotate

.-rotate-180 {
    transition: transform 0.3s ease-in-out;
    transform: rotate(180deg);
}

.-rotate-45 {
    transition: transform 0.3s ease-in-out;
    transform: rotate(45deg);
}

.-rotate-0 {
    transition: transform 0.3s ease-in-out;
    transform: rotate(0deg);
}

.btn-delete-default-style {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
}