@import './../../../../Style/theme.scss';

.LifeAtSpreeAISectionInclusionDiversity {
    background-color: $white;
    padding: 80px 16px;
    display: flex;
    justify-content: center;

    .wrapper {
        display: flex;
        flex-direction: column;
        margin-top: auto;
        margin-bottom: auto;

        .top {
            display: flex;
            flex-direction: column;
            gap: 42px;
            width: 800px;

            margin-left: auto;
            margin-right: auto;

            * {
                text-align: center;
            }

            @media (max-width: 900px) {
                width: 100%;
            }

            @media (max-width: 700px) {
                width: auto;

                .deprecated-text-display-large {
                    font-size: 24px !important;
                    line-height: normal !important;
                    text-align: left;
                }

                .text-body-large {
                    font-size: 16px !important;
                    line-height: normal !important;

                    margin-bottom: 15px;
                    text-align: left;
                }
            }
        }

        .illustrations {
            display: flex;
            width: 100%;
            justify-content: center;
            gap: 27px;

            margin-top: 32px;

            .illustration {
                img {
                    width: 286px;
                    height: 380px;
                }
            }
        }

        .carousel-container {
            margin-top: 20px;
            display: flex;
            justify-content: center;

            .Carousel {
                width: 500px;
                img {
                    width: 450px !important;
                }

                @media (max-width: 515px) {
                    width: 350px !important;
                    margin-left: 10px;
                    img {
                        width: 340px !important;
                    }
                }

                @media (max-width: 368px) {
                    width: 300px !important;
                    margin-left: 20px;
                    img {
                        width: 280px !important;
                    }
                }
            }
        }
    }

    @media (max-width: 1600px) {
        max-width: 1200px;
    }

    @media (max-width: 1350px) {
        max-width: 1000px;
    }

    @media (max-width: 1100px) {
        max-width: none;

        max-height: none !important;
        min-height:0 !important;
    }

    @media (max-width: 600px){
        .wrapper {
            .top {
                * {
                    text-align: left;
                    padding: 0 16px;
                }
            }
        }
    }
}
