.VideoMember {
    width: auto;
    height: auto;
    margin-bottom: -5px;
    position: relative;

    .country {
        font-size: 26px;
        font-weight: 500;
        line-height: 38px;
        letter-spacing:0;
        text-align: left;

        position: absolute;
        bottom: 20px;
        left: 30px;

        text-shadow:0 4px 4px rgba(0, 0, 0, 0.25);

        @media screen and (max-width: 700px) {
            font-size: 20px;
            bottom: 10px;   
            left: 10px;    
        }

        @media screen and (max-width: 425px) {
            font-size: 14px;
            bottom:0;   
            left: 5px;    
        }

    }

    video {
        transition: all 0.5s ease-in-out;
        z-index: 0;
        position: relative;

        &:hover {
            opacity: 0.85;
        }
    }
}