@import '../../../../../Style/theme.scss';
@import '../../../../../Style/typographie.scss';

.LatestUpdates {
    background-color: #F9F9F9;
    padding: 80px 16px;

    &,
    & > * {
        box-sizing: border-box;
    }

    &__content {
        max-width: 1300px;
        margin: 0 auto;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .WrapperSectionTitle {
        // @include deprecated-display-large-alt-mixin;
        text-align: center;
    }

    .mobile-carousel-container {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;

        .WrapperSectionTitle {
            margin-bottom: 24px;
        }

        .Carousel {
            max-width: 844px;

            @media screen and (max-width: 900px) {
                max-width: 432px;
            }

            @media screen and (max-width: 450px) {
                max-width: 350px !important;
            }


            @media screen and (max-width: 900px) {
                .ArticleCard {
                    min-height: 0;
                    margin-bottom: 50px;
                    margin-left: auto;
                    margin-right: auto;
    
                    .wrapper-img {
                        height: 271px;
                        margin-left: auto;
    
                        img {
                            height: 271px;
                        }
                    }
                }
            }
    
            .slick-dots {
                bottom: -10px !important;
            }
        }

        .Button {
            margin-top: 30px;
        }
    }

    &__articles {
        margin: 66px 0;
        display: flex;
        gap: 21px;
        flex-wrap: wrap;
        gap: 20px;
        align-items: center;
        justify-content: center;


        @media screen and (max-width: 1310px) {
            .ArticleCard {
                width: 350px;
                height: 400px;
                min-height: 0px;

                .wrapper-img {
                        height: 230px;

                    img {
                        height: 230px;
                    }
                }
            }
        }

        @media screen and (max-width: 1125px) {
            .ArticleCard {
                width: 300px;
                height: 400px;
                min-height: 0px;

                .wrapper-img {
                        height: 190px;

                    img {
                        height: 190px;
                    }
                }
            }
        }

        @media screen and (max-width: 1000px) {
            .ArticleCard {
                width: 250px;
                height: auto;
                min-height: 0px;

                .wrapper-img {
                        height: 160px;

                    img {
                        height: 160px;
                    }
                }
            }
        }
    }

    @media screen and (max-width: 450px) {
        .ArticleCard {
            width: 98% !important;
            height: auto !important;
            min-height: 0px;

            .wrapper-img {
                height: 221px !important;

                img {
                    height: 221px !important;
                }
            }
        }
    }

}
