.DropdownUser {
    background-color: white;
    padding: 10px 30px 10px 20px;
    border-radius: 20px;
    position: relative;
    
    &.-open-true {
        border-radius: 20px 20px 0 0;
    }

    .panel {
        display: flex;
        gap: 8px;
        align-items: center;
        justify-content: center;

        .content {
            margin-right: 25px;
    
            .user-name {
                color: #2EB67D;
                display: flex;
                min-width: 150px;
            }
        }
    }

    .dropdown-content {
        height: 0px !important;
        overflow: hidden;
        position: absolute;
        background-color: white;
        width: 100%;
        left: 0;
        border-radius: 0 0 20px 20px;
        padding: 0 0;
        z-index: 10000;
        
        &.-open-true {
            box-shadow: 1px 1px 0 1px #0000001A;

            height: 205px !important;
            padding: 32px 0;
            overflow: visible;
        }

        .content {
            padding: 0 20px;
            .user-mail {
                color: #606060;
                padding-bottom: 32px;
            }
            .dropdown-item {
                color: #2B2B2B;
                font-weight: 500 !important;
                border-top: 1px solid #77777733;
                padding: 16px 0;
            }
        }
    }
}