// Définir les variables globales
$default-gap-card: 210px;
$default-size-card: 335px + $default-gap-card;

$gap-card: $default-gap-card;
$size-card: $default-size-card;

$gap-card-sm: $default-gap-card;
$size-card-sm: $default-size-card - 100px;

$size-card-ultra-sm: $default-size-card - 120px;

$size-card-micro-sm: $default-size-card - 180px;
$size-card-mobile-xl: $default-size-card - 220px;

// Styles par défaut
.ImpactAndInclusionsCardsSection {
    position: relative;
    overflow: clip;
    height: 1050px;
    width: 100%;
    background-color: #F9F9F9;

    .content {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: $default-gap-card;
        padding-top: calc(24rem + 40px);

        @media (max-width: 700px) {
            gap: $gap-card-sm;
            padding-top: calc(21rem + 40px);
        }
    }

    .container-ctas {
        position: absolute;
        bottom: 71px;
        width: 100%;

        .content-cta {
            display: flex;
            gap: 40px;
            flex-direction: row;
            justify-content: center;
            .cta {
                width: 40px;
                height: 40px;
                border-radius: 400px;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
    
                background-color: #767676;
                &.-disabled {
                    background-color: #3F3F3F40;
                }
            }
    
            @media (max-width: 1000px) {
                gap: 24px;
                
            }
        }

        @media (max-width: 1000px) {
            bottom: 150px;
        }

        @media (max-width: 7000px) {
            bottom: 80px;
        }
    }

        .CardSustainabillity {
            position: absolute;
    
            &.card-position-1 {
                transform: rotate(-30deg) translate(-($size-card * 2), -310px);
    
                &.-animation-right {
                    animation: 1.5s ease-out 0s 1 normal forwards running oneToTwo;
                }
    
                &.-animation-left {
                    animation: 1.5s ease-out 0s 1 normal forwards running oneToSix;
                }
            }
    
            &.card-position-2 {
                transform: rotate(-15deg) translate(-($size-card * 1), -120px);
    
                &.-animation-right {
                    animation: 1.5s ease-out 0s 1 normal forwards running twoToThree;
                }
    
                &.-animation-left {
                    animation: 1.5s ease-out 0s 1 normal forwards running twoToOne;
                }
            }
    
            &.card-position-3 {
                transform: rotate(0) translate(0, -50px);
    
                &.-animation-right {
                    animation: 1.5s ease-out 0s 1 normal forwards running threeToFour;
                }
    
                &.-animation-left {
                        animation: 1.5s ease-out 0s 1 normal forwards running threeToTwo;
                }
            }
    
            &.card-position-4 {
                transform: rotate(15deg) translate($size-card * 1, -120px);
    
                &.-animation-right {
                        animation: 1.5s ease-out 0s 1 normal forwards running fourToFive;
                }
    
                &.-animation-left {
                        animation: 1.5s ease-out 0s 1 normal forwards running fourToThree;
                }
            }
    
            &.card-position-5 {
                transform: rotate(30deg) translate($size-card * 2, -310px);
    
                &.-animation-right {
                        animation: 1.5s ease-out 0s 1 normal forwards running fiveToSix;
                }
    
                &.-animation-left {
                        animation: 1.5s ease-out 0s 1 normal forwards running fiveToFour;
                }
            }
    
            &.card-position-6 {
                transform: rotate(-45deg) translate(-($size-card * 3), -350px);
            
                &.-animation-right {
                    animation: 1.5s ease-out 0s 1 normal forwards running sixToOne;
                }
    
                &.-animation-left {
                    animation: 1.5s ease-out 0s 1 normal forwards running sixToFive;
                }
    
            }
    
            @keyframes oneToTwo {
                from {
                    transform: rotate(-30deg) translate(-($size-card * 2), -310px);
                }
            
                to {
                    transform: rotate(-15deg) translate(-($size-card * 1), -120px);
                }
            }
            
            @keyframes twoToOne {
                from {
                    transform: rotate(-15deg) translate(-($size-card * 1), -120px);
                }
            
                to {
                    transform: rotate(-30deg) translate(-($size-card * 2), -310px);
                }
            }
            
            @keyframes twoToThree {
                from {
                    transform: rotate(-15deg) translate(-($size-card * 1), -120px);
                }
            
                to {
                    transform: rotate(0) translate(0, -50px);
                }
            }
            
            @keyframes threeToTwo {
                from {
                    transform: rotate(0) translate(0, -50px);
                }
            
                to {
                    transform: rotate(-15deg) translate(-($size-card * 1), -120px);
                }
            }
            
            @keyframes threeToFour {
                from {
                    transform: rotate(0) translate(0, -50px);
                }
            
                to {
                    transform: rotate(15deg) translate($size-card * 1, -120px);
                }
            }
            
            @keyframes fourToThree {
                from {
                    transform: rotate(15deg) translate($size-card * 1, -120px);
                }
            
                to {
                    transform: rotate(0) translate(0, -50px);
                }
            }
            
            @keyframes fourToFive {
                from {
                    transform: rotate(15deg) translate($size-card * 1, -120px);
                }
            
                to {
                    transform: rotate(30deg) translate($size-card * 2, -310px);
                }
            }
            
            @keyframes fiveToFour {
                from {
                    transform: rotate(30deg) translate($size-card * 2, -310px);
                }
            
                to {
                    transform: rotate(15deg) translate($size-card * 1, -120px);
                }
            }
            
            @keyframes fiveToSix {
                from {
                    transform: rotate(30deg) translate($size-card * 2, -310px);
                }
            
                to {
                    transform: rotate(45deg) translate($size-card * 3, -390px);
                }
            }
            
            @keyframes sixToFive {
                from {
                    transform: rotate(45deg) translate($size-card * 3, -350px);
                }
            
                to {
                    transform: rotate(30deg) translate($size-card * 2, -310px);
                }
            }
            
            
            
            @keyframes sixToOne {
                from {
                    transform: rotate(-45deg) translate(-($size-card * 3), -350px);
                }
            
                to {
                    transform: rotate(-30deg) translate(-($size-card * 2), -310px);
                }
            }
            
            @keyframes oneToSix {
                from {
                    transform: rotate(-30deg) translate(-($size-card * 2), -310px);
                }
            
                to {
                    transform: rotate(-45deg) translate(-($size-card * 3), -350px);
                }
            }
        }

    @media (max-width: 1500px) {
        .CardSustainabillity {
            position: absolute;
    
            &.card-position-1 {
                transform: rotate(-30deg) translate(-($size-card-ultra-sm * 2), -280px);
    
                &.-animation-right {
                    animation: 1.5s ease-out 0s 1 normal forwards running oneToTwo;
                }
    
                &.-animation-left {
                    animation: 1.5s ease-out 0s 1 normal forwards running oneToSix;
                }
            }
    
            &.card-position-2 {
                transform: rotate(-15deg) translate(-($size-card-ultra-sm * 1), -120px);
    
                &.-animation-right {
                    animation: 1.5s ease-out 0s 1 normal forwards running twoToThree;
                }
    
                &.-animation-left {
                    animation: 1.5s ease-out 0s 1 normal forwards running twoToOne;
                }
            }
    
            &.card-position-3 {
                transform: rotate(0) translate(0, -70px);
    
                &.-animation-right {
                    animation: 1.5s ease-out 0s 1 normal forwards running threeToFour;
                }
    
                &.-animation-left {
                        animation: 1.5s ease-out 0s 1 normal forwards running threeToTwo;
                }
            }
    
            &.card-position-4 {
                transform: rotate(15deg) translate($size-card-ultra-sm * 1, -120px);
    
                &.-animation-right {
                        animation: 1.5s ease-out 0s 1 normal forwards running fourToFive;
                }
    
                &.-animation-left {
                        animation: 1.5s ease-out 0s 1 normal forwards running fourToThree;
                }
            }
    
            &.card-position-5 {
                transform: rotate(30deg) translate($size-card-ultra-sm * 2, -280px);
    
                &.-animation-right {
                        animation: 1.5s ease-out 0s 1 normal forwards running fiveToSix;
                }
    
                &.-animation-left {
                        animation: 1.5s ease-out 0s 1 normal forwards running fiveToFour;
                }
            }
    
            &.card-position-6 {
                transform: rotate(-45deg) translate(-($size-card-ultra-sm * 3), -220px);
            
                &.-animation-right {
                    animation: 1.5s ease-out 0s 1 normal forwards running sixToOne;
                }
    
                &.-animation-left {
                    animation: 1.5s ease-out 0s 1 normal forwards running sixToFive;
                }
    
            }
    
            @keyframes oneToTwo {
                from {
                    transform: rotate(-30deg) translate(-($size-card-ultra-sm * 2), -280px);
                }
            
                to {
                    transform: rotate(-15deg) translate(-($size-card-ultra-sm * 1), -120px);
                }
            }
            
            @keyframes twoToOne {
                from {
                    transform: rotate(-15deg) translate(-($size-card-ultra-sm * 1), -120px);
                }
            
                to {
                    transform: rotate(-30deg) translate(-($size-card-ultra-sm * 2), -280px);
                }
            }
            
            @keyframes twoToThree {
                from {
                    transform: rotate(-15deg) translate(-($size-card-ultra-sm * 1), -120px);
                }
            
                to {
                    transform: rotate(0) translate(0, -70px);
                }
            }
            
            @keyframes threeToTwo {
                from {
                    transform: rotate(0) translate(0, -70px);
                }
            
                to {
                    transform: rotate(-15deg) translate(-($size-card-ultra-sm * 1), -120px);
                }
            }
            
            @keyframes threeToFour {
                from {
                    transform: rotate(0) translate(0, -70px);
                }
            
                to {
                    transform: rotate(15deg) translate($size-card-ultra-sm * 1, -120px);
                }
            }
            
            @keyframes fourToThree {
                from {
                    transform: rotate(15deg) translate($size-card-ultra-sm * 1, -120px);
                }
            
                to {
                    transform: rotate(0) translate(0, -70px);
                }
            }
            
            @keyframes fourToFive {
                from {
                    transform: rotate(15deg) translate($size-card-ultra-sm * 1, -120px);
                }
            
                to {
                    transform: rotate(30deg) translate($size-card-ultra-sm * 2, -280px);
                }
            }
            
            @keyframes fiveToFour {
                from {
                    transform: rotate(30deg) translate($size-card-ultra-sm * 2, -280px);
                }
            
                to {
                    transform: rotate(15deg) translate($size-card-ultra-sm * 1, -120px);
                }
            }
            
            @keyframes fiveToSix {
                from {
                    transform: rotate(30deg) translate($size-card-ultra-sm * 2, -280px);
                }
            
                to {
                    transform: rotate(45deg) translate($size-card-ultra-sm * 3, -100px);
                }
            }
            
            @keyframes sixToFive {
                from {
                    transform: rotate(45deg) translate($size-card-ultra-sm * 3, -100px);
                }
            
                to {
                    transform: rotate(30deg) translate($size-card-ultra-sm * 2, -280px);
                }
            }
            
            
            
            @keyframes sixToOne {
                from {
                    transform: rotate(-45deg) translate(-($size-card-ultra-sm * 3), -220px);
                }
            
                to {
                    transform: rotate(-30deg) translate(-($size-card-ultra-sm * 2), -280px);
                }
            }
            
            @keyframes oneToSix {
                from {
                    transform: rotate(-30deg) translate(-($size-card-ultra-sm * 2), -280px);
                }
            
                to {
                    transform: rotate(-45deg) translate(-($size-card-ultra-sm * 3), -220px);
                }
            }
        }
    }

    @media (max-width: 1377px) {
        .CardSustainabillity {
            position: absolute;
    
            &.card-position-1 {
                transform: rotate(-30deg) translate(-($size-card-ultra-sm * 2), -295px);
    
                &.-animation-right {
                    animation: 1.5s ease-out 0s 1 normal forwards running oneToTwo;
                }
    
                &.-animation-left {
                    animation: 1.5s ease-out 0s 1 normal forwards running oneToSix;
                }
            }
    
            &.card-position-2 {
                transform: rotate(-15deg) translate(-($size-card-ultra-sm * 1), -140px);
    
                &.-animation-right {
                    animation: 1.5s ease-out 0s 1 normal forwards running twoToThree;
                }
    
                &.-animation-left {
                    animation: 1.5s ease-out 0s 1 normal forwards running twoToOne;
                }
            }
    
            &.card-position-3 {
                transform: rotate(0) translate(0, -92px);
    
                &.-animation-right {
                    animation: 1.5s ease-out 0s 1 normal forwards running threeToFour;
                }
    
                &.-animation-left {
                        animation: 1.5s ease-out 0s 1 normal forwards running threeToTwo;
                }
            }
    
            &.card-position-4 {
                transform: rotate(15deg) translate($size-card-ultra-sm * 1, -140px);
    
                &.-animation-right {
                        animation: 1.5s ease-out 0s 1 normal forwards running fourToFive;
                }
    
                &.-animation-left {
                        animation: 1.5s ease-out 0s 1 normal forwards running fourToThree;
                }
            }
    
            &.card-position-5 {
                transform: rotate(30deg) translate($size-card-ultra-sm * 2, -295px);
    
                &.-animation-right {
                        animation: 1.5s ease-out 0s 1 normal forwards running fiveToSix;
                }
    
                &.-animation-left {
                        animation: 1.5s ease-out 0s 1 normal forwards running fiveToFour;
                }
            }
    
            &.card-position-6 {
                transform: rotate(-45deg) translate(-($size-card-ultra-sm * 3), -220px);
            
                &.-animation-right {
                    animation: 1.5s ease-out 0s 1 normal forwards running sixToOne;
                }
    
                &.-animation-left {
                    animation: 1.5s ease-out 0s 1 normal forwards running sixToFive;
                }
    
            }
    
            @keyframes oneToTwo {
                from {
                    transform: rotate(-30deg) translate(-($size-card-ultra-sm * 2), -295px);
                }
            
                to {
                    transform: rotate(-15deg) translate(-($size-card-ultra-sm * 1), -140px);
                }
            }
            
            @keyframes twoToOne {
                from {
                    transform: rotate(-15deg) translate(-($size-card-ultra-sm * 1), -140px);
                }
            
                to {
                    transform: rotate(-30deg) translate(-($size-card-ultra-sm * 2), -295px);
                }
            }
            
            @keyframes twoToThree {
                from {
                    transform: rotate(-15deg) translate(-($size-card-ultra-sm * 1), -140px);
                }
            
                to {
                    transform: rotate(0) translate(0, -92px);
                }
            }
            
            @keyframes threeToTwo {
                from {
                    transform: rotate(0) translate(0, -92px);
                }
            
                to {
                    transform: rotate(-15deg) translate(-($size-card-ultra-sm * 1), -140px);
                }
            }
            
            @keyframes threeToFour {
                from {
                    transform: rotate(0) translate(0, -92px);
                }
            
                to {
                    transform: rotate(15deg) translate($size-card-ultra-sm * 1, -140px);
                }
            }
            
            @keyframes fourToThree {
                from {
                    transform: rotate(15deg) translate($size-card-ultra-sm * 1, -140px);
                }
            
                to {
                    transform: rotate(0) translate(0, -92px);
                }
            }
            
            @keyframes fourToFive {
                from {
                    transform: rotate(15deg) translate($size-card-ultra-sm * 1, -140px);
                }
            
                to {
                    transform: rotate(30deg) translate($size-card-ultra-sm * 2, -295px);
                }
            }
            
            @keyframes fiveToFour {
                from {
                    transform: rotate(30deg) translate($size-card-ultra-sm * 2, -295px);
                }
            
                to {
                    transform: rotate(15deg) translate($size-card-ultra-sm * 1, -140px);
                }
            }
            
            @keyframes fiveToSix {
                from {
                    transform: rotate(30deg) translate($size-card-ultra-sm * 2, -295px);
                }
            
                to {
                    transform: rotate(45deg) translate($size-card-ultra-sm * 3, -100px);
                }
            }
            
            @keyframes sixToFive {
                from {
                    transform: rotate(45deg) translate($size-card-ultra-sm * 3, -100px);
                }
            
                to {
                    transform: rotate(30deg) translate($size-card-ultra-sm * 2, -295px);
                }
            }
            
            
            
            @keyframes sixToOne {
                from {
                    transform: rotate(-45deg) translate(-($size-card-ultra-sm * 3), -220px);
                }
            
                to {
                    transform: rotate(-30deg) translate(-($size-card-ultra-sm * 2), -295px);
                }
            }
            
            @keyframes oneToSix {
                from {
                    transform: rotate(-30deg) translate(-($size-card-ultra-sm * 2), -295px);
                }
            
                to {
                    transform: rotate(-45deg) translate(-($size-card-ultra-sm * 3), -220px);
                }
            }
        }
    }

    @media (max-width: 1280px) {
        .CardSustainabillity {
            position: absolute;
    
            &.card-position-1 {
                transform: rotate(-20deg) translate(-($size-card-micro-sm * 2), -235px);
    
                &.-animation-right {
                    animation: 1.5s ease-out 0s 1 normal forwards running oneToTwo;
                }
    
                &.-animation-left {
                    animation: 1.5s ease-out 0s 1 normal forwards running oneToSix;
                }
            }
    
            &.card-position-2 {
                transform: rotate(-10deg) translate(-($size-card-micro-sm * 1), -150px);
    
                &.-animation-right {
                    animation: 1.5s ease-out 0s 1 normal forwards running twoToThree;
                }
    
                &.-animation-left {
                    animation: 1.5s ease-out 0s 1 normal forwards running twoToOne;
                }
            }
    
            &.card-position-3 {
                transform: rotate(0) translate(0, -120px);
    
                &.-animation-right {
                    animation: 1.5s ease-out 0s 1 normal forwards running threeToFour;
                }
    
                &.-animation-left {
                        animation: 1.5s ease-out 0s 1 normal forwards running threeToTwo;
                }
            }
    
            &.card-position-4 {
                transform: rotate(10deg) translate($size-card-micro-sm * 1, -150px);
    
                &.-animation-right {
                        animation: 1.5s ease-out 0s 1 normal forwards running fourToFive;
                }
    
                &.-animation-left {
                        animation: 1.5s ease-out 0s 1 normal forwards running fourToThree;
                }
            }
    
            &.card-position-5 {
                transform: rotate(20deg) translate($size-card-micro-sm * 2, -235px);
    
                &.-animation-right {
                        animation: 1.5s ease-out 0s 1 normal forwards running fiveToSix;
                }
    
                &.-animation-left {
                        animation: 1.5s ease-out 0s 1 normal forwards running fiveToFour;
                }
            }
    
            &.card-position-6 {
                transform: rotate(-45deg) translate(-($size-card-micro-sm * 3), -220px);
            
                &.-animation-right {
                    animation: 1.5s ease-out 0s 1 normal forwards running sixToOne;
                }
    
                &.-animation-left {
                    animation: 1.5s ease-out 0s 1 normal forwards running sixToFive;
                }
    
            }
    
            @keyframes oneToTwo {
                from {
                    transform: rotate(-20deg) translate(-($size-card-micro-sm * 2), -235px);
                }
            
                to {
                    transform: rotate(-10deg) translate(-($size-card-micro-sm * 1), -150px);
                }
            }
            
            @keyframes twoToOne {
                from {
                    transform: rotate(-10deg) translate(-($size-card-micro-sm * 1), -150px);
                }
            
                to {
                    transform: rotate(-20deg) translate(-($size-card-micro-sm * 2), -235px);
                }
            }
            
            @keyframes twoToThree {
                from {
                    transform: rotate(-10deg) translate(-($size-card-micro-sm * 1), -150px);
                }
            
                to {
                    transform: rotate(0) translate(0, -120px);
                }
            }
            
            @keyframes threeToTwo {
                from {
                    transform: rotate(0) translate(0, -120px);
                }
            
                to {
                    transform: rotate(-10deg) translate(-($size-card-micro-sm * 1), -150px);
                }
            }
            
            @keyframes threeToFour {
                from {
                    transform: rotate(0) translate(0, -120px);
                }
            
                to {
                    transform: rotate(10deg) translate($size-card-micro-sm * 1, -150px);
                }
            }
            
            @keyframes fourToThree {
                from {
                    transform: rotate(10deg) translate($size-card-micro-sm * 1, -150px);
                }
            
                to {
                    transform: rotate(0) translate(0, -120px);
                }
            }
            
            @keyframes fourToFive {
                from {
                    transform: rotate(10deg) translate($size-card-micro-sm * 1, -150px);
                }
            
                to {
                    transform: rotate(20deg) translate($size-card-micro-sm * 2, -235px);
                }
            }
            
            @keyframes fiveToFour {
                from {
                    transform: rotate(20deg) translate($size-card-micro-sm * 2, -235px);
                }
            
                to {
                    transform: rotate(10deg) translate($size-card-micro-sm * 1, -150px);
                }
            }
            
            @keyframes fiveToSix {
                from {
                    transform: rotate(20deg) translate($size-card-micro-sm * 2, -235px);
                }
            
                to {
                    transform: rotate(30deg) translate($size-card-micro-sm * 3, -100px);
                }
            }
            
            @keyframes sixToFive {
                from {
                    transform: rotate(30deg) translate($size-card-micro-sm * 3, -100px);
                }
            
                to {
                    transform: rotate(20deg) translate($size-card-micro-sm * 2, -235px);
                }
            }
            
            
            
            @keyframes sixToOne {
                from {
                    transform: rotate(-30deg) translate(-($size-card-micro-sm * 3), -220px);
                }
            
                to {
                    transform: rotate(-20deg) translate(-($size-card-micro-sm * 2), -235px);
                }
            }
            
            @keyframes oneToSix {
                from {
                    transform: rotate(-20deg) translate(-($size-card-micro-sm * 2), -235px);
                }
            
                to {
                    transform: rotate(-30deg) translate(-($size-card-micro-sm * 3), -220px);
                }
            }
        }
    }


    @media (max-width: 1100px) {
        .CardSustainabillity {
            position: absolute;
    
            &.card-position-1 {
                transform: rotate(-20deg) translate(-($size-card-mobile-xl * 2), -190px);
    
                &.-animation-right {
                    animation: 1.5s ease-out 0s 1 normal forwards running oneToTwo;
                }
    
                &.-animation-left {
                    animation: 1.5s ease-out 0s 1 normal forwards running oneToSix;
                }
            }
    
            &.card-position-2 {
                transform: rotate(-10deg) translate(-($size-card-mobile-xl * 1), -120px);
    
                &.-animation-right {
                    animation: 1.5s ease-out 0s 1 normal forwards running twoToThree;
                }
    
                &.-animation-left {
                    animation: 1.5s ease-out 0s 1 normal forwards running twoToOne;
                }
            }
    
            &.card-position-3 {
                transform: rotate(0) translate(0, -92px);
    
                &.-animation-right {
                    animation: 1.5s ease-out 0s 1 normal forwards running threeToFour;
                }
    
                &.-animation-left {
                        animation: 1.5s ease-out 0s 1 normal forwards running threeToTwo;
                }
            }
    
            &.card-position-4 {
                transform: rotate(10deg) translate($size-card-mobile-xl * 1, -120px);
    
                &.-animation-right {
                        animation: 1.5s ease-out 0s 1 normal forwards running fourToFive;
                }
    
                &.-animation-left {
                        animation: 1.5s ease-out 0s 1 normal forwards running fourToThree;
                }
            }
    
            &.card-position-5 {
                transform: rotate(20deg) translate($size-card-mobile-xl * 2, -190px);
    
                &.-animation-right {
                        animation: 1.5s ease-out 0s 1 normal forwards running fiveToSix;
                }
    
                &.-animation-left {
                        animation: 1.5s ease-out 0s 1 normal forwards running fiveToFour;
                }
            }
    
            &.card-position-6 {
                transform: rotate(-45deg) translate(-($size-card-mobile-xl * 3), -220px);
            
                &.-animation-right {
                    animation: 1.5s ease-out 0s 1 normal forwards running sixToOne;
                }
    
                &.-animation-left {
                    animation: 1.5s ease-out 0s 1 normal forwards running sixToFive;
                }
    
            }
    
            @keyframes oneToTwo {
                from {
                    transform: rotate(-20deg) translate(-($size-card-mobile-xl * 2), -190px);
                }
            
                to {
                    transform: rotate(-10deg) translate(-($size-card-mobile-xl * 1), -120px);
                }
            }
            
            @keyframes twoToOne {
                from {
                    transform: rotate(-10deg) translate(-($size-card-mobile-xl * 1), -120px);
                }
            
                to {
                    transform: rotate(-20deg) translate(-($size-card-mobile-xl * 2), -190px);
                }
            }
            
            @keyframes twoToThree {
                from {
                    transform: rotate(-10deg) translate(-($size-card-mobile-xl * 1), -120px);
                }
            
                to {
                    transform: rotate(0) translate(0, -92px);
                }
            }
            
            @keyframes threeToTwo {
                from {
                    transform: rotate(0) translate(0, -92px);
                }
            
                to {
                    transform: rotate(-10deg) translate(-($size-card-mobile-xl * 1), -120px);
                }
            }
            
            @keyframes threeToFour {
                from {
                    transform: rotate(0) translate(0, -92px);
                }
            
                to {
                    transform: rotate(10deg) translate($size-card-mobile-xl * 1, -120px);
                }
            }
            
            @keyframes fourToThree {
                from {
                    transform: rotate(10deg) translate($size-card-mobile-xl * 1, -120px);
                }
            
                to {
                    transform: rotate(0) translate(0, -92px);
                }
            }
            
            @keyframes fourToFive {
                from {
                    transform: rotate(10deg) translate($size-card-mobile-xl * 1, -120px);
                }
            
                to {
                    transform: rotate(20deg) translate($size-card-mobile-xl * 2, -190px);
                }
            }
            
            @keyframes fiveToFour {
                from {
                    transform: rotate(20deg) translate($size-card-mobile-xl * 2, -190px);
                }
            
                to {
                    transform: rotate(10deg) translate($size-card-mobile-xl * 1, -120px);
                }
            }
            
            @keyframes fiveToSix {
                from {
                    transform: rotate(20deg) translate($size-card-mobile-xl * 2, -190px);
                }
            
                to {
                    transform: rotate(30deg) translate($size-card-mobile-xl * 3, -100px);
                }
            }
            
            @keyframes sixToFive {
                from {
                    transform: rotate(30deg) translate($size-card-mobile-xl * 3, -100px);
                }
            
                to {
                    transform: rotate(20deg) translate($size-card-mobile-xl * 2, -190px);
                }
            }
            
            
            
            @keyframes sixToOne {
                from {
                    transform: rotate(-30deg) translate(-($size-card-mobile-xl * 3), -220px);
                }
            
                to {
                    transform: rotate(-20deg) translate(-($size-card-mobile-xl * 2), -190px);
                }
            }
            
            @keyframes oneToSix {
                from {
                    transform: rotate(-20deg) translate(-($size-card-mobile-xl * 2), -190px);
                }
            
                to {
                    transform: rotate(-30deg) translate(-($size-card-mobile-xl * 3), -220px);
                }
            }
        }
    }

    @media (max-width: 700px) {
        .CardSustainabillity {
            position: absolute;
    
            &.card-position-1 {
                transform: rotate(-20deg) translate(-($size-card-mobile-xl * 2), -190px);
    
                &.-animation-right {
                    animation: 1.5s ease-out 0s 1 normal forwards running oneToTwo;
                }
    
                &.-animation-left {
                    animation: 1.5s ease-out 0s 1 normal forwards running oneToSix;
                }
            }
    
            &.card-position-2 {
                transform: rotate(-5deg) translate(-(310px * 1), -105px);
    
                &.-animation-right {
                    animation: 1.5s ease-out 0s 1 normal forwards running twoToThree;
                }
    
                &.-animation-left {
                    animation: 1.5s ease-out 0s 1 normal forwards running twoToOne;
                }
            }
    
            &.card-position-3 {
                transform: rotate(0) translate(0, -92px);
    
                &.-animation-right {
                    animation: 1.5s ease-out 0s 1 normal forwards running threeToFour;
                }
    
                &.-animation-left {
                        animation: 1.5s ease-out 0s 1 normal forwards running threeToTwo;
                }
            }
    
            &.card-position-4 {
              transform: rotate(5deg) translate((310px * 1), -105px);
    
                &.-animation-right {
                        animation: 1.5s ease-out 0s 1 normal forwards running fourToFive;
                }
    
                &.-animation-left {
                        animation: 1.5s ease-out 0s 1 normal forwards running fourToThree;
                }
            }
    
            &.card-position-5 {
                transform: rotate(20deg) translate($size-card-mobile-xl * 2, -190px);
    
                &.-animation-right {
                        animation: 1.5s ease-out 0s 1 normal forwards running fiveToSix;
                }
    
                &.-animation-left {
                        animation: 1.5s ease-out 0s 1 normal forwards running fiveToFour;
                }
            }
    
            &.card-position-6 {
                transform: rotate(-45deg) translate(-($size-card-mobile-xl * 3), -220px);
            
                &.-animation-right {
                    animation: 1.5s ease-out 0s 1 normal forwards running sixToOne;
                }
    
                &.-animation-left {
                    animation: 1.5s ease-out 0s 1 normal forwards running sixToFive;
                }
    
            }
    
            @keyframes oneToTwo {
                from {
                    transform: rotate(-20deg) translate(-($size-card-mobile-xl * 2), -190px);
                }
            
                to {
                    transform: rotate(-5deg) translate(-(310px * 1), -105px);
                }
            }
            
            @keyframes twoToOne {
                from {
                    transform: rotate(-5deg) translate(-(310px * 1), -105px);
                }
            
                to {
                    transform: rotate(-20deg) translate(-($size-card-mobile-xl * 2), -190px);
                }
            }
            
            @keyframes twoToThree {
                from {
                    transform: rotate(-5deg) translate(-(310px * 1), -105px);
                }
            
                to {
                    transform: rotate(0) translate(0, -92px);
                }
            }
            
            @keyframes threeToTwo {
                from {
                    transform: rotate(0) translate(0, -92px);
                }
            
                to {
                    transform: rotate(-5deg) translate(-(310px * 1), -105px);
                }
            }
            
            @keyframes threeToFour {
                from {
                    transform: rotate(0) translate(0, -92px);
                }
            
                to {
                transform: rotate(5deg) translate((310px * 1), -105px);
                }
            }
            
            @keyframes fourToThree {
                from {
                transform: rotate(5deg) translate((310px * 1), -105px);
                }
            
                to {
                    transform: rotate(0) translate(0, -92px);
                }
            }
            
            @keyframes fourToFive {
                from {
                transform: rotate(5deg) translate((310px * 1), -105px);
                }
            
                to {
                    transform: rotate(20deg) translate($size-card-mobile-xl * 2, -190px);
                }
            }
            
            @keyframes fiveToFour {
                from {
                    transform: rotate(20deg) translate($size-card-mobile-xl * 2, -190px);
                }
            
                to {
                transform: rotate(5deg) translate((310px * 1), -105px);
                }
            }
            
            @keyframes fiveToSix {
                from {
                    transform: rotate(20deg) translate($size-card-mobile-xl * 2, -190px);
                }
            
                to {
                    transform: rotate(30deg) translate($size-card-mobile-xl * 3, -100px);
                }
            }
            
            @keyframes sixToFive {
                from {
                    transform: rotate(30deg) translate($size-card-mobile-xl * 3, -100px);
                }
            
                to {
                    transform: rotate(20deg) translate($size-card-mobile-xl * 2, -190px);
                }
            }
            
            
            
            @keyframes sixToOne {
                from {
                    transform: rotate(-30deg) translate(-($size-card-mobile-xl * 3), -220px);
                }
            
                to {
                    transform: rotate(-20deg) translate(-($size-card-mobile-xl * 2), -190px);
                }
            }
            
            @keyframes oneToSix {
                from {
                    transform: rotate(-20deg) translate(-($size-card-mobile-xl * 2), -190px);
                }
            
                to {
                    transform: rotate(-30deg) translate(-($size-card-mobile-xl * 3), -220px);
                }
            }
        }
    }


    @media (max-width: 700px) {
        height: 790px;
    }

}