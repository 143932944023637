.PartnerRessourceTryOn {
    display: flex;
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;

    padding: 80px 16px;

    background-color: white;
    z-index: 2;
    position: relative;

    .left {
        width: 350px;

        .text-desc {
            margin-top: 28px;
        }

        .wrapper-dropdown {
            display: flex;
            flex-direction: column;
            margin-top: 28px;
            cursor: pointer;
            gap: 8px;

            .view_image_before_drop_down {
                display: flex;
                align-items: center;
                gap: 5px;
            }

            .label-drop {
                text-decoration: underline;
            }

            .image-wrapper {
                width: 223px;
                border-radius: 30px;

                img {
                    width: 100%;
                    border-radius: 30px;
                }
            }
        }
    }

    .right {
        margin-left: auto;
        .compare-slider {
            width: 616px;
            border-radius: 30px;
        }

        @media screen and (max-width: 1000px) {
            display: flex;
            justify-content: center;
            margin-top: 20px;
        }
    }

    @media screen and (max-width: 1000px) {
        flex-direction: column;

        .left {
            width: 100%;
        }

        .right {
            width: 100%;

            .video {
                margin-top: 36px;
                width: 100%;
            }
        }
        
    }
}