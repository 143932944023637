@import '../../Style/theme.scss';
@import '../../Style/dropshadows.scss';
@import '../../Style/typographie.scss';

.SelectInput {
    width: 243px;
    height: auto;
    position: relative;
    background-color: $white;
    border-radius: 50px !important;
    padding: 0;
    cursor: pointer;
    outline: none;
    border: none;
}

.ant-select-selector {
    border: none !important;
    border-radius: 50px !important;
    outline: none !important;
    padding: 15px 32px !important;
}
