@import './../Style/theme.scss';

.DefaultLayout {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    .content {
        ul {
            list-style-position: inside;
        }
        a {
            word-break: break-all;
        }
    }

    .create-account-layout {
        @media screen and (max-width: 850px) {
            padding-bottom: 20px;
        }
    }
}
