@import '../../../../Style/typographie.scss';

.Explanation {
    width: 100%;
    max-width: 1280px;
    margin: 0 auto;
    display: flex;
    align-items: center;

    &,
    & > * {
        box-sizing: border-box;
    }

    .explanation-left-section {
        padding: 75px 59px 75px 0;
        width: 55%;
    }

    .explanation-title {
        margin-bottom: 60px;
    }

    .explanation-right-section {
        width: 45%;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;

        .container-try-on {
            position: absolute;
            right: 0%;
            top: 12%;
            display: flex;
            align-items: center;

            .circle {
                height: 27px;
                width: 27px;
                border-radius: 50%;
                background-color: #121212;
                justify-content: center;
                align-items: center;
                display: flex;
                z-index: 2;

                @media screen and (max-width: 1740px) {
                    & {
                        height: 15px;
                        width: 15px;

                        .LogoBrandSmall {
                            width: 8px !important;
                        }
                    }
                }
            }

            .circle-text {
                border-radius: 7.997px;
                border: 0.4px solid #d0d0d0;
                background: #f8f8f8;
                box-shadow: 0px 2.399px 3.998px 1.599px rgba(0, 0, 0, 0.1);
                width: 63.175px;
                height: 15.994px;
                flex-shrink: 0;
                color: #000;
                font-size: 6.398px;
                font-style: normal;
                font-weight: 700 !important;
                line-height: 6.398px; /* 100% */
                letter-spacing: 0.8px;

                display: flex;
                justify-content: center;
                align-items: center;
                margin-left: -13px;
                z-index: 1;

                @media screen and (max-width: 1740px) {
                    & {
                        width: 50.175px;
                        height: 10.994px;
                        font-size: 5.398px;
                        margin-left: -10px;
                    }
                }
            }
        }

        .container-animation {
            opacity: 0;
            transition: all 1.5s ease-in-out;
            position: relative !important;
            top: 0;
            left: -80px;

            .container-loupe {
                z-index: 0;
                position: relative;
                display: flex;

                .container-loupe-cirlce {
                    background-color: $white;

                    border: 1px solid #d0d0d0;
                    border-radius: 100%;
                    width: 125px;
                    height: 125px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    z-index: 1;

                    .circle {
                        width: 30px !important;
                        height: 30px !important;
        
                        @media screen and (max-width: 1740px) {
                            .LogoBrandSmall {
                                width: 20px !important;
                            }
                        }
                    }    
                    
                    .circle-text {
                        border-radius: 7.997px;
                        border: 0.4px solid #d0d0d0;
                        background: #f8f8f8;
                        box-shadow: 0px 2.399px 3.998px 1.599px rgba(0, 0, 0, 0.1);
                        width: 63.175px;
                        height: 15.994px;
                        flex-shrink: 0;
                        color: #000;
                        font-size: 6.398px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 6.398px; /* 100% */
                        letter-spacing: 0.8px;
                    }
        
                }

                .container-try-on {
                    position: relative !important;
                    top: 0;
                    left: 0;
                }

                .triangle {
                    width: 0;
                    height: 0;
                    border-style: solid;
                    border-width: 64px 0 64px 200px;
                    margin-left: -60px;
                    border-color: transparent transparent transparent #f2f2f3;
                    transform: rotate(0deg);
                    z-index: 0;
                    opacity: 0.3;
                }
            }

            &.animated-true {
                opacity: 1;

                top: 0;
                left: -160px;
            }
        }
    }

    .explanation-image-container {
        position: relative;

        .container-try-on {
            position: absolute;
            right: 0%;
            top: 12%;
            display: flex;
            align-items: center;

            .circle {
                height: 27px;
                width: 27px;
                border-radius: 50%;
                background-color: #121212;
                justify-content: center;
                align-items: center;
                display: flex;
                z-index: 2;

                @media screen and (max-width: 1740px) {
                    & {
                        height: 15px;
                        width: 15px;

                        .LogoBrandSmall {
                            width: 8px !important;
                        }
                    }
                }
            }

            .circle-text {
                border-radius: 7.997px;
                border: 0.4px solid #d0d0d0;
                background: #f8f8f8;
                box-shadow: 0px 2.399px 3.998px 1.599px rgba(0, 0, 0, 0.1);
                width: 63.175px;
                height: 15.994px;
                flex-shrink: 0;
                color: #000;
                font-size: 6.398px;
                font-style: normal;
                font-weight: 400;
                line-height: 6.398px; /* 100% */
                letter-spacing: 0.8px;

                display: flex;
                justify-content: center;
                align-items: center;
                margin-left: -13px;
                z-index: 1;

                @media screen and (max-width: 1740px) {
                    & {
                        width: 50.175px;
                        height: 10.994px;
                        font-size: 5.398px;
                        margin-left: -10px;
                    }
                }
            }
        }

        .container-animation {
            opacity: 0;
            transition: all 1.5s ease-in-out;
            position: relative !important;
            top: 0;
            left: -80px;

            .container-loupe {
                z-index: 0;
                position: relative;
                display: flex;

                .container-loupe-cirlce {
                    background-color: $white;

                    border: 1px solid #d0d0d0;
                    border-radius: 100%;
                    width: 125px;
                    height: 125px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    z-index: 1;

                    .circle {
                        width: 30px !important;
                        height: 30px !important;
        
                        @media screen and (max-width: 1740px) {
                            .LogoBrandSmall {
                                width: 20px !important;
                            }
                        }
                    }    
                    
                    .circle-text {
                        border-radius: 7.997px;
                        border: 0.4px solid #d0d0d0;
                        background: #f8f8f8;
                        box-shadow: 0px 2.399px 3.998px 1.599px rgba(0, 0, 0, 0.1);
                        width: 63.175px;
                        height: 15.994px;
                        flex-shrink: 0;
                        color: #000;
                        font-size: 6.398px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 6.398px; /* 100% */
                        letter-spacing: 0.8px;
                    }
        
                }

                .container-try-on {
                    position: relative !important;
                    top: 0;
                    left: 0;
                }

                .triangle {
                    width: 0;
                    height: 0;
                    border-style: solid;
                    border-width: 64px 0 64px 200px;
                    margin-left: -60px;
                    border-color: transparent transparent transparent #f2f2f3;
                    transform: rotate(0deg);
                    z-index: 0;
                    opacity: 0.3;
                }
            }

            &.animated-true {
                opacity: 1;

                top: 0;
                left: -160px;
            }
        }
    }

    .explanation-image {
        width: 100%;
    }

    &__tryOnButton {
        position: absolute;
        top: 27%;
        right: 22%;
        transform: rotate(8deg);
    }

    .explanation-image-shopping {
        width: 100%;
    }

    @media screen and (max-width: 1000px) {
        * {
            text-align: left !important;
        }
        
    }
}

@media screen and (max-width: 850px) {
    .Explanation {
        max-width: calc(361px + 32px);

        .explanation-title {
            text-align: center;
            margin-bottom: 40px;
        }

        .explanation-left-section {
            padding: 0;
            width: 100%;
        }

        .explanation-image {
            margin-bottom: 40px;
        }
    }
}
