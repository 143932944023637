@import './../../../../Style/theme.scss';

.AboutUsSectionLeaders {
    background-color: #f2f2f3;
    padding: 80px 0;

    .WrapperSectionTitle {
        text-align: center;
        margin-bottom: 80px;

        @media screen and (max-width: 768px) {
            margin-bottom: 40px;
        }
    }

    .leaders {
        display: flex;
        flex-flow: wrap;
        justify-content: center;
        gap: 32px;
    }
}