@import './../../../Style/theme.scss';

$breakpoint-header-xl: 1600px;
$breakpoint-header-l: 1300px;
$breakpoint-header-mobile: 990px;

.LayoutHeader {
    position: fixed;
    width: 100%;
    background-color: $white;
    z-index: 200;

    background-color: rgb(255, 255, 255, 0.8);
    backdrop-filter: blur(10px);
}

.layout-header-content {
    position: relative;
    z-index: 1;

    align-items: center;
    display: flex;
    flex-direction: row;
    height: $header-height;
    position: relative;
    padding: 0 110px;

    box-shadow: 0 25px 40px -10px rgba(18, 19, 20, 0.08);

    .LogoBrand {
        margin-right: 80px;

        @media screen and (max-width: $breakpoint-header-xl) {
            & {
                margin-right: 60px;
            }
        }

        @media screen and (max-width: $breakpoint-header-l) {
            & {
                margin-right: 30px;
            }
        }
    }

    .logo-container {
        margin-right: auto;
        cursor: pointer;
    }

    .nav-items {
        display: flex;
        flex-direction: row;
        gap: 40px;
        list-style-type: none;

        .nav-item-selected {
            color: $element-brand-rest-primary;
        }

        .nav-item-title {
            cursor: pointer;
            color: $black;

            &:hover {
                color: $element-brand-rest-primary;
            }

            @media screen and (max-width: 1075px) {
                & {
                    font-size: 14px;
                }
            }

            @media screen and (max-width: 1000px) {
                & {
                    font-size: 13px;
                }
            }
        }

        @media screen and (max-width: $breakpoint-header-xl) {
            & {
                gap: 25px;
            }
        }

        @media screen and (max-width: 1075px) {
            & {
                gap: 15px;
            }
        }

        .nav-item {
            position: relative;
            display: inline-block;
        }

        .nav-item-dropdown {
            display: none;
            position: absolute;
            background-color: $white;
            min-width: 300px;
            box-shadow: 0 17px 33px -2px #1c27310d;
            padding: 12px 16px;
            z-index: 1;
            border-radius: 6px;
        }

        .nav-item:hover {
            .nav-item-dropdown {
                display: block;
            }
        }

        .nav-subitem {
            padding: 8px 0;
            cursor: pointer;
            display: flex;
            justify-content: space-between;

            &.subitem-disabled {
                cursor: not-allowed;
                color: $surface-grey-primary;
            }

            &:hover {
                color: $element-brand-rest-primary;
            }
        }

        a {
            text-decoration: none;
        }
    }

    .actions {
        display: flex;
        flex-direction: row;
        margin-left: 40px;
        gap: 20px;

        a {
            text-decoration: none;
            
        }

        .cta-login {
            color: #2EB67D;
            cursor: pointer;
            margin-top: auto;
            margin-bottom: auto;

            &:hover {
                opacity: 0.8;
            }
        }

        .cards-partner-action {
            display: flex;
            align-items: center;
            gap: 17px;

            .card-partner-action {
                border: 1px solid $surface-grey-disabled;
                padding: 10px 20px;
                border-radius: 10px;
                max-width: 255px;
                text-align: center;
                position: relative;
            
                p {
                    color: $surface-grey-secondary;

                    &.-isLock {
                        &.deprecated-text-title-medium-bold {
                            color: $surface-grey-opposite;
                        }
                        &.text-body-small {
                            color: $element-greys-secondary;
                        }
                    }
                }

                .lock {
                    position: absolute;
                    right: 10px;
                    top: 7px;
                }

                &.-isNotLock {
                    cursor: pointer;
                }

                &:not(.-isLock):hover {
                    border: 1px solid $element-brand-rest-primary;

                    path {
                        fill: $element-brand-rest-primary;
                    }
                }
            }
            
            .blur-effect-action {
                display: flex;
                justify-content: center;
                align-items: center;

                &.-is-not-approval {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 150px;

                    backdrop-filter: blur(5px);
                }
            }
        }

        .row-cta {
            display: flex;
            gap: 10px;
            flex: 1 1 0;
            margin-top: 15px;

            > button {
                flex-grow: 1;
                flex-basis: 100%;
            }

            .button-logout {
                .deprecated-text-title-medium-bold {
                    color: $surface-error-rest-primary !important;
                }
            }
        }


        @media screen and (max-width: $breakpoint-header-xl) {
            .Button {
                padding: 10px 15px !important;

                .deprecated-text-title-medium-bold {
                    font-size: 15px;
                }
            }
        }

        @media screen and (max-width: 1300px) {
            & {
                gap: 10px;
            }
        }
    }

    @media screen and (max-width: $breakpoint-header-xl) {
        & {
            padding: 0 50px;
        }
    }

    @media screen and (max-width: 1300px) {
        & {
            padding: 0 20px;
        }
    }

    @media screen and (max-width: $breakpoint-header-mobile) {
        & {
            .nav-items {
                display: none;
            }

            .actions {
                display: none;
            }
        }
    }
}

.content-menu-burger {
    display: none;
    margin-left: auto;

    .MenuBurgerIcon {
        cursor: pointer;
    }

    @media screen and (max-width: $breakpoint-header-mobile) {
        & {
            display: block;
        }
    }
}
