@import './../../../../Style/theme.scss';
@import '../../../../Style//typographie.scss';

.TryOn {
    background-color: $surface-grey-light;
    position: relative;

    & > * {
        box-sizing: border-box;
    }

    &::before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 50%;
        background-color: $surface-brand-rest-primary;
        z-index: 0;
    }

    &__content {
        display: flex;
        justify-content: center;
        max-width: 1600px;
        margin: 0 auto;
        position: relative;
        z-index: 1;
        flex-grow: 1;
        padding: 84px 16px 0;
        width: 100%;
    }

    &__halfSide {
        padding: 0 48px;
    }

    &__halfSideContent {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        height: 100%;
    }

    &__img {
        max-width: 100%;
    }

    &__before {
        background-color: $surface-grey-light;
        width: 50%;
        padding-left: 0;

        .deprecated-text-headline-large {
            color: #737373;

            bold {
                font-weight: 700;
                color: $element-greys-secondary;
            }
        }
    }

    &__after {
        width: 50%;
        background-color: $surface-brand-rest-primary;
        position: relative;
        padding-left: 0;

        .deprecated-text-headline-large {
            color: rgba(255, 255, 255, 0.75) !important;

            bold {
                font-weight: 700;
                color: $element-greys-white !important;
            }
        }

        .TryOn__img {
            max-width: 120%;
            position: relative;
            z-index: 11;
        }

        .WhiteCard {
            border: 1px solid $surface-brand-rest-secondary;
            background-color: $surface-brand-rest-tertiary;

            .text-body-large-bold {
                color: $element-onbrand-primary !important;
            }
        }

        .TryOn__topContainer {
            width: 100%;
            transform: translateX(6%);
            display: flex;
            flex-direction: column;
            align-items: center;

            h1 {
                color: $element-greys-white;
            }

            bold {
                @include primary-font-mixin();
            }
        }

        .TryOn__paragraph {
            color: $element-greys-white;
        }
    }

    &__paragraph {
        color: #333;
        text-align: center;
    }

    &__topContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 90%;
        margin: 0 auto 40px;

        h1 {
            color: $element-greys-secondary;
            margin-bottom: 8px;

            bold {
                font-weight: 700;
                @include primary-font-mixin();
            }
        }
    }

    .WhiteCard {
        max-width: calc(354px - 36px);
    }

    &__triangle {
        position: absolute;
        top: -84px;
        bottom: 0;
        left: -280px;
        width: 280px;
        z-index: 10;
        background: linear-gradient(
            to bottom right,
            transparent 50%,
            $surface-brand-rest-primary 50%
        );
    }
}

@media screen and (max-width: 1400px) {
    .TryOn {
        h1 {
            font-size: 48px;
        }

        .TryOn__paragraph {
            max-width: 300px;
        }

        &__triangle {
            width: 250px;
            left: -250px;
        }
    }
}

@media screen and (max-width: 1250px) {
    .TryOn {
        &::before {
            content: none;
        }

        &__halfSide {
            width: calc(100% + 32px);
            transform: translateX(-16px);
            padding: 0;
        }

        &__content {
            flex-direction: column;
        }

        &__triangle {
            position: absolute;
            width: 100%;
            height: 200px;
            left: 0;
            right: 0;
            top: -200px;
        }

        &__topContainer {
            flex-direction: column;
            align-items: center;

            h1 {
                margin-bottom: 20px;
                font-size: 32px;
            }
        }

        .WhiteCard {
            max-width: 100%;

            p {
                font-weight: 600;
            }
        }

        &__before {
            .TryOn__img {
                width: 90%;
            }
        }

        &__after {
            padding: 0 16px;
            .TryOn__img {
                max-width: 100%;
                transform: translateX(-6%);
            }

            .TryOn__topContainer {
                transform: translateX(0);
            }
        }
    }
}

@media screen and (max-width: 600px) {
    .TryOn {
        &__triangle {
            top: -175px;
            height: 175px;
        }

        &__paragraph {
            font-size: 16px;
            line-height: 150%;
        }

        &__after {
            padding: 0 16px;
            .TryOn__img {
                max-width: 100%;
                transform: translateX(-8%);
            }

            .TryOn__topContainer {
                transform: translateX(-4%);
            }
        }
    }
}

@media screen and (max-width: 500px) {
    .TryOn {
        &__triangle {
            top: -125px;
            height: 125px;
        }

        &__topContainer {
            margin: 50px auto 50px;
        }
    }
}
