@import './../../../../Style/theme.scss';
.Advantages {
    padding: 120px 0;
    background-color: #f2f2f3; // Not in theme.scss because it's only used here
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;

    .WrapperSectionTitle {
        text-align: center;
        margin: 0 20px;

        @media screen and (max-width: 768px) {
            margin-left: 0;
        }
    }

    .cards-container {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        row-gap: 20px;
        column-gap: 20px;
        margin-top: 32px;
        max-width: 1500px;

        .two-card {
            display: flex;
            flex-wrap: wrap;
            column-gap: 20px;
            row-gap: 20px;
            justify-content: center;
        }
    }


    @media screen and (max-width: 600px) {
        padding: 80px 0;

        .WrapperSectionTitle {
            text-align: left;
        }

        .cards-container {
            margin-top: 20px;
            margin-bottom: 40px;
            margin-top: 20px;
        }
    }

    @media screen and (max-width: 480px) {
        padding: 80px 16px;
        align-items: flex-start;

        .WrapperSectionTitle {
            text-align: left;
        }

        .cards-container {
            .two-card {
                justify-content: flex-start;
            }    
        }
    }
}
