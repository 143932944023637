@import 'src/Style/theme';

.LayoutFooter {
    display: flex;
    flex-direction: column;
    border-top: 1px solid $surface-grey-disabled;
    margin-top: auto;

    .top-section {
        margin: 45px auto 45px auto;
        display: flex;
        align-items: center;
        justify-content: space-between;
        max-width: 1280px;
        width: 100%;

        .links-container {
            display: flex;
            gap: 20px;
            a {
                color: $surface-grey-opposite;
            }

            @media screen and (max-width: 1270px) {
                .cursor-pointer {
                    font-size: 16px !important;
                }
            }

            @media screen and (max-width: 1152px) {
                .cursor-pointer {
                    font-size: 15px !important;
                }
            }

            @media screen and (max-width: 1102px) {
                gap: 12px;
                .cursor-pointer {
                    font-size: 13px !important;
                }
            }

            @media screen and (max-width: 1010px) {
                gap: 8px;
                .cursor-pointer {
                    font-size: 12px !important;
                }
            }

            @media screen and (max-width: 935px) {
                gap: 20px;
                flex-direction: column;
                .cursor-pointer {
                    font-size: 18px !important;
                }
            }
        }

        .social-media-container {
            display: flex;
            gap: 20px;
        }
    }

    .bottom-section {
        background-color: $surface-grey-opposite;
        width: 100%;
        .inner {
            height: 40px;
            margin: auto;
            display: flex;
            align-items: center;
            justify-content: center;
            max-width: 1280px;
        }
    }

    @media screen and (max-width: 1300px) {
        .top-section,
        .bottom-section .inner {
            margin-left: 20px;
            margin-right: 20px;
            width: auto;
        }
    }

    @media screen and (max-width: 915px) {
        .top-section,
        .links-container,
        .bottom-section .inner {
            flex-direction: column;
            align-items: center;
        }

        .top-section {
            gap: 40px;
            .links-container {
                gap: 40px;
            }
        }

        .bottom-section {
            .inner {
                height: auto;
                margin: 20px 0;
                gap: 20px;
            }
        }
    }

    a {
        text-decoration: none;
        color: $white;
    }
}
