@import './../../../../Style/theme.scss';

.ButtonPartnerAccessDashboard {
    .button {
        display: flex;
        background-color: $surface-brand-rest-primary;
        padding: 9px 15px;
        border-radius: 100px;
        align-items: center;
        cursor: pointer;
        justify-content: space-between;

        &:hover {
            background-color: $surface-brand-overlay-primary;
        }

        .button-icon {
            display: flex;
            border-radius: 100px;
            background-color: $white;
            width: 30px;
            height: 30px;

            justify-content: center;
            align-items: center;
            margin-right: 5px;
        }
    }
}