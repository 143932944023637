@import '../../../../Style/theme.scss';

.AnimatedInput {
    width: 100%;
    .input-container {
        display: flex;
        align-items: center;
        border-bottom: 1px solid $surface-grey-tertiary;
        input {
            border: 0;
            height: 65px;
            font-size: 28px;
            font-weight: 600;
            width: 100%;
            margin-right: 5px;
            position: relative;
            display: inline-block;

            &:focus-visible {
                outline: 0;
            }

            &::placeholder {
                color: $surface-grey-tertiary;
            }
        }
    }

    &.has-error {
        .input-container {
            border-bottom: 1px solid $surface-error-rest-primary;
            input {
                color: $surface-error-rest-primary;
            }
            input::placeholder {
                color: $surface-error-rest-primary;
            }
        }
        .error-message {
            display: flex;
            flex-direction: column-reverse;
            text-align: right;
            margin-top: 5px;
        }
    }

    input:-webkit-autofill {
        //remove the autofill color
        -webkit-box-shadow: 0 0 0 1000px white inset !important;
    }

    svg {
        width: 32px;
        height: 32px;

        .stroke-1 {
            stroke: $surface-brand-rest-primary;
        }

        .stroke-2 {
            stroke: $surface-error-rest-primary;
        }
    }

    .stroke-1,
    .stroke-2 {
        fill: none;
        stroke: #231f20;
        stroke-width: 15px;
    }

    .run-animation {
        .animated {
            animation: 0.75s animateIcon forwards;
        }
    }

    .animated {
        stroke-dasharray: 150;
        stroke-dashoffset: 150;
    }

    @keyframes animateIcon {
        from {
            stroke-dashoffset: 150;
        }
        to {
            stroke-dashoffset: 0;
        }
    }
}
