@import '../../Style/theme.scss';

$BREAKPOINT_TABLET: 750px;

.CareerPage {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 80px 0;
    justify-items: center;
    font-size: clamp(14px, 1vw, 16px);
    margin-top: 80px;
    @media (max-width: $BREAKPOINT_TABLET) {
        font-size: 14px !important;
        padding: 80px 24px;
    }

    h1 {
        text-align: center;
    }

    .job-title,
    .job-remote,
    .job-location,
    .job-type {
        @media (max-width: $BREAKPOINT_TABLET) {
            font-size: 14px !important;
        }
    }

    table {
        width: 65vw;
        margin-top: 40px;
        border-collapse: collapse;
    }

    thead {
        tr {
            text-align: left;
            border-bottom: 1px solid $surface-grey-secondary;
        }

        th {
            padding: 0 10px 12px 0;
        }
    }

    tr {
        cursor: pointer;
    }

    td {
        padding: 24px 10px 24px 0;
        border-bottom: 1px solid $surface-grey-secondary;
    }
}

.CareerMobile {
    padding: 80px 16px 0;
    h1 {
        text-align: center;
        margin-top: 40px;
        margin-bottom: 24px;
    }
    .job {
        padding: 16px;
        border-bottom: 1px solid $surface-grey-secondary;
        &:last-child {
            border-bottom: none;
        }

        &-info {
            font-size: clamp(14px, 1vw, 16px) !important;
            display: flex;
            flex-wrap: wrap;
            > p {
                padding-right: 4px;

                &:not(:last-child)::after {
                    content: '•';
                    padding-left: 4px;
                    color: $black;
                }
            }
        }
    }
}
