@import "./../../../../../../Style/theme.scss";
  
.CardSustainabillity {
    width: 419px;
    height: 540px;
    position: relative;
    perspective: 1000px;
    background-color: transparent;
    cursor: pointer;

    .card-container {
        width: 100%;
        height: 100%;
        position: absolute;
        transition: all 1.1s;
        transform-style: preserve-3d;

        &.-selected {
            transform: rotateY(180deg);
        }

        .side {
            height: 100%;
            position: absolute;
            backface-visibility: hidden;

            .card-substainabillity-image {
                width: 419px;
                border-radius: 30px;

                @media (max-width: 1500px) {
                    width: 419px * 0.9;
                    height: 540px * 0.9;
                }         
                
                @media (max-width: 1360px) {
                    width: 419px * 0.8;
                    height: 540px * 0.8;
                }
            

                @media (max-width: 1100px) {
                    width: 276px;
                    height: 418px;        
                }            
            }
            
            &.-front {
                z-index: 1;
            }

            &.-back {
                transform: rotateY(180deg);
            }
        }
    } 

    .overlay {
        width: 100%;
        height: 100%;
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
        linear-gradient(0deg, rgba(0, 0, 0, 0.75) 0%, rgba(0, 0, 0, 0) 54.76%);
        border-radius: 30px;
        position: absolute;
        top: 0;
        
        left: 0;
    }

    .wrapper-substainabillity-info {
        position: absolute;
        overflow: hidden;
        bottom: 0;
        width: 100%;
        border-radius: 0 0 30px 30px;
        padding-top: 20px;

        &.-center-content {
            display: flex;

            .infos {
                justify-content: center;
                align-items: center;
            }
        }

        .infos {
            padding: 16px;

            &.-back {
                display: flex;
                justify-content: center;
                height: 100%;
                padding: 16px 40px;

                @media (max-width: 1000px) {
                    padding: 16px;
                    
                }
            }

            &.-front {
                height: 180px;
                margin-top: auto;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: flex-end;
                padding: 40px;             
                .text-body-primary-font-bold {
                    margin-bottom: 10px;
                }

                @media (max-width: 1000px) {
                    padding: 16px;                    
                }
            }

            .vanish {
                opacity: 0;
            }

            .job-title {
                @media (max-width: 1000px) {
                    font-size: 14px !important;
                }
            }

            .text-description {
                font-size: 18px !important;

                @media (max-width: 1000px) {
                    font-size: 12px !important;
                }
            }

            .click-to-see {
                color: #eaeaea5b;
            }

            &.-lasted {
                margin-bottom: 0;
                width: 150px;
                margin-left: auto;
                margin-right: auto;
            }
        }

        &.-selected {
            height: calc(100% - 20px);
            border-radius: 30px;
            backdrop-filter: blur(8px);
            background: linear-gradient(0deg, rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0.75)),
            linear-gradient(0deg, rgba(0, 0, 0, 0.70) 0%, rgba(0, 0, 0, 0) 54.76%);
            
            .infos {
                display: flex;
                flex-direction: column;
                gap: 16px;
            }
        }
    }

    .substainabillity-extra-link {
        width: fit-content;
    }

    .wrapper-close-icon {
        position: absolute;
        top: 20px;
        right: 20px;
        border: 2px solid $white;
        border-radius: 150px;
        width: 20px;
        height: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 120;
    }

    @media (max-width: 1500px) {
        width: 419px * 0.9;
        height: 540px * 0.9;
    }

    @media (max-width: 1360px) {
        width: 419px * 0.8;
        height: 540px * 0.8;
    }

    @media (max-width: 1100px) {
        width: 276px;
        height: 418px;        
    }

}
