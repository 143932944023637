@import '../../../../Style/theme.scss';
@import '../../../../Style/typographie.scss';

.BlogPageList {
    background-color: $surface-grey-light;
    padding: 85px 48px;
    margin: 85px 0 80px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    & > * {
        box-sizing: border-box;
    }

    .content {
        max-width: 1280px;
        width: 100%;
        margin: 0 auto;
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;

        .content-info {
            margin-right: auto;
            display: flex;
            flex-direction: column;
            gap: 30px;
        }

        @media screen and (max-width: 1000px) {
            flex-direction: column-reverse;
            padding-top: 20px;

            .SelectInput {
                margin-left: auto;
            }

            .content-info {
                gap: 11px;
                margin-top: 42px;
            }
        }
    }

    &__breadcrumbs {
        // @include deprecated-body-large-mixin;
        text-transform: uppercase;
        margin-bottom: 28px;
        width: 100%;
    }

    .title-type-sort {
        font-size: 47px !important;
        font-weight: 500 !important;
    }

    .list {
        max-width: 1280px;
        margin-top: 71px;
        flex-wrap: wrap;
        display: flex;
        width: 100%;

        @media screen and (min-width: 1000px) {
            width: 100%;
            justify-content: space-between;
        }

        @media screen and (min-width: 1300px) {
            place-items: center;
            gap: 40px 21px;
        }

        @media screen and (min-width: 1000px) and (max-width: 1300px){
            place-items: center;
            gap: 70px 21px;
        }

        @media screen and (max-width: 1000px) {
            display: flex;
            flex-direction: column;
            gap: 70px;
        }

        @media screen and (max-width: 1374px) and (min-width: 900px) {
            .ArticleCard {
                width: 350px;
                height: 400px;
                min-height: 0;

                .wrapper-img {
                        height: 230px;

                    img {
                        height: 230px;
                    }
                }
            }
        }

        @media screen and (max-width: 1188px) and (min-width: 900px) {
            .ArticleCard {
                width: 300px;

                .wrapper-img {
                        height: 190px;

                    img {
                        height: 190px;
                    }
                }
            }
        }

        @media screen and (max-width: 1040px) and (min-width: 900px) {
            .ArticleCard {
                width: 250px;

                .wrapper-img {
                        height: 160px;

                    img {
                        height: 160px;
                    }
                }
            }
        }
    }

    @media screen and (max-width: 1000px) {
        padding: 26px 16px 80px;
        margin: 85px 0 80px 0;
    }
}
