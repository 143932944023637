.ParteningWithUsSection {
    max-width: 1400px;
    padding: 80px 16px;

    margin-left: auto;
    margin-right: auto;

    display: flex;
    gap: 120px;

    .left {
        width: 444px;
        border-radius: 50px;

        video {
            width: 444px;
            border-radius: 50px;
        }
    }

    .right {
        width: 813px;
        display: flex;
        flex-direction: column;
        gap: 24px;

        .subtitle {
            color: #2EB67D;
            text-transform: uppercase;
        }

        .desc {
            color: #3F3F3F;
        }

        .wrapper-features {
            display: flex;
            justify-content: space-between;
            margin-top: auto;
            padding-bottom: 36px;

            .feature { 
                display: flex;
                flex-direction: column;
                width: 200px;
                gap: 8px;

                .feature-icon {
                    margin-bottom: 8px;
                    padding-left: 8px;
                }

                .feature-desc {
                    color: #3F3F3F !important
                }

                @media screen and (max-width: 550px) {
                    width: 100%;
                }
            }
        }
    }

    @media screen and (max-width: 1430px) {
        max-width: none;
        gap: 40px;

        .right {
            width: 650px;
        }
    }

    @media screen and (max-width: 1160px) {
        .left {
            width: 344px;
            border-radius: 50px;
    
            video {
                width: 344px;
                border-radius: 50px;
            }
        }

        .right {
            width: auto;
            
        }
    }

    @media screen and (max-width: 1000px) {
        flex-direction: column;
 
         .left {
             margin-left: auto;
             margin-right: auto;
         }
 
         .right {
             width: auto;
             
         }
     }
 

    @media screen and (max-width: 550px) {
        flex-direction: column;

        .left {
            margin-left: auto;
            margin-right: auto;

            width: 100%;
            border-radius: 50px;
    
            video {
                width: 100%;
                border-radius: 50px;
            }    
        }
    
        .right {
            width: auto;
            .desc {
                text-wrap: pretty;
                max-width: none !important;
            }
    
            .wrapper-features {
                flex-direction: column;
                gap: 34px;

                .feature {
                    max-width: 350px;
                }
            }
        }
    }
}