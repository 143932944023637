.PetCard {
    display: flex;
    flex-direction: column;
    
    .text {
        text-align: center;
        width: 100px;

        margin-left: auto;
        margin-right: auto;

        font-size: 10px !important;
        font-weight: 300 !important;
        line-height: 15px !important;
    }
}