.CookiesItems {
    padding: 24px;
    background-color: #F9F9F9;
    border-radius: 30px;
    cursor: pointer;

    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .left {
            display: flex;
            align-items: center;
            gap: 16px;
        }
    }

    .bottom {
        height: 0;
        overflow: hidden;
        transition: all ease 0.2s;

        display: flex;
        flex-direction: column;
        gap: 16px;

        &.-open {
            padding-top: 32px;
        }

        .item {
            display: grid;
            width: 100%;
            grid-template-columns: 25% 25% 25% 25%;
            .cell {
                overflow: hidden;
            }
        }

        &.-mobile {
            .item {
                display: flex;
                flex-direction: column;
                gap: 6px;

                .cell {
                    display: flex;
                    justify-content: space-between;
                    gap: 16px;
                    .title {
                        padding-top: 0;
                        padding-bottom: 0;
                    }

                    .value {
                        margin-left: auto;
                        text-align: right;
                    }
                }
            }
        }
    }

}
