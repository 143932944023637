@import './../../Style/theme.scss';

.LifeAtSpreeAIPage {
    display: flex;
    flex-direction: column;
    align-items: center;

    width: 100%;
    margin-top: 80px;

    .container-cores-value {
        width: 100%;
        .container-button-learn-more {
            display: none !important;
        }

        .AboutUsCoresValueSectionPage {
            background-color: #f9f9f9 !important;
        }
    }

    .LatestUpdates {
        width: 100%;
        background-color: #f9f9f9 !important;
    }

    .container-join-team {
        display: flex;
        align-items: center;
        gap: 66px;

        padding-top: 160px;
        padding-bottom: 160px;

        button {
            padding: 12px 64px;
        }

        @media (max-width: 1000px) {
            flex-direction: column;
            gap: 17px;

            padding-top: 120px;
            padding-bottom: 120px;

            text-align: left;
        }
    }

    #benefits {
        width: 100%;
        background-color: #f9f9f9;
        display: flex;
        justify-content: center;
    }
}
