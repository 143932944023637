@import './../../Style/theme.scss';

.WhiteCard {
    background-color: $white;
    border-radius: 30px;

    &.green-border {
        border-top: 11px solid $surface-brand-rest-primary;
    }

    //special scrollbar
    &::-webkit-scrollbar {
        width: 7px;
        margin-right: 10px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #dfdfdf;
        border-radius: 5px;
    }

    &::-webkit-scrollbar-track {
        background-color: white;
        border-radius: 5px;
    }
}
