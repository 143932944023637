@import './../../../Style/theme.scss';

.CardDashboard {
    min-width: 324px;
    height: auto;
    min-height: 274px;
    border-radius: 30px;
    display: flex;
    background-color: $white;
    box-shadow: 0 0 50px 0 #0000001A;
    z-index: 1;

    transition: all 0.2s ease-in-out;

    &.-is-clickable {
        cursor: pointer;
    }

    .inner {
        padding: 32px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;

        .wrapper-icon {
            padding: 10px;
            background-color: $white;
        }

        .text-body-large-bold {
            margin-bottom: 8px;
        }

        .text-desc {
            color: #3F3F3F
        }

        .borderless-cta {
            &.-is-ok {
                color: #2EB67D;
                text-decoration: underline;
            }

            &.-is-coming-soon {
                color: #00000033;
            }
        }
    }

    &.animated {
        &:hover {
            box-shadow: 0 0 50px 0 #00000033;
            transform: translateY(-5px);
    
            .inner {
                .wrapper-icon {
                    svg {
                        animation: icon-hover 1s ease-in-out;
                    }
    
                    @keyframes icon-hover {
                        0% {
                            transform: rotate(0deg);
                        }
                        50% {
                            transform: rotate(5deg);
                        }
                        75% {
                            transform: rotate(-5deg);
                        }
                        100% {
                            transform: rotate(0deg);
                        }
                    }
    
                }
            }
        }    
    }

    @media screen and (max-width: 1080px) {
        min-width: 0;
        width: 324px;
    }
}