@import '../../../../Style/theme.scss';
@import '../../../../Style/typographie.scss';

.ExperienceBenefits {
    padding: 120px 16px;

    ul,
    li {
        margin: 0;
        padding: 0;
        list-style: none;
    }

    &,
    & > * {
        box-sizing: border-box;
    }

    .content {
        max-width: 1280px;
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 32px;
        align-items: center;
        margin: 0 auto;

        @media screen and (max-width: 768px) {
            align-items: normal
        }
    }

    .title {
        text-align: center;
    }

    .benefits {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 40px;
        flex-wrap: wrap;

        .benefit {
            display: flex;
            gap: 24px;
            max-width: 350px;

            .benefit-wrapper-icon {
                min-width: 80px;
                min-height: 80px;
                border-radius: 20px;
                
                display: flex;
                justify-content: center;
                align-items: center;
            }

            .benefit-info {
                display: flex;
                flex-direction: column;
                gap: 8px;

                .benefit-description {
                    color: $element-greys-secondary-alt;
                }
            }
        }
    }

    .cta-contact-us {
        padding-left: 64px;
        padding-right: 64px;

        @media screen and (max-width: 768px) {
            margin-left: auto;
            margin-right: auto;
        }
    }
    
    @media screen and (max-width: 1450px) {
        .benefits {
            grid-template-columns: repeat(2, 1fr);
        }
    }
    
    @media screen and (max-width: 950px) {
        padding: 80px 16px;

        .benefits {
            grid-template-columns: repeat(1, 1fr);
        }

        .title {
            text-align: left;
        }
    }
}

