@import './../../Style/theme.scss';

.CookiesModalDetail {
    max-width: 980px;
    min-width: 750px;

    max-height: 750px;
    background-color: $white;
    position: fixed;
    border-radius: 30px;

    z-index: 10000;
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.15);

    //center
    top: 50%;
    left: 50%;

    transform: translate(-50%, -50%);
    padding: 32px;

    overflow: auto;

    .content {
        display: flex;
        flex-direction: column;
    
        .top {
            display: flex;
            justify-content: space-between;
            align-items: center;

            border-bottom: 1px solid #77777733;
            padding-bottom: 32px;
        }

        .title {
            padding-top: 32px;
            padding-bottom: 8px;
        }

        .desc {
            padding-bottom: 32px;
        }

        .wrapper-contact-us {
            padding: 24px;
            display: flex;
            flex-direction: column;
            gap: 16px;
            margin-bottom: 32px;

            border-radius: 30px;
            border: 1px solid #77777733;
        }

        .wrapper-cookies-choose {
            display: flex;
            flex-direction: column;
            gap: 16px;
            margin-bottom: 32px;
        }

        .actions {
            display: flex;
            justify-content: space-between;

            .Button {
                width: 100%;

                &.reject-all-cta:hover {
                    background-color: $white !important;
                    border-color: #2EB67D !important;
                    .label {
                        color: #2EB67D !important;
                    }
                }

                &.accept-all-cta:hover {
                    background-color: #1C754F;
                }
            }

            .left {
                display: flex;
                gap: 16px;
            }
        }
    }

    @media screen and (max-height: 750px){
        max-height: 80vh;
    }

    @media (max-width: 768px) {
        width: calc(100% - 32px);
        border-radius: 0;

        //reset min and max width
        min-width: 0;
        max-width: none;

        right: 0;
        bottom: 16px;
        height: 100dvh;
        max-height: none;
        padding: 47px 16px;

        top: auto;
        left: auto;

        transform: none;
        position: relative;

        .content {
            padding-bottom: 250px;
        }
        
        .actions {
            flex-direction: column;
            gap: 16px;
            position: fixed;
            bottom: 0;
            left: 0;
            right: 0;
            background-color: $white;
            padding: 5px 16px 47px 16px;

            .left {
                flex-direction: column;
                gap: 16px;
            }
        }
    }

    span {
        text-decoration: underline;
        font-weight: bold;
    }

    a {
        outline: none !important;
    }
}