@import './../../Style/theme.scss';

.FadingCarousel {
    .offset {
        position: relative;
    }

    .carousel-button-group {
        position: absolute;
        top: 40%;
        .custom-arrows {
            position: absolute;
            outline: 0;
            transition: all 0.5s;
            border-radius: 35px;
            border: 0;
            background: $surface-brand-rest-primary;
            min-width: 43px;
            min-height: 43px;
            opacity: 1;
            cursor: pointer;
            &:hover {
                cursor: pointer;
                background: $surface-brand-overlay-primary;
            }
            &.disabled {
                background: $white;
                border: 1px solid $surface-grey-disabled;
                &::before {
                    color: $surface-grey-tertiary;
                }
            }
        }
    }

    .react-multi-carousel-dot-list {
        bottom: -30px;
    }
    .react-multi-carousel-dot {
        button {
            width: 8px;
            height: 8px;
            border: 1px solid transparent;
            background: $surface-brand-rest-primary;
            margin: 0 10px;
            opacity: 0.5;
            &:active {
                background: $surface-brand-rest-primary;
            }
            &:hover {
                opacity: 1;
            }
        }
    }
    .react-multi-carousel-dot--active {
        button {
            opacity: 1;
        }
    }

    // fading elements
    .react-multi-carousel-item {
        transition: all 200ms ease-in-out;
        opacity: 0.4;
    }
    .react-multi-carousel-item--active {
        opacity: 1;
    }
}
