@import './../../../Style/theme.scss';
@import './../../../Style/typographie.scss';

.LandingPage {
    height: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 80px;

    &,
    & > * {
        box-sizing: border-box;
    }

    .hero {
        position: relative;
        max-height: 720px;
        overflow: hidden;
        padding: 0 0 !important;
    
        .wrapper-video {
            img {
                margin-left: auto;
                margin-right: auto; 
                width: 100%;           
                
                overflow-y: hidden;
    
                @media (max-width: 768px) {
                    width: auto;
                    height: 100vh !important;
                    position: relative;
                    top: 0;
                    left: 50%;
                    transform: translate(-50%, 0);
                }
            }
        
            &::after {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                overflow-y: hidden;    
            }
    
            @media (max-width: 768px) {
                width: auto;
                height: 840px !important;
                position: relative;
                top: 0;
                left: 50%;
                transform: translate(-50%, 0);
            }
        }
    
        .inner {
            position: absolute;
            left: 38%;
            bottom: 120px;
            transform: translate(-50%, -10%);
            z-index: 1;
            width: max-content;
    
            @media (max-width: 1650px) {
                max-width: 1100px;
            }
    
            @media (max-width: 1150px) {
                max-width: 900px;
            }
    
            @media (max-width: 1110px) {
                max-width: 700px;
            }
    
            @media (max-width: 750px) {
                max-width: 600px;
            }
    
            @media (max-width: 650px) {
                max-width: 500px;
            }
    
            @media (max-width: 550px) {
                max-width: 400px;
            }

            &.-IT {
                left: 47%
            }
            
            @media (max-width: 1600px) and (min-width: 1430px) {
                position: absolute;
                left: 33%;
                transform: translate(-50%, -20%);
                z-index: 1;
                &.-IT {
                    left: 41%
                }
            }

            @media (max-width: 1430px) and (min-width: 1240px) {
                position: absolute;
                left: 38%;
                transform: translate(-50%, -10%);
                z-index: 1;
                &.-IT {
                    left: 47%
                }
            }


            @media (max-width: 1240px) and (min-width: 1170px) {
                position: absolute;
                left: 37%;
                transform: translate(-50%, 10%);
                z-index: 1;
                &.-IT {
                    left: 48%
                }
            }

            @media (max-width: 1170px) and (min-width: 1000px) {
                position: absolute;
                left: 40%;
                transform: translate(-50%, 10%);
                z-index: 1;
            }
    
            @media (max-width: 1000px) and (min-width: 800px) {
                position: absolute;
                left: 50%;
                bottom: 10%;
                transform: translate(-50%, -20%);
                z-index: 1;
    
                display: flex;
                flex-direction: column;
                text-align: center;
            }

            @media (max-width: 800px) and (min-width:580px) {
                position: absolute;
                left: 50%;
                bottom: 10%;
                transform: translate(-50%, -50%);
                z-index: 1;
    
                display: flex;
                flex-direction: column;
                text-align: center;
            }
    
            @media (max-width: 580px) {
                position: absolute;
                bottom: 100px;
                left: 16px;
                right: 0;
    
                transform: translate(0, 0);
                width: calc(100% - 32px);
    
                display: flex;
                flex-direction: column;
                text-align: left;
            }

            .WrapperSectionTitle {
                margin-bottom: 16px;
            }
        }

        @media (max-width: 768px) {
            max-height: 640px;
        }
    }

    .title {
        margin-bottom: 16px;
    }

    .wrapper-btn {
        width: 100%;
        display: flex;
        flex-direction: row;
        margin-right: auto;
        gap: 32px;
        max-width: 250px;
        margin-top: 64px;
    
        .cta-sign-in {
            background-color: transparent !important;

            &:hover {
                background-color: #2b2b2b !important;
            }
            .label {
                white-space: nowrap;
            }
        }

        @media screen and (max-width: 768px) {
            flex-direction: column;
            gap: 16px;

            .Button {
                width: fit-content !important;
            }
        }
    }

    .wrapper-paragraph {
        margin-top: calc(60px - 38px);
        display: flex;
        gap: 40px;

        .paragraph {
            display: flex;
            flex-direction: column;
            gap: 10px;
        }

        @media screen and (max-width: 768px) {
            flex-direction: column;
            gap: 64px;

            .Button {
                width: fit-content !important;
            }
        }
    }


    .cta-sign-upText {
        font-weight: 700;
    }

    .cta-sign-inText {
        font-weight: 700;
        white-space: nowrap;
    }

    .overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 1;
    }
}

@media screen and (max-width: 950px) {
    .LandingPage {
        &__bottomPart {
            max-width: 313px;
        }
    }
}

.LandingPageBenefits {
    display: grid;
    grid-auto-rows: 1fr;

    &,
    & > * {
        box-sizing: border-box;
    }

    &__section {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 32px;
        position: relative;
        height: 801px;

        &--manageAssets {
            background: linear-gradient(0deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.5) 100%),
                url('../../../Assets/Img/CreateAccount/manage-assets.png');
            background-size: cover;
            background-position: center;
        }
    }

    &__video {
        position: absolute;
        min-width: 100%;
        height: 100%;
        z-index: -1;
        object-position: 50% 50%;
        object-fit: cover;
    }

    &__sectionContent {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 33px;
        z-index: 2;

        @media screen and (max-width: 940px) {
            width: 85%;
        }
    }

    .sectionTitle {
        display: flex;
        align-items: center;
        z-index: 2;

        svg {
            width: 40px;
            height: 40px;
            margin-right: 21px;
        }
    }

    .subTitle {
        line-height: 150%;
        text-align: center;
        z-index: 2;
        font-size: 32px !important;
        font-weight: 300;
    }
}

@media screen and (max-width: 1200px) {
    .LandingPageBenefits {
        &__section {
            gap: 20px;
        }
    }
}

@media screen and (max-width: 940px) {
    .LandingPageBenefits {
        &__section {
            gap: 0;
            height: 489px;
        }

        .sectionTitle {
            font-size: 16px !important;
        }

        .subTitle {
            font-size: 16px !important;
        }
    }
}

.LandingPageButtonsSection {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    padding: 80px 16px;

    .WrapperSectionTitle {
        margin-bottom: 66px;
        text-align: center;
    }

    .cta-sign-up {
        padding-left: 64px;
        padding-right: 64px;
        margin: 0 32px;
    }

    .cta-sign-in {
        .label {
            color: $surface-brand-rest-primary !important
        }
    }
}