@import '../../Style/theme.scss';

.TabElement {
    padding: 8px 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border-radius: 50px;
    background-color: $surface-grey-light;
    cursor: pointer;
    border: none;
    transition: background-color 0.3s ease-in-out;
    color: #2b2b2b;
    font-family: 'poppins', sans-serif;
    font-size: 16px;
    font-weight: 500;
    min-width: 119px;

    &:hover {
        background-color: $surface-grey-disabled;
    }
}
