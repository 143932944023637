.ImageOrVideo {
    position: relative;

    .tumbnail-overlay {
        background-color: #00000080;
        z-index: 1;

        position: absolute;
        top: 0;
        left: 0;
        height: 117px;
        width: 117px;
        border-radius: 8px;

        animation: fade-in 0.3s ease-in-out;

        @keyframes fade-in {
            from {
                opacity: 0;
            }
            to {
                opacity: 1;
            }
        }

    }
}