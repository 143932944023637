@import '../../../../Style/theme.scss';
@import '../../../../Style/breakpoint.scss';

.HeroVideoContainer {
    position: relative;

    .overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 99.5%;
        background: radial-gradient(50% 157.51% at 50% 50%, rgba(43, 43, 43, 0) 0%, rgba(0, 0, 0, 0.75) 100%),
        linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
        z-index: 1;
    }

}
.HeroVideo {
    height: calc(100vh - 80px);
    width: 100vw;
    object-fit: cover;
}

.HeroVideoContent {
    position: absolute;
    z-index: 1;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    
    @media (max-width: map-get($breakpoints, tablet)) {
        width: 250px;
    }

    h1 {
        color: $white;
        margin-bottom: 32px;
        @media (max-width: map-get($breakpoints, tablet)) {
            display: flex;
            flex-direction: column;
            margin-bottom: 0 !important;
        }
    }

    .HeroVideoSubtitle {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;

        .subtitle-wrapper {
            display: flex;
            gap: 10px;
            justify-content: center;
            align-items: center;
            margin-top: 32px;
            @media (max-width: map-get($breakpoints, tablet)) {
                flex-direction: column;
                margin-top: 16px;
            }

            gap: 8px;
        }

        .static-text {
            color: #d3d3d3;
            left: 10%;
            @media (max-width: map-get($breakpoints, tablet)) {
                left: 0;
                position: static;
            }
        }

        .container-cta {
            display: flex;
        }

        .dynamic-text-container {
            padding: 24px;
            border-radius: 40px;
            background-color: rgba($white, 0.2);
            white-space: nowrap;
            @media (max-width: map-get($breakpoints, tablet)) {
                position: static;
            }
        }
        
        .dynamic-text {
            color: $white;
            white-space: nowrap;
        }
    }
}
