.GlobalLoadingContainer {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    display: none !important;
    background-color: rgba(black, 0.5);
    align-items: center;
    justify-content: center;
    z-index: 10000001;

    &.loading {
        display: flex !important;
    }

    .logo {
        max-width: 50px;
        animation: spin 4s linear infinite;
    }

    @keyframes spin {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(360deg);
        }
    }
}
