@import './../../../../Style/theme.scss';
@import '../../../../Style/typographie.scss';

.LifeAtSpreeAISectionHero {
    display: flex;
    flex-direction: column;
    background-color: $white;
    padding: 80px;

    .wrapper-meeting-team {
        display: grid;
        grid-template-columns: repeat(3, 350px);
        justify-content: center;

        margin-bottom: 80px;

        .card-top-left {
            video {
                border-top-left-radius: 30px;
            }
        }

        .card-top-right {
            video {
                border-top-right-radius: 30px;
            }
        }

        .card-bottom-left {
            video {
                border-bottom-left-radius: 30px;
            }
        }

        .card-bottom-right {
            video {
                border-bottom-right-radius: 30px;
            }
        }

        @media (max-width: 1450px) {
            grid-template-columns: repeat(3, 300px);
        }

        @media (max-width: 1050px) {
            grid-template-columns: repeat(2, 1fr);
            margin-bottom: 40px;
        }

        &.-full {
            grid-template-columns: repeat(5, 250px);

            @media (max-width: 1290px) {
                grid-template-columns: repeat(5, 200px);
            }

            @media (max-width: 1066px) {
                grid-template-columns: repeat(3, 250px);
            }
        }
    }

    .title {
        text-align: center;
        padding-bottom: 32px;

        .gradient-text {
            @include primary-font-mixin();
        }

        @media (max-width: 850px) {
            text-align: left;
            font-size: 32px;
            line-height: normal;

            padding-bottom: 40px;
        }
    }

    .our-mission {
        display: grid;
        grid-template-columns: 1fr 1fr;
        margin-left: auto;
        margin-right: auto;

        max-width: 1050px;

        .right {
            .cta-join-us {
                margin-top: 65px;
            }

            .deprecated-text-title-large {
                color: $element-greys-secondary;
                text-align: left;
            }

            @media (max-width: 600px) {
                a {
                    margin-right: auto;
                }
            }
        }

        @media (max-width: 1450px) {
            max-width: 900px;
        }

        @media (max-width: 870px) {
            max-width: none;
            display: flex;
            flex-direction: column;
            gap: 40px;

            .right {
                justify-content: center;
                align-items: center;
                display: flex;
                flex-direction: column;

                .cta-join-us {
                    margin-right: auto;

                    padding-left: 54px;
                    padding-right: 54px;

                    margin-top: 40px;
                }
            }
        }
    }

    @media (max-width: 1600px) {
        max-width: 1200px;
    }

    @media (max-width: 1350px) {
        max-width: 1000px;
    }

    @media (max-width: 1100px) {
        max-width: none;
        padding: 80px 16px;
    }
}
