.SetNewPassword {
    background: linear-gradient(black 50%, white 50%);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 85px 0;

    .WhiteCard {
        height: 500px;
        width: 480px;
        box-shadow: 0px 25px 40px -10px rgba(28, 39, 49, 0.08);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        row-gap: 30px;
        max-width: 480px;
        padding: 0 80px;

        .form-container {
            width: 100%;
            .form-content {
                padding: 0;
            }
            button {
                max-width: 250px;
            }
            .FormSubmit {
                margin: auto;
            }
        }

        .Button {
            width: 250px;
        }
    }

    @media screen and (max-width: 750px) {
        padding: 85px 20px;
        .WhiteCard {
            width: 100%;
            padding: 20px;
            height: 400px;
        }
    }
}
