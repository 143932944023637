@import './theme';

//https://www.figma.com/file/I8L4Dl1g0r35Oz689IzWht/SpreeAI---Design-System?type=design&node-id=2120-793&mode=design&t=WiiTkCzXP4xMdcju-4

@mixin primary-font-mixin {
    font-family: 'poppins', sans-serif !important;
}

@mixin secondary-font-mixin {
    font-family: 'montserrat', sans-serif !important;
}

/* -------------- */
// Text Main Header
/* -------------- */

@mixin text-main-header-mixin {
    color: $surface-grey-opposite;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
    font-size: 48px !important;

    @include primary-font-mixin();

    * {
        @include primary-font-mixin();
    }

    @media screen and (max-width: 1000px) {
        font-size: 32px !important;
        color: $surface-grey-opposite;
        font-style: normal;
        font-weight: 500;
        line-height: 120%; /* 38.4px */
    }
}

.text-main-header {
    @include text-main-header-mixin;
}

/* -------------- */
// Text Body
/* -------------- */

@mixin text-body-mixin {
    color: $element-greys-secondary;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    font-size: 18px !important;

    @include secondary-font-mixin();

    * {
        @include secondary-font-mixin();
    }

    @media screen and (max-width: 1000px) {
        font-size: 16px !important;
        line-height: 150%; /* 21px */
    }
}

.text-body {
    @include text-body-mixin;
}

.text-body-bold {
    @include text-body-mixin;
    font-weight: 700;
}

// same with primary font

.text-body-primary-font {
    @include text-body-mixin;
    @include primary-font-mixin;
}

.text-body-primary-font-bold {
    @include text-body-mixin;
    @include primary-font-mixin;
    font-weight: 700 !important;
}

// Medium

.text-body-medium {
    @include text-body-mixin;
    font-size: 16px !important;
}

.text-body-medium-bold {
    @include text-body-mixin;
    font-size: 16px !important;
    font-weight: 700;
}

.text-body-medium-primary-font {
    @include text-body-mixin;
    font-size: 16px !important;
    @include primary-font-mixin;
}

.text-body-medium-primary-font-bold {
    @include text-body-mixin;
    font-size: 16px !important;
    font-weight: 700;
    @include primary-font-mixin;
}

// Small

.text-body-small {
    @include text-body-mixin;
    font-size: 14px !important;
}

.text-body-small-bold {
    @include text-body-mixin;
    font-size: 14px !important;
    font-weight: 700;
}

// same with primary font

.text-body-small-primary-font {
    @include text-body-mixin;
    @include primary-font-mixin;
    font-size: 14px !important;
}

.text-body-small-primary-font-bold {
    @include text-body-mixin;
    @include primary-font-mixin;
    font-size: 14px !important;
    font-weight: 600;
}

// Large

.text-body-large {
    @include text-body-mixin;
    font-size: 24px !important;
}

.text-body-large-bold {
    @include text-body-mixin;
    font-size: 24px !important;
    font-weight: 700;
}

.text-body-large-primary-font {
    @include text-body-mixin;
    font-size: 24px !important;
    @include primary-font-mixin;
}

.text-body-large-primary-font-bold {
    @include text-body-mixin;
    font-size: 24px !important;
    font-weight: 600;
    @include primary-font-mixin;
}

.text-primary-font {
    @include primary-font-mixin;
}

/* -------------- */

// font colors
.text-white {
    color: $white;
}
.text-white-alt {
    color: $white-alt;
}
.text-element-greys-white {
    color: $element-greys-white;
}
.text-element-greys-primary {
    color: $element-greys-primary;
}
.text-element-greys-secondary {
    color: $element-greys-secondary;
}
.text-element-greys-secondary-alt {
    color: $element-greys-secondary-alt;
}
.text-element-greys-tertiary {
    color: $element-greys-tertiary;
}
.text-element-brand-rest-primary {
    color: $element-brand-rest-primary;
}
.text-surface-grey-primary {
    color: $surface-grey-primary;
}
.text-surface-grey-disabled {
    color: $surface-grey-disabled;
}
.text-surface-grey-tertiary {
    color: $surface-grey-tertiary;
}

.text-surface-grey-opposite {
    color: $surface-grey-opposite;
}

.text-surface-support-secondary {
    color: $surface-support-secondary;
}
.text-surface-support-tertiary {
    color: $surface-support-tertiary;
}

.text-surface-error-rest-primary {
    color: $surface-error-rest-primary;
}
.text-surface-brand-rest-primary {
    color: $surface-brand-rest-primary;
}
.text-element-onbrand-primary {
    color: $element-onbrand-primary;
}
.text-surface-error-rest-primary {
    color: $surface-error-rest-primary;
}
.text-element-black-secondary-alt {
    color: $element-black-secondary-alt;
}

/* -------------- */

// font weight

.text-weight-300 {
    font-weight: 300;
}

.text-weight-500 {
    font-weight: 500;
}

.text-weight-600 {
    font-weight: 600;
}

/* OLD FOR THE REST OF THE PROJECT WORK BUT NOT USE */

@mixin deprecated-display-large-mixin {
    font-size: 57px;
    line-height: 64px;
    font-weight: 500;
    color: $element-greys-primary;

    @include primary-font-mixin();
}
.deprecated-text-display-large {
    @include deprecated-display-large-mixin;
}
/* -------------- */

@mixin deprecated-display-medium-mixin {
    font-size: 45px;
    line-height: 52px;
    font-weight: 500;
    color: $element-greys-primary;

    @include primary-font-mixin();
}
.deprecated-text-display-medium {
    @include deprecated-display-medium-mixin;
}
/* -------------- */

@mixin deprecated-display-small-mixin {
    font-size: 36px;
    line-height: 44px;
    font-weight: 500;
    color: $element-greys-primary;

    @include primary-font-mixin();
}
.deprecated-text-display-small {
    @include deprecated-display-small-mixin;
}
/* -------------- */

@mixin deprecated-headline-large-mixin {
    font-size: 32px;
    line-height: 40px;
    font-weight: 500;
    color: $element-greys-primary;

    @include primary-font-mixin();
}
.deprecated-text-headline-large {
    @include deprecated-headline-large-mixin;
}
/* -------------- */

@mixin deprecated-headline-medium-mixin {
    font-size: 28px;
    line-height: 36px;
    font-weight: 500;
    color: $element-greys-primary;

    @include primary-font-mixin();
}
.deprecated-text-headline-medium {
    @include deprecated-headline-medium-mixin;
}
/* -------------- */

@mixin deprecated-headline-small-mixin {
    font-size: 24px;
    line-height: 32px;
    font-weight: 500;
    color: $element-greys-primary;

    @include primary-font-mixin();
}
.deprecated-text-headline-small {
    @include deprecated-headline-small-mixin;
}
/* -------------- */

@mixin deprecated-title-large-mixin {
    font-size: 22px;
    line-height: 28px;
    font-weight: 400;
    color: $element-greys-primary;

    @include primary-font-mixin();
}
.deprecated-text-title-large {
    @include deprecated-title-large-mixin;
}
.deprecated-text-title-large-bold {
    @include deprecated-title-large-mixin;
    font-weight: 500;
}
/* -------------- */

@mixin deprecated-title-medium-mixin {
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    color: $element-greys-primary;

    @include secondary-font-mixin();
}
.deprecated-text-title-medium {
    @include deprecated-title-medium-mixin;
}
.deprecated-text-title-medium-bold {
    @include deprecated-title-medium-mixin;
    font-weight: 500;
}
/* -------------- */

@mixin deprecated-title-small-mixin {
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    color: $element-greys-primary;

    @include secondary-font-mixin();
}
.deprecated-text-title-small {
    @include deprecated-title-small-mixin;
}
.deprecated-text-title-small-bold {
    @include deprecated-title-small-mixin;
    font-weight: 500;
}

/* -------------- */

@mixin body-large-mixin {
    font-size: 18px;
    line-height: 24px;
    font-weight: 400;
    color: $element-greys-primary;

    @include secondary-font-mixin();

    @media screen and (max-width: 1000px) {
        font-size: 16px;
        line-height: 22px;
    }
}
.deprecated-text-body-large {
    @include body-large-mixin;
}
.deprecated-text-body-large-bold {
    @include body-large-mixin;
    font-weight: 500;
}
/* -------------- */

/* END */
