@import '../../../../Style/theme.scss';

.ExperienceFlow {
    padding: 80px 16px;

    img {
        margin-bottom: 24px !important;
        border-radius: 24px;
    }

    .WrapperSectionTitle {
        text-align: center;

        @media screen and (max-width: 600px) {
            margin-bottom: 24px;
            max-width: 350px;
            margin-left: auto;
            margin-right: auto;
        }
    }

    .disclaimer {
        text-align: center;
        margin-bottom: 24px;
        color: $element-greys-secondary-alt;
    }

    @media screen and (max-width: 600px) {
        h1 {
            font-size: 2em;
        }
    }

    .mobile-carousel-container {
        display: flex;
        justify-content: center;
        .Carousel {
            max-width: 532px;
            @media screen and (max-width: 530px) {
                max-width: 100%;
            }
            .ExperienceFlowStep {
                max-width: 600px;
                margin: 0 16px;
                img {
                    width: 100%;
                }
            }
        }
    }
}
