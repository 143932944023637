@import './../../../../Style/theme.scss';

.ExperienceHero {
    .WrapperSectionTitle {
        text-align: center;

        @media screen and (min-width: 1060px) {
            &.-lng-it {
                margin-left: 45px;
            }
        }
        .sliding-words-wrapper {
            vertical-align: top;
            display: inline-block;
            overflow: hidden;
            height: 58px;
    
            @media screen and (max-width: 1000px) {
                height: 40px;
            }
        }
          
        .sliding-words {
            display: inline-flex;
            flex-direction: column;
            position: relative;
            top: 0;
            animation: slide-words 10s ease-in-out infinite;
            text-align: left;
        }
          
        @keyframes slide-words {
            0% {
                top: 0;
            }
            15% {
                top: 0;
            }
            30% {
                top: -100%;
            }
            45% {
                top: -100%;
            }
            60% {
                top: -200%;
            }
            75% {
                top: -200%;
            }
        }
    
        @media screen and (max-width: 560px) {
            text-align: left;

            .sliding-words-wrapper {
                margin-top: 3px;
            }
        }
    }

    .subTitle {
        text-align: center;
        margin-top: 24px;

        @media screen and (max-width: 560px) {
            text-align: left;
        }
    }

}
