@import 'src/Style/theme';

.Carousel {
    .slick-dots {
        display: flex !important;
        gap: 8px;
        justify-content: center;
        align-items: center;
        margin-top: 30px;
        
        bottom: -50px !important;

        li button::before {
            background-color: #00000040;
            content: "";

            width: 16px;
            height: 10px;

            border-radius: 14px;
            
        }

        li.slick-active button::before {
            border-radius: 14px;
            background: rgba(0, 0, 0, 0.50);

            width: 30px !important;
            height: 10px;
        }

        li.slick-active {
            width: 30px !important;
            height: 20px;
        }
    }

    .slick-dots li button {
        padding: 0 !important;
        margin: 0 !important;
    }

    .slick-dots li {
        padding: 0 !important;
        margin: 0 !important;
    }
}
