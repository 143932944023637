.FaqItem {
    padding: 36px 31px;
    background-color: #F9F9F9;
    border-radius: 30px;
    cursor: pointer;

    .top {
        display: grid;
        grid-template-columns: 6fr 1fr;

        .icon {
            margin-left: auto;
            margin-top: auto;
            margin-bottom: auto;
            padding-bottom: 10px;
        }
    }

    .bottom {
        height: 0px;
        overflow: hidden;
        transition: all ease 0.2s;
    }

    .answer {
        gap: 0.3ch;
        margin-top: 19px;
        
        .text-underline {
            text-decoration: underline;
        }

        @media (max-width: 768px) {
            margin-top: 16px;
           
        }

        a {
            color: #3f3f3f !important;
            text-decoration: underline;
        }
    }

    .fake {
        position: absolute;
        z-index: -1;
        * {
            color: transparent !important;

            a {
                color: transparent !important;
            }
        }
    }
}
