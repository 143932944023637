@import './../../../Style/theme.scss';

.PartnerRessourcesHeader {
    background-color: #F6F6F6;
    margin-top: 80px;
    padding: 80px 16px;
    
    .top {
        display: flex;
        flex-direction: column;
        gap: 32px;
        margin-bottom: 40px;

        max-width: 1000px;

        margin-left: auto;
        margin-right: auto;

        text-align: center;

        .title-desc {
            display: flex;
            flex-direction: column;
            gap: 10px;
        }
    }

    .bottom {
        display: flex;
        gap: 30px;

        max-width: 1000px;

        margin-left: auto;
        margin-right: auto;

        .Carousel {
            width: 100%;
            margin-left: auto;
            margin-right: auto;

            .RequirementCards {
                margin-left: auto;
                margin-right: auto;
                min-height: auto;
                width: 96%;

                &.-ES {
                    min-height: 870px;
                }

                &.-IT {
                    min-height: 820px;
                }

                &.-FR {
                    min-height: 850px;
                }


                .inner-body {
                    margin-bottom: 10px;
                }

                .Button {
                    padding: 12px 0px !important;
                }
            }
        }
    }
}
