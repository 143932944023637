@import 'src/Style/theme.scss';

.Checkbox {
    display: flex;
    align-items: center;
    .box {
        width: 16px;
        min-width: 16px;
        height: 16px;
        min-height: 16px;
        border-radius: 5px;
        border: 1px solid grey;
        position: relative;

        svg {
            position: absolute;
            top: -1px;
            right: -2px;
        }

        &.checked {
            border: 1px solid transparent;
            background-color: $element-brand-rest-primary;
        }
    }
    label {
        margin-left: 8px;
    }
}
