@import '../../../../Style/theme.scss';
@import '../../../../Style/breakpoint.scss';
$breakpoint-svg: 570px; // Breakpoint for when the SVG needs to be scaled down to not overflow

.CoreValues {
    display: flex;
    align-items: center;
    padding: 120px 0;
    position: relative;
    background-color: white;

    @media (max-width: map-get($breakpoints, 'laptop')) {
        width: auto;
    }

    .content {
        width: 100%;
        max-width: 1360px;
        margin: auto;
        display: flex;
        align-items: center;
        justify-content: space-between;

        @media (max-width: map-get($breakpoints, 'laptop')) {
            flex-direction: column;
            justify-content: center;
        }

        .title-container {
            width: 527px;
            p {
                padding-top: 16px;
            }
            @media (max-width: map-get($breakpoints, 'laptop')) {
                width: auto;
            }
        }

        .title {
            line-height: 60px;
            @media (max-width: map-get($breakpoints, 'laptop')) {
                flex-direction: column;
                justify-content: center;
                line-height: 48px;
            }
        }
    }

    .overlay-text {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        pointer-events: none; // Assure que les clics passent à travers
    
        .middleText {
            position: absolute;
            text-align: center;
            width: 100%;
            max-width: 400px;
            opacity: 0; // Définir l'opacité initiale
            visibility: hidden; // Définir la visibilité initiale
            transition: opacity 0.8s ease, visibility 0.8s ease; // Transition pour l'opacité et la visibilité
    
            &.active {
                opacity: 1; // Rendre visible
                visibility: visible;
            }
    
            &.inactive {
                opacity: 0; // Rendre invisible
                visibility: hidden;
            }
    
            h3 {
                margin-bottom: 8px;
            }
    
            p {
                font-size: 14px !important;
            }
    
            @media (max-width: $breakpoint-svg) {
                width: 220px;
                font-size: 14px;
                padding-top: 10px;
                h3 {
                    font-size: 16px !important;
                }
            }
        }

        .windmillText {
            position: absolute;
            top: 135px;
            left: 0;
            @media (max-width: $breakpoint-svg) {
                top: 85px;
            }
        }

        .headText {
            position: absolute;
            top: 135px;
            right: 10px;
            @media (max-width: $breakpoint-svg) {
                top: 85px;
            }
        }

        .earthText {
            position: absolute;
            bottom: 55px;
            left: 30px;
            @media (max-width: $breakpoint-svg) {
                bottom: 35px;
                left: 15px;
            }
        }

        .thumbText {
            position: absolute;
            bottom: 55px;
            right: 35px;
            @media (max-width: $breakpoint-svg) {
                bottom: 35px;
                right: 20px;
            }
        }
    }    
    
    // Ensure the texts overlap and are centered
    .middleText {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    
    svg {
        overflow: visible;
        @media (max-width: $breakpoint-svg) {
            width: 360px;
            height: auto;
        }

        g {
            transition:
                opacity 0.5s ease-in-out,
                transform 0.5s ease-in-out;
        }
    }

    .CoreValuesCircle {
        position: relative;
        width: 557px;
        @media (max-width: map-get($breakpoints, 'laptop')) {
            width: auto;
            height: auto;
            margin-top: 40px;
        }
    }

    .ClickableBlock {
        position: absolute;
        width: 50%;
        height: 50%;
        transition: background-color 0.3s;
        opacity: 0;
    }
}
