@import './../../../../Style/theme.scss';
@import './../../../../Style/breakpoint.scss';

.FuturOfFashionSection {
    padding: 80px 0;
    background-color: white;

    .box {
        position: relative;
        background: radial-gradient(121.55% 107.82% at 50% -3.74%, #121212 0%, #333333 100%);

        width: 84%;
        margin-left: auto;
        margin-right: auto;
        border-radius: 20px;
        max-width: 1200px;

        padding: 80px;

        .title {
            letter-spacing: 0em;
            text-align: center;

            @media screen and (max-width: 768px) {
                text-align: left;
            }
        }

        .subtitle {
            margin: 55px auto;
            max-width: 678px;
            text-align: center;

            @media screen and (max-width: 768px) {
                text-align: left;
                margin: 20px 0 40px;
            }
        }

        .cta-button {
            margin-left: auto;
            margin-right: auto;

            @media (max-width: map-get($breakpoints, 'l')) {
                margin-left: 0;
            }

            @media (max-width: 380px) {
                padding: 14px 18.5px !important;
            }
        }

        .ellipse {
            position: absolute;
            z-index: 0;
            height: 80%;

            @media (max-width: 1000px) {
                display: none;
            }
        }
        .ellipse-right {
            top: 0;
            right: 0;
        }
        .ellipse-left {
            bottom: 0;
            left: 0;
            transform: rotate(180deg);
        }

        @media screen and (max-width: 1000px) {
            padding: 40px 20px;
        }
    }
}
